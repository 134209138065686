import "./styles.css";

import { t } from "localizify";
import propTypes from "prop-types";
import React, { useState } from "react";
import { Button, Form, Header, Message } from "semantic-ui-react";

import Link from "../../../../components/link/index.web";
import PasswordStrengthIndicator from "../../../../components/password-strength/index.web";
import prefixUrl from "../../../../public-path";

function ResetPasswordForm({
  type,
  error,
  loading,
  success,
  reset,
  currentPasswordRequired,
}) {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordBlurred, setNewPasswordBlurred] = useState(false);
  const [confirmPasswordBlurred, setConfirmPasswordBlurred] = useState(false);
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [passwordPasses, setPasswordPasses] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);

  if (success) {
    return (
      <>
        <Header>{t("password.reset.success_heading")}</Header>
        {!currentPasswordRequired && (
          <>
            <p>{t("password.reset.success_message")}</p>
            <Button
              as={Link}
              href={prefixUrl("/")}
              className="reset-password-cta"
              secondary
            >
              {t("password.reset.cta_success")}
            </Button>
          </>
        )}
      </>
    );
  }

  const onSubmit = e => {
    e.preventDefault();

    if (currentPasswordRequired && !currentPassword) {
      return;
    }

    if (passwordPasses && passwordsMatch) {
      if (currentPasswordRequired) {
        reset(currentPassword, newPasswordConfirm);
      } else {
        reset(newPasswordConfirm);
      }
    }
  };

  const message =
    type === "reset"
      ? t("password.reset.welcome_message_reset")
      : t("password.reset.welcome_message_setup");
  const heading =
    type === "reset"
      ? t("password.reset.welcome_heading_reset")
      : t("password.reset.welcome_heading_setup");

  return (
    <>
      <Header>{heading}</Header>
      <p>{message}</p>
      <Form
        size="small"
        onSubmit={e => onSubmit(e)}
        error={!!error}
        disabled={loading}
        className="login-area-form"
      >
        {currentPasswordRequired && (
          <Form.Input
            style={{ marginBottom: 50 }}
            fluid
            icon="lock"
            iconPosition="left"
            placeholder="Current password"
            type="password"
            name="currentPassword"
            id="currentPassword"
            onChange={({ target: { value } }) => {
              setCurrentPassword(value);
            }}
            value={currentPassword}
            label="Current Password"
            error={newPasswordBlurred && !currentPassword}
          />
        )}

        <Form.Input
          fluid
          icon="lock"
          iconPosition="left"
          placeholder="New password"
          type="password"
          name="newPassword"
          id="newPassword"
          onChange={({ target: { value } }) => {
            setNewPasswordConfirm("");
            setNewPassword(value);
          }}
          value={newPassword}
          label="New Password"
          onBlur={() => setNewPasswordBlurred(true)}
          error={newPasswordBlurred && !passwordPasses}
        />
        <PasswordStrengthIndicator
          password={newPassword}
          onPassing={() => setPasswordPasses(true)}
          onFailing={() => setPasswordPasses(false)}
        />
        <Form.Input
          fluid
          icon="lock"
          iconPosition="left"
          placeholder="Retype new password"
          type="password"
          label="Confirm Password"
          name="newPasswordConfirm"
          id="newPasswordConfirm"
          onChange={({ target: { value } }) => {
            setNewPasswordConfirm(value);
            setPasswordsMatch(value === newPassword);
          }}
          onBlur={({ target: { value } }) => {
            setPasswordsMatch(value === newPassword);
            setConfirmPasswordBlurred(true);
          }}
          value={newPasswordConfirm}
          error={confirmPasswordBlurred && !passwordsMatch}
        />

        <Message
          error
          header="Error Occurred"
          content={
            error
              ? error.message || "An unknown error occurred. Try again."
              : ""
          }
        />
        <Button
          className="reset-password-cta"
          secondary
          type="submit"
          loading={loading}
          disabled={
            !passwordPasses ||
            !passwordsMatch ||
            loading ||
            (currentPasswordRequired && !currentPassword)
          }
        >
          {t("password.reset.cta")}
        </Button>
      </Form>
    </>
  );
}

ResetPasswordForm.propTypes = {
  error: propTypes.string,
  loading: propTypes.bool,
  success: propTypes.bool,
  reset: propTypes.func.isRequired,
  currentPasswordRequired: propTypes.bool,
  type: propTypes.string.isRequired,
};

ResetPasswordForm.defaultProps = {
  error: "",
  loading: false,
  success: false,
  currentPasswordRequired: false,
};

export default ResetPasswordForm;
