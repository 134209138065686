// Match cookie value set in @communityco/access-tokens
const AUTHORIZATION_COOKIE = "AuthToken";

// role constants
export const ADMIN = "ADMIN";
export const MEMBER = "MEMBER";
export const PUBLIC = "PUBLIC";

export function getAuthRedirectWeb(pathname) {
  return `${pathname}?redirect=${encodeURIComponent(window.location.href)}`;
}

// handler for if we cant get an identity
export const DEFAULT_IDENTITY = {
  hasRole: role => role === PUBLIC,
  roles: [PUBLIC],
  userId: undefined,
  isLoggedIn: false,
  experience: "convoy",
};

export function jwtToIdentity(jwtValue) {
  if (!jwtValue) {
    return DEFAULT_IDENTITY;
  }

  try {
    const [, middle] = jwtValue.split(".");
    const payload = window.atob(middle);
    const {
      aud,
      sub,
      brd: brandCode,
      com: communityCode,
      loc: locationCode,
      gate,
    } = JSON.parse(payload);

    const audiences = Array.isArray(aud) ? aud : [aud];
    const roles = [PUBLIC];
    if (audiences.includes("/graphql")) {
      roles[0] = audiences.includes("/graphqlAdmin") ? ADMIN : MEMBER;
    }

    const userId =
      typeof sub === "string" && sub.startsWith("u:") && sub.slice(2);

    return {
      brandCode,
      communityCode,
      gate,
      hasRole: role => roles.includes(role),
      isLoggedIn: Boolean(userId),
      locationCode,
      roles,
      userId,
    };
  } catch (err) {
    return DEFAULT_IDENTITY;
  }
}

let lastCookie;
let jwt = null;
let idObj = DEFAULT_IDENTITY;

export default function useIdentity() {
  if (lastCookie !== document.cookie) {
    const match = document.cookie.match(`${AUTHORIZATION_COOKIE}=([^;]+)`);
    const newJwt = match && match[1];
    if (newJwt !== jwt) {
      idObj = jwtToIdentity(newJwt);
      jwt = newJwt;
    }
    lastCookie = document.cookie;
  }
  return idObj;
}
