import React from "react";
import { Grid, Header, List, Segment } from "semantic-ui-react";

function AdminSitesList() {
  const sites = [
    {
      name: "Adage",
      link: "https://member.adagecollective.com/admin",
    },
    {
      name: "Bizjournals",
      link: "https://member.bizjournals.com/admin",
    },
    { name: "Brand", link: "https://member.brandcommunities.com/admin" },
    {
      name: "Forbes",
      link: "https://member.forbescouncils.com/admin",
    },
    {
      name: "Young Entrepreunial Council",
      link: "https://member.yec.co/admin",
    },
  ];
  return (
    <Grid centered column={1}>
      <Grid.Column width={6}>
        <Segment>
          <Header as="h3">Brand Specific Admin Dashboards</Header>
          <List>
            {sites.map(site => (
              <List.Item key={site.name}>
                <a href={site.link}>{site.name}</a>
              </List.Item>
            ))}
          </List>
        </Segment>
      </Grid.Column>
    </Grid>
  );
}

export default AdminSitesList;
