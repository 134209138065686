import PropTypes from "prop-types";
import React from "react";
import { Divider, Grid, Header } from "semantic-ui-react";

import colors from "../../lib/colors";

function SectionHeader({ title, padded }) {
  return (
    <div
      style={{
        marginBottom: "20px",
        marginTop: padded ? "40px" : "auto",
      }}
    >
      <Grid>
        <Grid.Row
          columns={1}
          style={{
            padding: "24px 0 5px",
          }}
        >
          <Grid.Column width={16}>
            <Header
              as="h3"
              style={{ fontSize: "20px", color: colors.grayMedium }}
              data-ui-container="settings-section-header"
            >
              {title}
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider
        style={{
          marginBottom: "8px",
          borderColor: colors.silverDark,
          borderBottom: "none",
        }}
      />
    </div>
  );
}

export default SectionHeader;

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  padded: PropTypes.bool,
};

SectionHeader.defaultProps = {
  padded: false,
};
