import PropTypes from "prop-types";
import React from "react";
import { Icon, Popup } from "semantic-ui-react";

import colors from "../../lib/colors";

export default function HelpTooltip({ content }) {
  return (
    <Popup
      trigger={
        <Icon
          className="question-circle-o"
          size="large"
          style={{
            verticalAlign: "top",
            margin: "0 5px",
            position: "bottom left",
            color: colors.grayBase,
          }}
        />
      }
      size="small"
      hoverable
      content={content}
      style={{
        boxShadow: "none",
        verticalAlign: "middle",
        marginLeft: "-10px",
      }}
    />
  );
}

HelpTooltip.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

HelpTooltip.defaultProps = {
  content: null,
};
