import { t } from "localizify";
import PropTypes from "prop-types";
import React from "react";
import { useMutation } from "react-apollo";

import SegmentPage from "../../../components/segment/page";
import { ALERT } from "../../../graphql/client-schema";
import AgreementModal from "../agreements/modal.web";

export function SubmitArticleModal({
  open,
  onClose,
  submitting,
  error,
  onSubmit,
}) {
  const [showAlert] = useMutation(ALERT, {
    variables: {
      displayType: "positive",
      title: t("articles.status_messages.SUBMITTED_FOR_REVIEW.cardFriendly"),
      positive: t("articles.status_messages.SUBMITTED_FOR_REVIEW.positive"),
      message: t("articles.status_messages.SUBMITTED_FOR_REVIEW.message"),
    },
  });

  return (
    <SegmentPage view="ArticleSubmitConfirm" logOnEvent="onOpen">
      <AgreementModal
        headerText={t("articles.article_agreement.modal_title")}
        bodyText={t("articles.article_agreement.agreement_text")}
        acknowledgementText={t(
          "articles.article_agreement.acknowledgement_text"
        )}
        confirmText={t("articles.article_agreement.cta_confirm")}
        errorText={error && t("articles.error_submit_article")}
        loading={submitting}
        onAccept={async () => {
          await onSubmit();
          showAlert();
        }}
        onClose={onClose}
        open={open}
      />
    </SegmentPage>
  );
}

SubmitArticleModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  submitting: PropTypes.bool,
  error: PropTypes.string,
  onSubmit: PropTypes.func,
};

SubmitArticleModal.defaultProps = {
  open: false,
  onClose: () => {},
  submitting: false,
  error: "",
  onSubmit: () => {},
};

export default SubmitArticleModal;
