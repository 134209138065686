import PropTypes from "prop-types";
import React from "react";
import { Grid, Segment } from "semantic-ui-react";

export default function Layout({ children, textAlign }) {
  return (
    <Grid columns={3} textAlign={textAlign}>
      <Grid.Column only="tablet" tablet={3} computer={5} />
      <Grid.Column mobile={16} tablet={10} computer={6}>
        <Segment padded style={{ marginTop: "5em", minHeight: "250px" }}>
          {children}
        </Segment>
      </Grid.Column>
      <Grid.Column only="tablet" tablet={3} computer={5} />
    </Grid>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  textAlign: PropTypes.string,
};

Layout.defaultProps = {
  textAlign: "center",
};
