import "../styles.css";

import PropTypes from "prop-types";
import React from "react";
import { Divider, Header, Icon } from "semantic-ui-react";

import { TrackButton } from "../../../../components/segment/track";
import colorsTheme from "../../../../lib/colors";
import { namespacedT } from "../../../../lib/i18n";

function ErrorView({
  handleTryAgain,
  hasGraphError,
  conciergeEmail,
  conciergePhone,
}) {
  const t = namespacedT("payment.dunning_member");

  return (
    <div className="error-view-container">
      <Icon className="warning-circle" />
      <div>
        <Header
          as="h2"
          align="center"
          style={{ color: colorsTheme.black, marginBottom: 0 }}
        >
          {t("something_went_wrong")}
        </Header>
        {!hasGraphError && (
          <p style={{ color: colorsTheme.grayMedium }}>
            {t("there_was_an_issue")}
          </p>
        )}
      </div>
      <TrackButton
        secondary
        content="Try Again"
        onClick={handleTryAgain}
        eventLabel="Try Again"
      />

      <Divider horizontal style={{ width: "50%", textTransform: "lowercase" }}>
        or
      </Divider>
      <Header
        as="h2"
        align="center"
        style={{ color: colorsTheme.black, margin: 0 }}
      >
        {t("contact_us")}
      </Header>
      <div className="error-view-contact-container">
        <div>
          <Icon
            className="mobile-phone"
            size="large"
            style={{ color: colorsTheme.grayMedium, margin: 0 }}
          />
          <p style={{ color: colorsTheme.grayMedium, margin: 0 }}>
            {conciergePhone}
          </p>
        </div>
        <div>
          <Icon
            className="envelope-solid"
            size="small"
            style={{ color: colorsTheme.grayMedium }}
          />
          <p style={{ color: colorsTheme.grayMedium, margin: 0 }}>
            {conciergeEmail}
          </p>
        </div>
      </div>
    </div>
  );
}

ErrorView.propTypes = {
  handleTryAgain: PropTypes.func,
  hasGraphError: PropTypes.bool,
  conciergeEmail: PropTypes.string,
  conciergePhone: PropTypes.string,
};

ErrorView.defaultProps = {
  handleTryAgain: () => null,
  hasGraphError: false,
  conciergeEmail: "",
  conciergePhone: "",
};

export default ErrorView;
