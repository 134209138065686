const prefix = window.CCO_PATH_PREFIX || "";

export default function prefixUrl(urlStr = "") {
  return `${prefix}${urlStr}`;
}

/**
 * Extracts the path with prefix after the domain or the entire FQDN
 * @param urlStr - FQDN URI encoded or not
 * @param {(undefined|"absolute")} format - Whether you want the path or the absolute FQDN
 * @returns {string}
 */
export function prefixPathFromAbsoluteUrl(urlStr, format) {
  if (!prefix) return urlStr;
  if (urlStr === "/") return prefixUrl(urlStr);

  try {
    const url = new URL(decodeURIComponent(urlStr));

    if (!url.pathname.startsWith(prefix)) {
      url.pathname = prefixUrl(url.pathname);
    }

    return format === "absolute" ? url.toString() : url.pathname;
  } catch {
    return urlStr;
  }
}
