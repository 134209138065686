import "./styles.css";

import PropTypes from "prop-types";
import React from "react";
import { Button, Icon, Responsive } from "semantic-ui-react";

import { LOGOUT } from "../../../../containers/auth/routes-links";
import { linkTo } from "../../../../containers/profile/routes-links";
import { SETTINGS_HOME } from "../../../../containers/settings/routes-links";
import { logEvent } from "../../../../lib/analytics";
import { namespacedT } from "../../../../lib/i18n";
import Avatar from "../../../avatar/index.web";
import Link from "../../../link/index.web";

const t = namespacedT("app_navbar");

function UserMenu({
  id,
  avatar,
  displayName,
  company,
  community,
  location,
  onClose,
}) {
  return (
    <div
      className="app-navbar-user-menu"
      data-ui-container="app-navbar-user-menu"
    >
      <div className="app-navbar-user-menu-header">
        <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
          <Button
            as={Link}
            to={linkTo.self(id)}
            className="app-navbar-user-menu-settings-button"
            circular
            icon="edit"
            onClick={onClose}
          />
        </Responsive>

        <Responsive minWidth={Responsive.onlyComputer.minWidth}>
          <Avatar
            className="app-navbar-user-menu-avatar"
            src={avatar}
            size={64}
          />
        </Responsive>

        <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
          <Avatar
            className="app-navbar-user-menu-avatar"
            src={avatar}
            size={100}
          />
        </Responsive>

        <h2 className="app-navbar-user-menu-name">{displayName}</h2>

        {company && <h4 className="app-navbar-user-menu-company">{company}</h4>}
      </div>

      <div className="app-navbar-user-menu-details">
        <span className="app-navbar-user-menu-community">{community}</span>

        <span className="app-navbar-user-menu-location">{location}</span>
      </div>

      <Responsive minWidth={Responsive.onlyComputer.minWidth}>
        <div className="app-navbar-user-menu-footer">
          <Button
            as={Link}
            to={linkTo.self(id)}
            onClick={() => {
              logEvent("User Menu", "Button-Click", "Manage Profile");
              onClose();
            }}
            className="app-navbar-user-menu-primary-button"
            fluid
          >
            {t("manage_profile")}
          </Button>

          <Button
            as={Link}
            to={SETTINGS_HOME}
            onClick={onClose}
            className="app-navbar-user-menu-secondary-button"
            fluid
            inverted
            icon
          >
            <Icon className="settings" /> {t("settings")}
          </Button>

          <Button
            as={Link}
            to={LOGOUT}
            onClick={onClose}
            className="app-navbar-user-menu-secondary-button"
            fluid
            inverted
            icon
          >
            <Icon className="sign-out" /> {t("sign_out")}
          </Button>
        </div>
      </Responsive>
    </div>
  );
}

export default UserMenu;

UserMenu.propTypes = {
  id: PropTypes.string,
  avatar: PropTypes.string,
  displayName: PropTypes.string,
  company: PropTypes.string,
  community: PropTypes.string,
  location: PropTypes.string,
  onClose: PropTypes.func,
};

UserMenu.defaultProps = {
  id: "",
  avatar: "",
  displayName: "",
  company: "",
  community: "",
  location: "",
  onClose: () => {},
};
