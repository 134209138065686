import "./animated-check.css";

import PropTypes from "prop-types";
import React from "react";

const AnimatedCheck = ({ color }) => {
  return (
    <svg
      width="100%"
      viewBox="0 0 130.2 130.2"
      preserveAspectRatio="xMidYMid meet"
    >
      <circle
        className="path circle"
        fill="none"
        stroke={color}
        strokeWidth="6"
        strokeMiterlimit="10"
        cx="65.1"
        cy="65.1"
        r="62.1"
      />
      <polyline
        className="path check"
        fill="none"
        stroke={color}
        strokeWidth="6"
        strokeLinecap="round"
        strokeMiterlimit="10"
        points="100.2,40.2 51.5,88.8 29.8,67.5 "
      />
    </svg>
  );
};

export default AnimatedCheck;

AnimatedCheck.propTypes = {
  color: PropTypes.string,
};

AnimatedCheck.defaultProps = {
  color: "green",
};
