import React from "react";
import { Route, Switch } from "react-router-dom";

import ErrorBoundary from "../../components/error-boundary/error-boundary.web";
import Layout from "./layout/index.web";
import { SETTINGS_HOME } from "./routes-links";

export default (
  <Route path={SETTINGS_HOME}>
    <Switch>
      <Route path={SETTINGS_HOME}>
        <ErrorBoundary title="Error" message="An Error has occurred">
          <Layout />
        </ErrorBoundary>
      </Route>
    </Switch>
  </Route>
);
