import "./styles.css";

import PropTypes from "prop-types";
import React from "react";
import { Icon, Popup } from "semantic-ui-react";

import NotificationsListWired from "../../../../containers/notifications/notifications-list/index.web";
import Sidebar from "../../../sidebar/index.web";
import { useHasUnviewed } from "./hooks";

const NotificationsPopupPure = ({ showIndicator, onOpen, open, onClose }) => {
  return (
    <Popup
      className="app-navbar-popup notification-list"
      on="click"
      position="top right"
      offset="5px"
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      trigger={
        <div
          className="app-navbar-notifications-menu-toggle"
          data-ui-action="app-navbar-notifications-menu-toggle"
        >
          {showIndicator && (
            <span className="app-navbar-notifications-indicator" />
          )}
          <Icon className="notification" />
        </div>
      }
      content={
        <NotificationsListWired pageSize={7} isCompact onClose={onClose} />
      }
    />
  );
};

export const SidebarNotifications = ({ visible, onClose }) => (
  <Sidebar
    visible={visible}
    onClose={onClose}
    direction="right"
    closeOnDimmerClick
    hideClose
    style={{ width: "90%", zIndex: 1005 }}
  >
    <NotificationsListWired pageSize={25} showCloseIcon onClose={onClose} />
  </Sidebar>
);

const NotificationsIconPure = ({ showIndicator, onOpen }) => (
  <div className="app-navbar-notifications-menu-toggle">
    {showIndicator && <span className="app-navbar-notifications-indicator" />}
    <Icon className="notification" onClick={onOpen} />
  </div>
);

export const NotificationsIconWired = props => {
  const { hasUnviewed } = useHasUnviewed();

  return <NotificationsIconPure {...props} showIndicator={hasUnviewed} />;
};

export const NotificationsPopupWired = props => {
  const { hasUnviewed } = useHasUnviewed();

  return <NotificationsPopupPure {...props} showIndicator={hasUnviewed} />;
};

NotificationsPopupPure.propTypes = {
  showIndicator: PropTypes.bool,
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

NotificationsPopupPure.defaultProps = {
  open: false,
  onOpen: () => {},
  onClose: () => {},
  showIndicator: false,
};

NotificationsIconPure.propTypes = {
  showIndicator: PropTypes.bool,
  onOpen: PropTypes.func,
};

NotificationsIconPure.defaultProps = {
  onOpen: () => {},
  showIndicator: false,
};

SidebarNotifications.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
