import fetch from "../../graphql/resolvers/fetch-manager";
import prefixUrl from "../../public-path";

function isTrackablePath(path) {
  return (
    typeof path === "string" &&
    (path.startsWith("/expert-panels") || path.startsWith("/groups"))
  );
}
function isActiveEvent(category, action, label) {
  return (
    category === "User Menu" &&
    action === "Button-Click" &&
    label === "Manage Profile"
  );
}

async function sendTracking(query, input) {
  try {
    const response = await fetch(prefixUrl("/graphql"), {
      method: "POST",
      credentials: "same-origin",
      mode: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query,
        variables: { input },
      }),
    });
    if (response.ok) {
      const data = await response.json();
      console.log("Tracking OK:", data);
    } else {
      const info = await response.text();
      console.error("Tracking Fetch Error:", info);
    }
  } catch (err) {
    console.error("Tracking Error:", err);
  }
}

const MUTATION_EVENT = `mutation($input: TrackEventInput!) {
  trackEvent(input: $input)
}`;

const MUTATION_PAGEVIEW = `mutation($input: TrackPageviewInput!) {
  trackPageview(input: $input)
}`;

export async function event(category, action, label) {
  if (isActiveEvent(category, action, label)) {
    await sendTracking(MUTATION_EVENT, { category, action, label });
  }
}

export async function pageView(location, title) {
  if (isTrackablePath(location)) {
    let pageTitle = title;
    if (!pageTitle && document) {
      await new Promise(resolve => setTimeout(resolve, 10));
      pageTitle = document.title;
    }
    await sendTracking(MUTATION_PAGEVIEW, { location, title: pageTitle });
  }
}

export async function modalView(modalPath) {
  const modelName = modalPath.startsWith("/") ? modalPath.slice(1) : modalPath;
  const location = `/modal/${modelName}`;
  if (isTrackablePath(location)) {
    await sendTracking(MUTATION_PAGEVIEW, { location });
  }
}
