import "react-credit-cards/es/styles-compiled.css";

import PropTypes from "prop-types";
import React from "react";
import CreditCard from "react-credit-cards";
import { Button, Checkbox, Icon } from "semantic-ui-react";

import ButtonTertiary from "../../../../components/button/tertiary/index.web";
import colors from "../../../../lib/colors";
import { namespacedT } from "../../../../lib/i18n";

const t = namespacedT("settings.payment");

const PaymentCardItem = ({
  id,
  firstName,
  lastName,
  last4Digits,
  cardType,
  expirationMonth,
  expirationYear,
  isDefault,
  toggleDefaultItem,
  isExpired,
  isFailed,
  isSoonExpired,
  handleRemove,
  loadingDelete,
  loadingDefault,
  toggleCardAuthLog,
  visibleAuthLog,
  noAuthLog,
  isAdmin,
}) => {
  const maskedCardNumber =
    cardType === "AMERICAN_EXPRESS"
      ? last4Digits.padStart(15, "*")
      : last4Digits.padStart(16, "*");
  const expiration =
    expirationMonth && expirationYear
      ? `${String(expirationMonth).padStart(2, "0")}${expirationYear}`
      : "";

  return (
    <div
      style={{
        display: "inline-flex",
        flexDirection: "column",
        margin: "10px",
        position: "relative",
      }}
    >
      <CreditCard
        expiry={expiration}
        issuer={cardType === "AMERICAN_EXPRESS" ? "AMEX" : cardType}
        name={firstName || lastName ? `${firstName} ${lastName}`.trim() : ""}
        number={maskedCardNumber}
        preview
        cvc={""} // react-credit-cards complains if cvc is missing
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <Checkbox
          disabled={loadingDefault || isDefault}
          checked={isDefault}
          label="Default"
          onChange={() => toggleDefaultItem(id)}
        />
        {isExpired && (
          <div
            style={{
              color: colors.redText,
              display: "flex",
              alignItems: "end",
            }}
          >
            <Icon className="warning-circle" /> {t("expired")}
          </div>
        )}
        {isFailed && (
          <div
            style={{
              color: colors.redText,
              display: "flex",
              alignItems: "end",
            }}
          >
            <Icon className="warning-circle" />{" "}
            {isAdmin ? t("admin.failed") : t("failed")}
          </div>
        )}
        {isSoonExpired && (
          <div style={{ color: "#FBBD08", display: "flex", alignItems: "end" }}>
            <Icon className="warning-circle" /> {t("expired_soon")}
          </div>
        )}

        <ButtonTertiary
          style={{ visibility: isDefault ? "hidden" : "visible" }}
          onClick={() => handleRemove(id)}
          disabled={loadingDelete}
        >
          {t("remove_card")}
        </ButtonTertiary>
      </div>
      {!noAuthLog && (
        <Button
          color={visibleAuthLog ? "green" : undefined}
          style={{
            alignSelf: "flex-start",
            margin: "10px 0",
            fontSize: "12px",
          }}
          onClick={toggleCardAuthLog}
        >
          {visibleAuthLog
            ? t("admin.authLog.hide_log")
            : t("admin.authLog.show_log")}
        </Button>
      )}
    </div>
  );
};

export default PaymentCardItem;

PaymentCardItem.propTypes = {
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  last4Digits: PropTypes.string,
  cardType: PropTypes.string,
  expirationMonth: PropTypes.number,
  expirationYear: PropTypes.number,
  isDefault: PropTypes.bool,
  isExpired: PropTypes.bool,
  isFailed: PropTypes.bool,
  isSoonExpired: PropTypes.bool,
  toggleDefaultItem: PropTypes.func,
  handleRemove: PropTypes.func,
  loadingDelete: PropTypes.bool,
  loadingDefault: PropTypes.bool,
  toggleCardAuthLog: PropTypes.func,
  visibleAuthLog: PropTypes.bool,
  noAuthLog: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

PaymentCardItem.defaultProps = {
  id: "",
  firstName: "",
  lastName: "",
  last4Digits: "",
  cardType: "",
  expirationMonth: null,
  expirationYear: null,
  isDefault: false,
  isExpired: false,
  isFailed: false,
  isSoonExpired: false,
  toggleDefaultItem: () => {},
  handleRemove: () => {},
  loadingDelete: false,
  loadingDefault: false,
  toggleCardAuthLog: () => {},
  visibleAuthLog: false,
  noAuthLog: false,
  isAdmin: false,
};
