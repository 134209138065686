import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import NotFound from "../../components/not-found/index.web";
import { getSearchParam } from "../../lib/url";
import ResetPassword from "./reset/index.web";
import { PASSWORD, SETUP_PASSWORD } from "./routes-links";

export default (
  <Route path={[PASSWORD, "/account"]}>
    {" "}
    {/* capture old auth product routes to redirect properly */}
    <Switch>
      <Route
        path={SETUP_PASSWORD}
        render={({ location }) => {
          const search = location && location.search;
          const token = new URLSearchParams(search).get("token");
          return <ResetPassword type="setup" token={token} />;
        }}
      />
      {/* default to not-found */}
      <NotFound />
      {/* REDIRECTS */}
      <Redirect from="/account/forgot-password" to="/password/forgot" />
      <Redirect
        from="/account/reset-password"
        to={`/password/reset?token=${getSearchParam("token")}`}
      />
      <Redirect
        from="/account/set-password"
        to={`/password/setup?token=${getSearchParam("token")}`}
      />
    </Switch>
  </Route>
);
