import gql from "graphql-tag";
import { get } from "lodash";
import React, { Fragment } from "react";
import { useQuery } from "react-apollo";
import { Responsive, Segment } from "semantic-ui-react";

import Markdown from "../../../components/markdown/index.web";

const ARTICLE_GUIDELINES = gql`
  query articleGuidelines {
    articleGuidelines {
      guidelines
    }
  }
`;

export function Guidelines() {
  const { loading, data } = useQuery(ARTICLE_GUIDELINES);
  const guidelines = get(data, "articleGuidelines.guidelines");

  return (
    <Fragment>
      <Responsive minWidth={Responsive.onlyTablet.minWidth}>
        <Segment padded="very" basic>
          <Markdown loading={loading} markdown={guidelines} />
        </Segment>
      </Responsive>

      <Responsive {...Responsive.onlyMobile}>
        <Segment basic>
          <Markdown loading={loading} markdown={guidelines} />
        </Segment>
      </Responsive>
    </Fragment>
  );
}

export default Guidelines;
