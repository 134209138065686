import "./styles.css";

import { get } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { historyShape, locationShape } from "react-router-props";

import SegmentPage from "../../../components/segment/page";
import { getSearchParam } from "../../../lib/url";
import { prefixPathFromAbsoluteUrl } from "../../../public-path";
import { useQueryLoginConfig } from "../graphql";
import AdminLogin from "./admin-login.web";
import Layout from "./layout.web";
import LoginForgotPassword, {
  PasswordLinkSentConfirmation,
} from "./login-forgot-password.web";
import SourceInputForm from "./login-source-input.web";
import LoginWithPasswordForm from "./login-with-password.web";
import OneTimeCodeInputForm from "./one-time-code-input.web";
import OtcLockout from "./otc-lockout.web";
import { VIEWS, getRememberMe } from "./shared";

// returns the correct view based on the current login step
function getActiveForm(activeView) {
  if (activeView === VIEWS.ONE_TIME_CODE_INPUT) {
    return OneTimeCodeInputForm;
  }

  if (activeView === VIEWS.PASSWORD_INPUT) {
    return LoginWithPasswordForm;
  }

  if (activeView === VIEWS.FORGOT_PASSWORD) {
    return LoginForgotPassword;
  }

  if (activeView === VIEWS.RESET_PASSWORD_CONFIRMATION) {
    return PasswordLinkSentConfirmation;
  }

  if (activeView === VIEWS.OTC_LOCKOUT) {
    return OtcLockout;
  }

  if (activeView === VIEWS.LOGIN_ADMIN) {
    return AdminLogin;
  }
  // initial form
  return SourceInputForm;
}

// Initial state
const initialState = {
  channelTo: null, // email address to login as
  activeView: VIEWS.EMAIL_INPUT,
};

function reducer(state, action) {
  switch (action.type) {
    case "LOGIN_CANCELED":
      return { ...initialState };
    case "LOGIN_REQUESTED":
      return {
        channelTo: action.channelTo,
        activeView: VIEWS.PASSWORD_INPUT,
        viewName: "LoginPassword",
      };

    case "ADMIN_LOGIN_REQUESTED":
      return {
        channelTo: action.channelTo,
        activeView: VIEWS.LOGIN_ADMIN,
        viewName: "LoginAdmin",
      };

    case "ONE_TIME_CODE_REQUESTED":
      return {
        ...state,
        channel: action.channel,
        channelTo: action.channelTo,
        activeView: VIEWS.ONE_TIME_CODE_INPUT,
        viewName: "LoginEnterOTC",
      };

    case "FORGOT_PASSWORD_REQUESTED":
      return {
        ...state,
        activeView: VIEWS.FORGOT_PASSWORD,
        viewName: "ForgotPassword",
      };

    case "RESET_LINK_REQUESTED":
      return {
        ...state,
        activeView: VIEWS.RESET_PASSWORD_CONFIRMATION,
        viewName: "ForgotPasswordLinkSent",
      };

    case "OTC_LOCKED":
      return {
        ...state,
        activeView: VIEWS.OTC_LOCKOUT,
        viewName: "LoginAccountLockedOTC",
      };
    default:
      throw new Error();
  }
}

export default function Login({ location, isAdminLogin }) {
  // base site config
  const config = useQueryLoginConfig();

  const initialStateOverrides = {};

  // direct admin route
  if (isAdminLogin) {
    initialStateOverrides.activeView = VIEWS.LOGIN_ADMIN;
    initialStateOverrides.channelTo = getRememberMe() || "";
  }

  const [loginState, dispatchLoginState] = React.useReducer(reducer, {
    ...initialState,
    ...initialStateOverrides,
  });

  const ActiveForm = getActiveForm(loginState.activeView);

  return (
    <Layout {...config}>
      <SegmentPage view={loginState.viewName} shouldLogOnViewChange>
        <ActiveForm
          onLoginComplete={() => {
            const newRedirect = get(location, "state.from");
            const oldRedirect = getSearchParam("redirect");

            const url =
              oldRedirect || !newRedirect
                ? prefixPathFromAbsoluteUrl(oldRedirect || "/")
                : newRedirect;

            window.location.replace(url);
          }}
          dispatchLoginState={dispatchLoginState}
          channel={loginState.channel}
          channelTo={loginState.channelTo}
          membershipInfoLink={config.membershipLink}
        />
      </SegmentPage>
    </Layout>
  );
}

Login.propTypes = {
  isAdminLogin: PropTypes.bool,
  location: locationShape.isRequired,
  history: historyShape.isRequired,
};

Login.defaultProps = {
  isAdminLogin: false,
};
