import "./notification-item-loading.css";

import PropTypes from "prop-types";
import React from "react";
import { List, Placeholder } from "semantic-ui-react";

export default function NotificationItemLoading({ showAvatar }) {
  const lineBaseStyle = { height: "1em", overflow: "hidden" };

  return (
    <List.Item
      className="notification-loader"
      style={{ padding: "1em 0", display: "flex" }}
    >
      {showAvatar && (
        <div
          style={{
            marginLeft: "1em",
            width: "45px",
            height: "35px",
            overflow: "hidden",
          }}
        >
          <Placeholder fluid>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
        </div>
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "35px",
          padding: "0 1em",
        }}
      >
        <div style={lineBaseStyle}>
          <Placeholder fluid>
            <Placeholder.Line />
          </Placeholder>
        </div>
        <div style={{ ...lineBaseStyle, width: "50%" }}>
          <Placeholder fluid>
            <Placeholder.Line />
          </Placeholder>
        </div>
      </div>
    </List.Item>
  );
}

NotificationItemLoading.propTypes = {
  showAvatar: PropTypes.bool,
};

NotificationItemLoading.defaultProps = {
  showAvatar: false,
};
