import gql from "graphql-tag";
import { get } from "lodash";
import { useMutation, useQuery } from "react-apollo";

const SELECTION_FRAGMENT = gql`
  fragment Selection_Fragment on NotificationSelection {
    id
    selected
  }
`;
const GET_NOTIFICATION_PREFERENCES = gql`
  query {
    me {
      id
      notificationPreferences {
        id
        group
        options {
          id
          name
          description
          forEmail: selection(channel: EMAIL) {
            ...Selection_Fragment
          }
          forWeb: selection(channel: WEB) {
            ...Selection_Fragment
          }
          forMobile: selection(channel: PUSH) {
            ...Selection_Fragment
          }
        }
      }
    }
  }
  ${SELECTION_FRAGMENT}
`;

const SET_NOTIFICATION_PREFERENCES = gql`
  mutation NotificationsPreferenceSelection(
    $input: NotificationSelectionInput!
  ) {
    notificationsPreferenceSelection(input: $input) {
      ...Selection_Fragment
    }
  }
  ${SELECTION_FRAGMENT}
`;

export const useNotificationPreferences = () => {
  const { loading, data, error } = useQuery(GET_NOTIFICATION_PREFERENCES);

  const notificationPreferences = get(data, ["me", "notificationPreferences"]);

  return {
    loading,
    error,
    notificationPreferences,
  };
};

export const useSetNotificationPreferences = () => {
  const [mutate] = useMutation(SET_NOTIFICATION_PREFERENCES);

  return {
    setNotificationPreferences: (id, selected) =>
      mutate({
        variables: { input: { id, selected } },
        optimisticResponse: {
          __typename: "Mutation",
          notificationsPreferenceSelection: {
            __typename: "NotificationSelection",
            id,
            selected,
          },
        },
      }),
  };
};
