import isObject from "lodash/isObject";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Message } from "semantic-ui-react";

const errorMessage = error => (isObject(error) ? error.message : error);

function ErrorMessage({ error, messageProps, onLoad }) {
  useEffect(() => {
    if (error && onLoad) {
      onLoad(errorMessage(error));
    }
  }, [onLoad, error]);

  if (!error) return null;

  const mProps = {
    negative: true,
    style: { textAlign: "left" },
    content: errorMessage(error),
    ...messageProps,
  };

  return <Message {...mProps} />;
}

ErrorMessage.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
  // eslint-disable-next-line react/forbid-prop-types
  messageProps: PropTypes.object,
  onLoad: PropTypes.func,
};

ErrorMessage.defaultProps = {
  error: "",
  messageProps: {},
  onLoad: undefined,
};

export default ErrorMessage;
