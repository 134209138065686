import "react-credit-cards/es/styles-compiled.css";
import "../styles.css";

import PropTypes from "prop-types";
import React, { useEffect, useReducer } from "react";
import { Button, Grid, Icon, Loader, Segment } from "semantic-ui-react";

import ErrorMessage from "../../../../components/error-message/index.web";
import SegmentPage from "../../../../components/segment/page";
import { trackEvent } from "../../../../lib/analytics/segment";
import colorsTheme from "../../../../lib/colors";
import { namespacedT } from "../../../../lib/i18n";
import ErrorView from "../error-view/index.web";
import SuccessView from "../success-view/index.web";
import FormHeader from "./form-header.web";
import FormView from "./form-view.web";

const t = namespacedT("payment.dunning_member");

const HANDLE_RETRY = "HANDLE_RETRY";
const CALL_CHARGIFY = "CALL_CHARGIFY";
const SET_PAID = "SET_PAID";
const SET_CHARGIFY_ERROR_MESSAGE = "SET_CHARGIFY_ERROR_MESSAGE";
const SET_SERVER_ERROR_MESSAGE = "SET_SERVER_ERROR_MESSAGE";
const SET_VIEW = "SET_VIEW";

const PAYMENT_COMPLETE = "PaymentComplete";
const PAYMENT_ERROR = "PaymentError";
const PAYMENT_NO_BALANCE = "PaymentNoBalance";

function formReducer(state, action) {
  switch (action.type) {
    case HANDLE_RETRY:
      return {
        ...state,
        callingChargify: false,
        isRetry: true,
        serverErrorMessage: "",
      };
    case CALL_CHARGIFY:
      return {
        ...state,
        callingChargify: action.payload,
        chargifyErrorMessage: "",
        serverErrorMessage: "",
      };
    case SET_PAID:
      return { ...state, paid: true, viewName: PAYMENT_COMPLETE };
    case SET_CHARGIFY_ERROR_MESSAGE:
      return { ...state, chargifyErrorMessage: action.payload };
    case SET_SERVER_ERROR_MESSAGE:
      return {
        ...state,
        serverErrorMessage: action.payload,
        viewName: PAYMENT_ERROR,
      };
    case SET_VIEW:
      return { ...state, viewName: action.payload };
    default:
      return state;
  }
}

export default function DunningMemberForm({
  addPaymentViaToken,
  savingPayment,
  hasError,
  paymentError,
  publicKey,
  serverHost,
  loading,
  userName,
  currentBalance,
  showNoBalanceDue,
  renewalDate,
  loadingUserData,
  layoutConfig: { backgroundImage, logo },
  brandName,
  conciergeEmail,
  conciergePhone,
  trackFailedChargifyJS,
}) {
  const formConfig = {
    publicKey,
    serverHost,
  };

  const [
    {
      callingChargify,
      paid,
      serverErrorMessage,
      chargifyErrorMessage,
      isRetry,
      viewName,
    },
    dispatch,
  ] = useReducer(formReducer, {
    callingChargify: false,
    paid: false,
    serverErrorMessage: "",
    chargifyErrorMessage: "",
    isRetry: false,
    viewName: "",
  });

  function setCallingChargify(isCallingChargify) {
    dispatch({
      type: CALL_CHARGIFY,
      payload: isCallingChargify,
    });
  }

  function setPaid() {
    dispatch({ type: SET_PAID });
  }

  function setView(name) {
    dispatch({ type: SET_VIEW, payload: name });
  }

  function setServerErrorMessage(errorMessage) {
    dispatch({
      type: SET_SERVER_ERROR_MESSAGE,
      payload: errorMessage,
    });
  }

  function setChargifyErrorMessage(errorMessage) {
    dispatch({
      type: SET_CHARGIFY_ERROR_MESSAGE,
      payload: errorMessage,
    });
  }

  function handleTryAgain() {
    dispatch({ type: HANDLE_RETRY });
  }

  const error = hasError || !!serverErrorMessage;

  useEffect(() => {
    if (error) {
      setView(PAYMENT_ERROR);
    } else if (showNoBalanceDue) {
      setView(PAYMENT_NO_BALANCE);
    } else if (currentBalance === 0) {
      setPaid(true);
    }
  }, [error, showNoBalanceDue, currentBalance]);

  useEffect(() => {
    if (
      paymentError &&
      paymentError.statusCode === 400 &&
      chargifyErrorMessage === ""
    ) {
      // set form error messages on 400 errors
      setChargifyErrorMessage(paymentError.message);
    } else if (paymentError && !isRetry && serverErrorMessage === "") {
      // show the error view
      setServerErrorMessage(paymentError.message);
    }
  }, [chargifyErrorMessage, paymentError, serverErrorMessage, isRetry]);

  return (
    <>
      <div
        className="dunning-background"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      />
      <Grid className="dunning-member-form-container">
        <Grid.Column>
          <div className="dunning-brand-logo-container">
            <img src={logo} alt="" style={{ maxWidth: "200px" }} />
          </div>
          <SegmentPage view={viewName} shouldLogOnViewChange>
            <Segment className="dunning-member-content-container" padded="very">
              <div className="dunning-member-content">
                {loadingUserData ? (
                  <Loader active />
                ) : (
                  <>
                    {!error && (
                      <FormHeader
                        paid={paid || showNoBalanceDue}
                        userName={userName}
                        currentBalance={currentBalance}
                        renewalDate={renewalDate}
                      />
                    )}
                    <div>
                      {error && (
                        <ErrorView
                          hasGraphError={hasError}
                          handleTryAgain={handleTryAgain}
                          conciergeEmail={conciergeEmail}
                          conciergePhone={conciergePhone}
                        />
                      )}
                      <ErrorMessage
                        error={chargifyErrorMessage}
                        onLoad={() => {
                          trackEvent("ErrorShown", null, chargifyErrorMessage);
                        }}
                        messageProps={{
                          icon: "warning",
                          style: { marginTop: "14px" },
                        }}
                      />
                      {!loading && !error && (
                        <FormView
                          paid={paid || showNoBalanceDue}
                          setPaid={setPaid}
                          currentBalance={currentBalance}
                          formConfig={formConfig}
                          callingChargify={callingChargify}
                          savingPayment={savingPayment}
                          setCallingChargify={setCallingChargify}
                          setServerErrorMessage={setServerErrorMessage}
                          setChargifyErrorMessage={setChargifyErrorMessage}
                          addPaymentViaToken={addPaymentViaToken}
                          trackFailedChargifyJS={trackFailedChargifyJS}
                        />
                      )}
                      {!error && (paid || showNoBalanceDue) && (
                        <SuccessView
                          showNoBalanceDue={showNoBalanceDue}
                          renewalDate={renewalDate}
                          brandName={brandName}
                        />
                      )}
                    </div>
                    {!(paid || showNoBalanceDue) && !error && (
                      <footer>
                        <Button
                          style={{ fontWeight: "normal" }}
                          secondary
                          disabled={callingChargify || savingPayment}
                          loading={callingChargify || savingPayment}
                          onClick={setCallingChargify}
                        >
                          <Icon
                            name="lock"
                            style={{
                              color: colorsTheme.white,
                              verticalAlign: "text-top",
                            }}
                          />
                          {t("button_label")}
                        </Button>
                      </footer>
                    )}
                  </>
                )}
              </div>
            </Segment>
          </SegmentPage>
        </Grid.Column>
      </Grid>
    </>
  );
}

DunningMemberForm.propTypes = {
  addPaymentViaToken: PropTypes.func,
  trackFailedChargifyJS: PropTypes.func,
  savingPayment: PropTypes.bool,
  hasError: PropTypes.shape({ message: PropTypes.string }),
  paymentError: PropTypes.shape({
    statusCode: PropTypes.number,
    message: PropTypes.string,
  }),
  publicKey: PropTypes.string,
  serverHost: PropTypes.string,
  loading: PropTypes.bool,
  userName: PropTypes.string,
  currentBalance: PropTypes.number,
  showNoBalanceDue: PropTypes.bool,
  renewalDate: PropTypes.string,
  loadingUserData: PropTypes.bool,
  layoutConfig: PropTypes.shape({
    backgroundImage: PropTypes.string,
    logo: PropTypes.string,
  }),
  brandName: PropTypes.string,
  conciergeEmail: PropTypes.string,
  conciergePhone: PropTypes.string,
};

DunningMemberForm.defaultProps = {
  addPaymentViaToken: () => {},
  trackFailedChargifyJS: () => {},
  savingPayment: false,
  hasError: null,
  paymentError: null,
  publicKey: "",
  serverHost: "",
  loading: false,
  userName: "",
  currentBalance: undefined,
  showNoBalanceDue: false,
  renewalDate: "",
  loadingUserData: false,
  layoutConfig: {
    backgroundImage: "",
    logo: "",
  },
  brandName: "",
  conciergeEmail: "",
  conciergePhone: "",
};
