import PropTypes from "prop-types";
import React from "react";
import { Container } from "semantic-ui-react";

import { appQuery } from "../app-layout/graphql";
import Navbar from "./navbar";

function SimplifiedAppLayout({ loading, children, ...navBarProps }) {
  if (loading) {
    return null;
  }

  return (
    <>
      <Navbar {...navBarProps} />
      <Container className="app-layout">{children}</Container>
    </>
  );
}

SimplifiedAppLayout.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
};

SimplifiedAppLayout.defaultProps = {
  loading: false,
};

export default appQuery(SimplifiedAppLayout);
