import { t } from "localizify";
import { get } from "lodash";
import { useMutation } from "react-apollo";
import * as yup from "yup";

import { LOGIN_REQUEST_ONE_TIME } from "../graphql";

export const CHANNELS = { EMAIL: "EMAIL", SMS: "SMS" };
export const VIEWS = {
  EMAIL_INPUT: "EMAIL_INPUT",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  ONE_TIME_CODE_INPUT: "ONE_TIME_CODE_INPUT",
  OTC_LOCKOUT: "OTC_LOCKOUT",
  PASSWORD_INPUT: "PASSWORD_INPUT",
  RESET_PASSWORD_CONFIRMATION: "RESET_PASSWORD_CONFIRMATION",
  LOGIN_ADMIN: "LOGIN_ADMIN",
};

export const UNRECOVERABLE_OTC_CODES = [
  "BadRequest.MaxAttempts",
  "BadRequest.MaxSendAttempts",
  "BadRequest.TooManyRequests",
  "BadRequest.UnhandledError",
];

export function isLockableOtcError(err) {
  const errCode = get(err, "networkError.response.data.code");
  return UNRECOVERABLE_OTC_CODES.includes(errCode);
}

export function useRequestOneTimeCode({ dispatch, channelTo, onCompleted }) {
  function dispatchOTCRequested() {
    dispatch({
      type: "ONE_TIME_CODE_REQUESTED",
      channelTo,
      channel: CHANNELS.EMAIL,
    });
  }

  const _onCompleted = onCompleted || dispatchOTCRequested;

  const [mutation, { loading }] = useMutation(LOGIN_REQUEST_ONE_TIME, {
    variables: {
      to: channelTo,
      channel: CHANNELS.EMAIL,
    },
    onCompleted: _onCompleted,
    onError: err => {
      if (isLockableOtcError(err)) {
        dispatch({ type: "OTC_LOCKED" });
      }
    },
  });

  return {
    requestOneTimeCode: mutation,
    requestOneTimeCodeLoading: loading,
  };
}

export function getErrorMessage(error) {
  const code =
    get(error, "networkError.response.data.code") || get(error, "code");

  switch (code) {
    case "Invalid.Email":
      return t("auth.login.source_input.error_invalid_source_format");
    case "NotAuthorized.Password":
      return t("auth.login.login_with_password.error_incorrect_password");
    case "BadRequest.InvalidCode":
      return t("auth.login.otc_input.error_invalid_code");
    default:
      return t("auth.login.generic_error");
  }
}

const LS_REMEMBER_KEY = "cco-remember-me";
export function setRememberMe(remember, value) {
  if (remember) {
    return localStorage.setItem(LS_REMEMBER_KEY, value);
  }
  return localStorage.removeItem(LS_REMEMBER_KEY);
}

export function getRememberMe() {
  return localStorage.getItem(LS_REMEMBER_KEY);
}

const channelSchema = yup
  .string()
  .email()
  .required();

// Validate the form
export function isValidChannelTo(emailValue) {
  let isValid = false;
  let error;

  try {
    const channel = channelSchema.validateSync(emailValue);
    if (channel) {
      isValid = true;
    }
  } catch (err) {
    error = {
      code: "Invalid.Email",
    };
  }
  return [isValid, error];
}
