import React from "react";

import { changePassword } from "../graphql";
import PasswordForm from "../reset/form.web";

const WiredPasswordForm = changePassword(PasswordForm);

const Update = () => <WiredPasswordForm currentPasswordRequired />;

export default Update;
