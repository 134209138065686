/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import "./styles.css";

import PropTypes from "prop-types";
import React, { useState } from "react";
import { Container, Icon, List, Popup, Responsive } from "semantic-ui-react";

import colors from "../../../lib/colors";
import { namespacedT } from "../../../lib/i18n";
import prefixUrl from "../../../public-path";
import Avatar from "../../avatar/index.web";
import Link from "../../link/index.web";
import {
  NotificationsIconWired,
  NotificationsPopupWired,
  SidebarNotifications,
} from "./notifications/index.web";
import PrimaryMenuItems, { SignIn } from "./primary-menu-items/index.web";
import SidebarMenu from "./sidebar-menu/index.web";
import UserMenu from "./user-menu/index.web";

const t = namespacedT("app_navbar");

function Navbar({ appName, appIcon, isLoggedIn }) {
  return (
    <>
      <div className="app-navbar avert-container-masthead-rules">
        <Container className="app-navbar-container">
          {/* it needs to refresh the page in order to get the brand default product */}
          {appIcon && (
            <Link href={prefixUrl("/")}>
              <img
                className="app-navbar-logo"
                src={appIcon}
                alt={appName}
                data-ui-action="app-navbar-home"
              />
            </Link>
          )}

          {!isLoggedIn && <SignIn classNamePrefix="app-navbar-menu" />}
        </Container>
      </div>
    </>
  );
}

export default Navbar;

Navbar.propTypes = {
  appName: PropTypes.string,
  appIcon: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.string,
    avatar: PropTypes.string,
    displayName: PropTypes.string,
    company: PropTypes.string,
    community: PropTypes.string,
    location: PropTypes.string,
  }),
  hasForum: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  moreMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
    })
  ),
};

Navbar.defaultProps = {
  appName: "",
  appIcon: "",
  user: {
    id: "",
    avatar: "",
    displayName: "",
    company: "",
    community: "",
    location: "",
  },
  hasForum: false,
  isLoggedIn: false,
  moreMenuItems: [],
};
