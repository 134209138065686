import { t } from "localizify";
import { get } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useMutation } from "react-apollo";
import { Button, Form, Header, Icon } from "semantic-ui-react";

import ButtonTertiary from "../../../components/button/tertiary/index.web";
import { logEvent } from "../../../lib/analytics";
import { LOGIN_VERIFY_ONE_TIME } from "../graphql";
import ErrorText from "./error-text.web";
import { CHANNELS, isLockableOtcError, useRequestOneTimeCode } from "./shared";

const CODE_LENGTH = 5;
const emptyOneTimeCode = new Array(CODE_LENGTH).fill("");

// The individual boxes
function InputCodeBoxes({ error, oneTimeCode, setOneTimeCode }) {
  const refsArray = React.useRef([]);

  function onPaste(e) {
    const pastedValue = e.clipboardData.getData("Text");
    if (pastedValue.length === CODE_LENGTH) {
      setOneTimeCode([...pastedValue]);
    }
  }

  let inputClassNames = "login-area-code-input";

  if (error) {
    inputClassNames = `${inputClassNames} login-error`;
  }

  useEffect(() => {
    if (refsArray.current[0]) {
      refsArray.current[0].focus();
    }
  }, [refsArray]);

  return (
    <>
      {oneTimeCode.map((value, i) => (
        <input
          /* eslint-disable-next-line react/no-array-index-key */
          key={`one-time-input-${i}`}
          maxLength={1}
          inputMode="numeric"
          onPaste={e => onPaste(e, i)}
          ref={ref => {
            refsArray.current[i] = ref;
          }}
          onFocus={() => {
            refsArray.current[i].select();
          }}
          onKeyDown={e => {
            const oldValue = e.target.value;
            // if the same value is entered go to the next input
            if (oldValue === e.key && refsArray.current[i + 1]) {
              e.preventDefault();
              e.stopPropagation();
              refsArray.current[i + 1].focus();
            }

            // deleting empty goes back if possible
            if (
              oldValue === "" &&
              e.keyCode === 8 &&
              refsArray.current[i - 1]
            ) {
              refsArray.current[i - 1].focus();
            }
          }}
          onChange={e => {
            const newValue = e.target.value;
            const otc = [
              ...oneTimeCode.slice(0, i),
              newValue,
              ...oneTimeCode.slice(i + 1),
            ];
            setOneTimeCode(otc);
            if (newValue.length && refsArray.current[i + 1]) {
              refsArray.current[i + 1].focus();
            }
          }}
          className={inputClassNames}
          value={value}
        />
      ))}
    </>
  );
}

InputCodeBoxes.propTypes = {
  error: PropTypes.shape({ message: PropTypes.string }),
  oneTimeCode: PropTypes.string.isRequired,
  setOneTimeCode: PropTypes.func.isRequired,
};

InputCodeBoxes.defaultProps = {
  error: null,
};

export default function OneTimeCodeInputForm({
  channelTo,
  channel,
  code,
  error,
  dispatchLoginState,
  onLoginComplete,
}) {
  const [otcState, setOtcState] = React.useState({
    oneTimeCode: (code && code.split("")) || emptyOneTimeCode,
    resentCode: false,
    error,
  });

  const disabledSubmit = otcState.oneTimeCode.some(v => v === "");

  const channelText =
    channel === CHANNELS.EMAIL
      ? t("auth.login.otc_input.heading_source_email")
      : t("auth.login.otc_input.heading_source_sms");

  const {
    requestOneTimeCode,
    requestOneTimeCodeLoading,
  } = useRequestOneTimeCode({
    dispatch: dispatchLoginState,
    channelTo,
    onCompleted: () => {
      logEvent("MemberLogin", "LinkClick", "Request New Code");
      setOtcState({
        ...otcState,
        error: undefined,
        resentCode: true,
        oneTimeCode: emptyOneTimeCode,
      });
    },
  });

  const [verifyOtc, { loading }] = useMutation(LOGIN_VERIFY_ONE_TIME, {
    variables: {
      to: channelTo,
      code: otcState.oneTimeCode.join(""),
    },
    onCompleted: () => {
      logEvent("MemberLogin", "LoginSuccess", "OTC Success");
      onLoginComplete();
    },
    onError: err => {
      if (err) {
        if (isLockableOtcError(err)) {
          logEvent("MemberLogin", "ErrorShown", "OTC Locked");
          dispatchLoginState({ type: "OTC_LOCKED" });
        } else {
          logEvent(
            "MemberLogin",
            "ErrorShown",
            get(err, "networkError.response.data.code") || "UnknownError"
          );
          setOtcState({ ...otcState, error: err });
        }
      }
    },
  });

  return (
    <>
      <Header size="large">
        {t("auth.login.otc_input.heading", { channelText })}
      </Header>
      <p className="login-area-intro">
        {t("auth.login.otc_input.intro")} <strong>{channelTo}</strong>
      </p>
      <p className="login-area-info">{t("auth.login.otc_input.info")}</p>
      <Form
        size="small"
        onSubmit={e => {
          logEvent("MemberLogin", "FormSubmit", "Sign In OTC");
          verifyOtc(e);
        }}
        className="login-area-form"
      >
        <div
          style={{
            display: "inline-block",
            position: "relative",
            marginBottom: 20,
          }}
        >
          <InputCodeBoxes
            oneTimeCode={otcState.oneTimeCode}
            setOneTimeCode={oneTimeCode => {
              logEvent("MemberLogin", "InputEntry", "OTC");
              setOtcState({ ...otcState, error: undefined, oneTimeCode });
            }}
            error={otcState.error}
          />

          <ErrorText
            error={otcState.error}
            style={{ position: "absolute", top: "calc(100% + 20px)", left: 6 }}
          />
        </div>

        <Button
          className="login-area-cta"
          secondary
          disabled={disabledSubmit || loading}
          loading={loading}
          type="submit"
        >
          {t("auth.login.cta_sign_in")}
        </Button>
        <p>
          {otcState.resentCode ? (
            <span
              style={{
                color: "green",
                fontWeight: "bold",
                fontSize: 14,
                height: "14px",
                display: "block",
                margin: "30px auto",
              }}
            >
              <Icon name="check" color="green" size="small" /> Sent Code
            </span>
          ) : (
            <Button
              basic
              type="button"
              onClick={requestOneTimeCode}
              loading={requestOneTimeCodeLoading}
              style={{
                textDecoration: "underline",
                fontWeight: "bold",
                fontSize: 14,
                boxShadow: "none",
                padding: 0,
                display: "block",
                margin: "30px auto",
              }}
              content={
                <span style={{ color: "#000" }}>
                  {t("auth.login.cta_resend_code")}
                </span>
              }
            />
          )}

          <ButtonTertiary
            onClick={() => {
              logEvent(
                "MemberLogin",
                "LinkClick",
                "Sign In with Email and Password"
              );
              dispatchLoginState({ type: "LOGIN_REQUESTED", channelTo });
            }}
            type="button"
            style={{
              margin: "10px auto 0",
            }}
            content={t("auth.login.cta_use_password")}
            basic
            underlined
            subtle
          />
        </p>
      </Form>
    </>
  );
}

OneTimeCodeInputForm.propTypes = {
  channel: PropTypes.string.isRequired,
  channelTo: PropTypes.string.isRequired,
  code: PropTypes.string,
  dispatchLoginState: PropTypes.func.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  onLoginComplete: PropTypes.func.isRequired,
};

OneTimeCodeInputForm.defaultProps = {
  code: "",
  error: null,
};
