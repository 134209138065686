export default {
  ArticlePullQuote: {
    isPersisting(root, variables, { cache, optimisticResponse }) {
      if (optimisticResponse) {
        cache.writeData({
          data: {
            isPersisting:
              optimisticResponse.articlePullQuoteCreate.isPersisting,
          },
        });
      }
      return null;
    },
  },

  Remark: {
    isPersisting(root, variables, { cache, optimisticResponse }) {
      if (optimisticResponse) {
        cache.writeData({
          data: {
            isPersisting: optimisticResponse.articleFeedbackCreate.isPersisting,
          },
        });
      }
      return null;
    },
  },
};
