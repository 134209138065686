import PropTypes from "prop-types";
import React, { Fragment } from "react";
import {
  Dimmer,
  Icon,
  Responsive,
  Sidebar as SemanticSidebar,
  Transition,
} from "semantic-ui-react";

export default function Sidebar(props) {
  const {
    visible,
    closeOnDimmerClick,
    onClose,
    children,
    hideClose,
    ...sidebarProps
  } = props;

  return (
    <Fragment>
      <Transition animation="fade" visible={visible} unmountOnHide>
        <Dimmer active page onClick={() => closeOnDimmerClick && onClose()} />
      </Transition>

      <SemanticSidebar
        {...sidebarProps}
        visible={visible}
        animation="overlay"
        style={{
          backgroundColor: "#fff",
          zIndex: 1005,
          ...sidebarProps.style,
        }}
      >
        <div
          style={{
            background: "#fff",
            position: "relative",
            minHeight: "100%",
          }}
        >
          <div
            style={{
              position: "sticky",
              top: 0,
              zIndex: 2,
            }}
          >
            <div
              style={{
                background:
                  "radial-gradient(circle, rgba(255,255,255,1) 24%, rgba(255,255,255,0) 80%)",
                padding: "8px 10px 6px",
                position: "absolute",
                left: sidebarProps.direction === "left" ? 0 : "auto",
                right: sidebarProps.direction !== "left" ? 0 : "auto",
                display: hideClose ? "none" : "block",
              }}
            >
              <Responsive minWidth={Responsive.onlyComputer.minWidth}>
                <Icon
                  className="close"
                  onClick={() => onClose(false)}
                  style={{
                    margin: 0,
                    padding: 0,
                    cursor: "pointer",
                  }}
                />
              </Responsive>

              <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
                <Icon
                  className="close"
                  onClick={() => onClose(false)}
                  style={{
                    margin: "15px 0 0 5px",
                    padding: 0,
                    cursor: "pointer",
                    color: "#929292",
                    fontSize: "22px",
                  }}
                />
              </Responsive>
            </div>
          </div>

          {children}
        </div>
      </SemanticSidebar>
    </Fragment>
  );
}

Sidebar.propTypes = {
  visible: PropTypes.bool,
  width: PropTypes.string,
  style: PropTypes.shape(),
  onClose: PropTypes.func,
  closeOnDimmerClick: PropTypes.bool,
  children: PropTypes.node,
  hideClose: PropTypes.bool,
};

Sidebar.defaultProps = {
  visible: false,
  width: null,
  style: null,
  onClose: () => {},
  closeOnDimmerClick: false,
  children: null,
  hideClose: false,
};
