import * as yup from "yup";

import {
  combinedPhoneNumberValidator,
  combinedPhoneNumberValidatorRequired,
} from "../../../components/phone-number-input/validators";
import { ERROR_CODES, getErrorMessageFromCode } from "../../../lib/error";

const formSchema = yup.object().shape({
  email: yup
    .string()
    .email(getErrorMessageFromCode(ERROR_CODES.INVALID_EMAIL))
    .required(),
  mobilePhone: combinedPhoneNumberValidatorRequired,
  officePhone: combinedPhoneNumberValidator,
});

export default formSchema;
