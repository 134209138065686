import gql from "graphql-tag";
import { get } from "lodash";
import { useQuery } from "react-apollo";

const GET_COMMUNITY = gql`
  query {
    community @client {
      products {
        auth {
          config {
            appStoreLink
            playStoreLink
          }
        }
      }
    }
  }
`;

const useCommunity = () => {
  const { data } = useQuery(GET_COMMUNITY);

  const config = get(data, ["community", "products", "auth", "config"]);

  return {
    config,
  };
};

export default useCommunity;
