import "./style.css";

import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Button } from "semantic-ui-react";

export default function ButtonTertiary({ underlined, subtle, ...props }) {
  const classes = classnames(`cco-button-tertiary ${props.className}`, {
    underlined,
    subtle,
  });

  return (
    <Button
      // When the basic prop is true it will apply the color to the button text rather than its background
      basic={!!props.color}
      {...props}
      className={classes}
      style={{
        ...props.style,
        background: "none",
        display: "inline-block",
        padding: 0,
        fontWeight: 400,
      }}
    />
  );
}

ButtonTertiary.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.shape({
    color: PropTypes.string,
  }),
  underlined: PropTypes.bool,
  subtle: PropTypes.bool,
};

ButtonTertiary.defaultProps = {
  className: "",
  color: null,
  style: {},
  underlined: false,
  subtle: false,
};
