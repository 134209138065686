import "@communityco/semantic-ui/dist/semantic.min.css";
import "./variables.css";
import "./style.css";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import AppWrapper from "./app-wrapper";
import prefixUrl from "./public-path";
import Routes from "./routes.web";
import * as serviceWorker from "./serviceWorker";

const { me = {} } = window.__APOLLO_STATE__ || {};
if (me.id) {
  window.analytics.identify(me.id);
}

const App = () => (
  <Router basename={prefixUrl("")}>
    <AppWrapper>
      <Routes />
    </AppWrapper>
  </Router>
);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
