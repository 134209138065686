import { segmentCategories as ArticleCategories } from "../../containers/article/routes-links";
import { segmentCategories as EPCategories } from "../../containers/expert-panels/routes-links";
import { segmentCategories as ProfileCategories } from "../../containers/profile/routes-links";
import { segmentCategories as SettingsCategories } from "../../containers/settings/routes-links";

export default {
  ...ArticleCategories,
  ...EPCategories,
  ...ProfileCategories,
  ...SettingsCategories,
};
