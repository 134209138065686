import * as onboardingConstants from "../../containers/onboarding/constants";
import pathToCategoryMap from "./path-to-category-map";

const legacyViews = [
  onboardingConstants.PAYMENT_STEP,
  onboardingConstants.PASSWORD_STEP,
  onboardingConstants.PROFILE_STEP,
  onboardingConstants.CONTACT_STEP,
  onboardingConstants.EP_LANDING_STEP,
  onboardingConstants.EP_QUESTION_STEP,
  onboardingConstants.COMPLETE_STEP,
  onboardingConstants.ADDRESS_STEP,
  onboardingConstants.FREC_SHUTDOWN_STEP,
];

const excludedRoutes = ["/get-started"];

export function logPageSegment(view) {
  const { pathname, protocol, host } = new URL(window.location.href);
  // pages like onboarding have a lot of PPI so we want to exclude non-virtual logging for excluded routes
  if (!view && excludedRoutes.includes(pathname)) return;

  let viewString = "";
  if (view) {
    viewString = legacyViews.includes(view) ? `/${view}` : `%23view=${view}`;
  }
  const path = `${pathname}${viewString}`;

  window.analytics.page(document.title, {
    path,
    url: `${protocol}//${host}${path}`,
  });
}

export function trackEvent(eventType, category, label, values = {}) {
  const { pathname } = new URL(window.location.href);
  const segmentCategory = category || pathToCategoryMap[pathname];

  window.analytics.track(eventType, {
    label,
    category: segmentCategory,
    ...values,
  });
}
