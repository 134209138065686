import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, Form, Grid, Icon, Responsive } from "semantic-ui-react";

import { TrackCheckbox } from "../segment/track";

const Acknowledge = ({
  acknowledgeLink,
  confirmText,
  acknowledgeText,
  loading,
  checked,
  buttonType,
  plainButton,
  onAccept,
}) => {
  const [hasAccepted, setAccepted] = useState(checked);
  const handleChange = (event, checkbox) => {
    setAccepted(checkbox.checked);
  };
  return (
    <Grid verticalAlign="middle">
      <Grid.Column
        floated="left"
        mobile={16}
        tablet={11}
        computer={11}
        textAlign="left"
      >
        <Form.Field>
          <TrackCheckbox
            eventLabel={acknowledgeText}
            disabled={loading}
            onChange={handleChange}
            label={acknowledgeText}
            defaultChecked={checked}
          />
          {acknowledgeLink}
        </Form.Field>
      </Grid.Column>
      <Grid.Column
        floated="right"
        textAlign="right"
        mobile={16}
        tablet={5}
        computer={5}
      >
        <Responsive {...Responsive.onlyMobile}>
          <Button
            type={buttonType}
            primary
            disabled={!hasAccepted || loading}
            loading={loading}
            onClick={onAccept}
            icon
            fluid
          >
            {confirmText}
            {!plainButton && <Icon className="angle-right" />}
          </Button>
        </Responsive>

        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <Button
            type={buttonType}
            primary
            disabled={!hasAccepted || loading}
            loading={loading}
            onClick={onAccept}
            icon
          >
            {confirmText}
            {!plainButton && <Icon className="angle-right" />}
          </Button>
        </Responsive>
      </Grid.Column>
    </Grid>
  );
};

Acknowledge.propTypes = {
  acknowledgeText: PropTypes.string.isRequired,
  acknowledgeLink: PropTypes.node,
  confirmText: PropTypes.string,
  onAccept: PropTypes.func,
  loading: PropTypes.bool,
  checked: PropTypes.bool,
  buttonType: PropTypes.string,
  plainButton: PropTypes.bool,
};

Acknowledge.defaultProps = {
  confirmText: "Agree",
  acknowledgeLink: null,
  onAccept: () => {},
  loading: false,
  checked: false,
  buttonType: "submit",
  plainButton: false,
};

export default Acknowledge;
