import gql from "graphql-tag";

export const USER_PHONE_FRAGMENT = gql`
  fragment UserPhone_Fragment on UserPhone {
    id
    countryCode
    number
  }
`;

export default gql`
  query {
    username
    me {
      id
      mobilePhone {
        ...UserPhone_Fragment
      }
    }
  }
  ${USER_PHONE_FRAGMENT}
`;
