import "react-credit-cards/es/styles-compiled.css";

import PropTypes from "prop-types";
import React, { useState } from "react";
import { useLocation } from "react-router";
import { Button, Card, Message, Placeholder } from "semantic-ui-react";

import { isAdminUrl } from "../../../../components/lib/url";
import Link from "../../../../components/link/index.web";
import { namespacedT } from "../../../../lib/i18n";
import useIdentity from "../../../../lib/use-identity";
import {
  useChangeDefaultSavedPayment,
  useDeleteSavedPayment,
  usePayments,
  usePaymentsForUser,
} from "../../graphql";
import CardAuthLogWired from "../card-auth-log/index.web";
import PaymentCardItem from "../card-item/index.web";
import AddCardModal from "./add-card-modal.web";

const cardTypes = {
  VISA: "Visa",
  MASTERCARD: "Master Card",
  AMERICAN_EXPRESS: "American Express",
  DISCOVER: "Discover",
  DINERS_CLUB: "Diners Club",
  JCB: "JCB",
};

const t = namespacedT("settings.payment");

function CardPlaceholder() {
  return (
    <Card>
      <Card.Content>
        <Placeholder>
          <Placeholder.Image rectangular />
        </Placeholder>
      </Card.Content>
    </Card>
  );
}

function hasDefaultCardAuthError(cardArray) {
  return cardArray.some(
    ({ isExpired, isFailed, isSoonExpired, isDefault }) =>
      isDefault && (isExpired || isFailed || isSoonExpired)
  );
}

// decorates Message with need-help concierge email message
function NeedHelpErrorMessage({ children, ...props }) {
  const { pathname } = useLocation();
  const isAdmin = isAdminUrl(pathname);

  return (
    <Message {...props}>
      {children}
      {!isAdmin && (
        <>
          {" Need help? Contact "}
          <Link href={t("need_help_email")}>{`${t("need_help_email")}`}</Link>.
        </>
      )}
    </Message>
  );
}

NeedHelpErrorMessage.propTypes = {
  children: PropTypes.node.isRequired,
};

const PaymentContainer = ({
  userId,
  cardArray,
  loading,
  deletePayment,
  errorDelete,
  loadingDelete,
  changeDefaultPayment,
  errorDefault,
  loadingDefault,
  noAuthLogs,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const [authLogState, setAuthLogState] = useState({
    showCardAuthLog: false,
    card: {},
  });

  function toggleCardAuthLog(card) {
    if (noAuthLogs) {
      return;
    }
    // can show if its not selected
    const isNotCurrentlySelected = authLogState.card.id !== card.id;

    // show if its hidden or theres a new card selected and we're just swapping out card details
    const showCardAuthLog =
      isNotCurrentlySelected || !authLogState.showCardAuthLog;

    const logState = {
      showCardAuthLog,
      card: showCardAuthLog ? card : {},
    };

    setAuthLogState(logState);
  }

  const { pathname } = useLocation();
  const isAdmin = isAdminUrl(pathname);

  function messageSelection(card) {
    const cardType = cardTypes[`${card.cardType}`];
    const { last4Digits } = card;
    if (card.isExpired) {
      return (
        <Message negative>
          {isAdmin
            ? t("admin.expired_card_message", { cardType, last4Digits })
            : t("expired_card_message", { cardType, last4Digits })}
        </Message>
      );
    }

    if (card.isFailed) {
      return (
        <Message negative>
          {isAdmin
            ? t("admin.failed_card_message", { cardType, last4Digits })
            : t("failed_card_message", { cardType, last4Digits })}
        </Message>
      );
    }

    if (card.isSoonExpired) {
      return (
        <Message warning>
          {isAdmin
            ? t("admin.expired_soon_card_message", { cardType, last4Digits })
            : t("expired_soon_card_message", { cardType, last4Digits })}
        </Message>
      );
    }
    return null;
  }

  return (
    <div>
      <div style={{ textAlign: "right" }}>
        <Button onClick={() => setModalOpen(true)} primary>
          {t("add_new_card")}
        </Button>
      </div>
      {cardArray.map(card => {
        return messageSelection(card);
      })}
      {hasDefaultCardAuthError(cardArray) && (
        <NeedHelpErrorMessage>{t("update_card")}</NeedHelpErrorMessage>
      )}
      {errorDefault && (
        <NeedHelpErrorMessage negative>
          {isAdmin
            ? t("admin.error_change_default")
            : t("error_change_default")}
        </NeedHelpErrorMessage>
      )}
      {errorDelete && (
        <NeedHelpErrorMessage negative>
          {isAdmin ? t("admin.error_delete_card") : t("error_delete_card")}
        </NeedHelpErrorMessage>
      )}
      {loading && <CardPlaceholder />}
      {modalOpen && (
        <AddCardModal
          userId={userId}
          closeModal={() => setModalOpen(false)}
          isAdmin={isAdmin}
        />
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        {cardArray.map(card => {
          return (
            <PaymentCardItem
              key={card.id}
              {...card}
              toggleDefaultItem={changeDefaultPayment}
              handleRemove={deletePayment}
              loadingDelete={loadingDelete}
              loadingDefault={loadingDefault}
              toggleCardAuthLog={() => toggleCardAuthLog(card)}
              visibleAuthLog={authLogState.card.id === card.id}
              noAuthLog={noAuthLogs}
              isAdmin={isAdmin}
            />
          );
        })}
      </div>
      {authLogState.showCardAuthLog && !noAuthLogs && (
        <CardAuthLogWired {...authLogState.card} />
      )}
    </div>
  );
};

PaymentContainer.propTypes = {
  userId: PropTypes.string,
  loading: PropTypes.bool,
  cardArray: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      last4Digits: PropTypes.string,
      cardType: PropTypes.string,
      expirationMonth: PropTypes.number,
      expirationYear: PropTypes.number,
      isDefault: PropTypes.bool,
      isExpired: PropTypes.bool,
      isSoonExpired: PropTypes.bool,
    })
  ),
  deletePayment: PropTypes.func,
  errorDelete: PropTypes.shape({ message: PropTypes.string }),
  loadingDelete: PropTypes.bool,
  changeDefaultPayment: PropTypes.func,
  errorDefault: PropTypes.shape({ message: PropTypes.string }),
  loadingDefault: PropTypes.bool,
  noAuthLogs: PropTypes.bool,
};

PaymentContainer.defaultProps = {
  userId: "",
  loading: false,
  cardArray: [],
  deletePayment: () => {},
  errorDelete: null,
  loadingDelete: false,
  changeDefaultPayment: () => {},
  errorDefault: null,
  loadingDefault: false,
  noAuthLogs: false,
};

export function PaymentContainerWiredMember() {
  const { userId } = useIdentity();
  const { cardArray, loading } = usePayments();
  const { destroy, errorDelete, loadingDelete } = useDeleteSavedPayment();
  const {
    changeDefault,
    errorDefault,
    loadingDefault,
  } = useChangeDefaultSavedPayment();

  return (
    <PaymentContainer
      userId={userId}
      cardArray={cardArray}
      loading={loading}
      deletePayment={destroy}
      errorDelete={errorDelete}
      loadingDelete={loadingDelete}
      changeDefaultPayment={changeDefault}
      errorDefault={errorDefault}
      loadingDefault={loadingDefault}
      noAuthLogs
    />
  );
}

function PaymentContainerWiredAdmin({ userId }) {
  const { cardArray, loading } = usePaymentsForUser(userId);
  const { destroy, errorDelete, loadingDelete } = useDeleteSavedPayment(userId);
  const {
    changeDefault,
    errorDefault,
    loadingDefault,
  } = useChangeDefaultSavedPayment(userId);
  return (
    <PaymentContainer
      userId={userId}
      cardArray={cardArray}
      loading={loading}
      deletePayment={destroy}
      errorDelete={errorDelete}
      loadingDelete={loadingDelete}
      changeDefaultPayment={changeDefault}
      errorDefault={errorDefault}
      loadingDefault={loadingDefault}
    />
  );
}

PaymentContainerWiredAdmin.propTypes = {
  userId: PropTypes.string,
};

PaymentContainerWiredAdmin.defaultProps = {
  userId: "",
};

export default PaymentContainerWiredAdmin;
