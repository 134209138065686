import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Header, Icon, List, Segment, Visibility } from "semantic-ui-react";

import InlineLink from "../../../components/link/index.web";
import colors from "../../../lib/colors";
import { namespacedT } from "../../../lib/i18n";
import NotificationItemLoading from "../notification-item-loading/index.web";
import NotificationItem from "../notification-item/index.web";
import { linkTo } from "../route-links";
import { useMarkAllAsRead, useMarkAsRead, useNotifications } from "./hooks";

const t = namespacedT("notifications");

export function NotificationListPure({
  fetchMore,
  markAsRead,
  markAllAsRead,
  notifications,
  hasMore,
  loading,
  isCompact,
  pageSize,
  refetch,
  refetching,
  onClose,
  showCloseIcon,
}) {
  const showLoadingFetcher = notifications.length > 0 && hasMore;
  // TODO: Re-enable if add avatar to the graph type: https://communityco.atlassian.net/browse/DEV-1759
  // const showAvatar = !isCompact;
  const showAvatar = false;

  useEffect(() => {
    refetch();
  }, [refetch]);

  const renderLoading = () => {
    return new Array(pageSize).fill().map((id, i) => {
      return (
        <NotificationItemLoading
          key={`loadingNotification-${id || i}`}
          showAvatar={showAvatar}
        />
      );
    });
  };

  const renderContent = () => {
    if (notifications.length) {
      return notifications.map(notification => (
        <NotificationItem
          {...notification}
          key={notification.id}
          showAvatar={showAvatar}
          markAsRead={markAsRead}
        />
      ));
    }
    return loading ? null : (
      <Segment basic vertical textAlign="center" style={{ paddingTop: "50%" }}>
        <p style={{ color: colors.grayBase }}>
          {t("notifications_list.no_data")}
        </p>
      </Segment>
    );
  };

  const renderSeeAll = () => {
    return (
      <div
        style={{
          borderTop: `1px solid ${colors.silverDark}`,
          padding: ".75em 1em",
          textAlign: "center",
          fontWeight: 500,
        }}
      >
        <InlineLink to={linkTo.notifications()} onClick={onClose}>
          {t("notifications_list.footer")}
        </InlineLink>
      </div>
    );
  };

  return (
    <Segment basic style={{ padding: "0", marginTop: ".25em" }}>
      <div
        style={{
          borderBottom: `1px solid ${colors.silverDark}`,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
          padding: ".75em 1em",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {showCloseIcon && (
            <Icon
              className="close"
              style={{
                marginRight: "1em",
                fontSize: "22px",
                color: "#929292",
                cursor: "pointer",
              }}
              onClick={onClose}
            />
          )}
          <Header
            content={t("title")}
            as={isCompact ? "h3" : "h2"}
            style={{ margin: 0, fontWeight: 400 }}
          />
        </div>
        <InlineLink onClick={markAllAsRead}>
          {t("notifications_list.cta_clear")}
        </InlineLink>
      </div>
      <List
        divided
        relaxed
        selection
        style={Object.assign(
          { margin: 0 },
          isCompact
            ? {
                // 63px is the height of each row at this time, - 35px for the partial offset
                height: `${63 * pageSize - 35}px`,
                overflowY: "scroll",
                width: "350px",
              }
            : {}
        )}
      >
        {!refetching && renderContent()}
        {loading && renderLoading()}
        {showLoadingFetcher && <Visibility onTopVisible={fetchMore} />}
      </List>
      {isCompact && renderSeeAll()}
    </Segment>
  );
}

NotificationListPure.propTypes = {
  // queries /mutations
  fetchMore: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  markAsRead: PropTypes.func.isRequired,
  markAllAsRead: PropTypes.func.isRequired,
  // query response
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      message: PropTypes.string,
      url: PropTypes.string,
      createdAt: PropTypes.string,
      avatar: PropTypes.string,
      hasRead: PropTypes.bool,
      loading: PropTypes.bool,
    })
  ),
  hasMore: PropTypes.bool,
  loading: PropTypes.bool,
  isCompact: PropTypes.bool,
  pageSize: PropTypes.number,
  refetching: PropTypes.bool,
  onClose: PropTypes.func,
  showCloseIcon: PropTypes.bool,
};

NotificationListPure.defaultProps = {
  notifications: [],
  hasMore: false,
  loading: false,
  isCompact: false,
  pageSize: 10,
  refetching: false,
  onClose: () => {},
  showCloseIcon: false,
};

export default function NotificationsListWired(props) {
  const { pageSize } = props;
  const {
    notifications,
    loading,
    hasMore,
    fetchMore,
    refetch,
    refetching,
  } = useNotifications(pageSize);
  const { markAsRead } = useMarkAsRead();
  const { markAllAsRead } = useMarkAllAsRead();

  return (
    <NotificationListPure
      {...props}
      notifications={notifications}
      loading={loading}
      hasMore={hasMore}
      fetchMore={fetchMore}
      markAsRead={markAsRead}
      markAllAsRead={() => markAllAsRead(notifications)}
      refetch={refetch}
      refetching={refetching}
    />
  );
}

NotificationsListWired.propTypes = {
  pageSize: PropTypes.number,
};

NotificationsListWired.defaultProps = {
  pageSize: 10,
};
