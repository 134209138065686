import PropTypes from "prop-types";
import React from "react";
import { List } from "semantic-ui-react";

import Avatar from "../../../components/avatar/index.web";
import RelativeTime from "../../../components/relative-time/index.web";
import colors from "../../../lib/colors";

export default function NotificationItem({
  markAsRead,
  id,
  message,
  url,
  createdAt,
  avatar,
  hasRead,
  showAvatar,
}) {
  return (
    <List.Item
      onClick={async e => {
        e.preventDefault();
        await markAsRead([id]);
        window.location = url;
      }}
      as="a"
      href={url}
      style={{ padding: "1em", display: "flex" }}
    >
      <div style={{ display: "flex", alignItems: "strech", width: "10px" }}>
        {!hasRead && (
          <List.Icon
            className="filled-circle"
            size="small"
            style={{
              color: colors.blueBase,
              margin: "4px 0",
              fontSize: "10px",
            }}
          />
        )}
      </div>
      {showAvatar && (
        <Avatar
          avatar
          bordered
          size="mini"
          style={{ maxHeight: "35px", margin: "0 .25em 0 .5em" }}
          src={avatar}
        />
      )}
      <List.Content
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "4px",
          fontWeight: 400,
          overflow: "hidden",
        }}
      >
        <p
          style={{
            marginBottom: 0,
            color: colors.grayDarkest,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {message}
        </p>
        <List.Description style={{ color: colors.grayBase }}>
          <RelativeTime timestamp={createdAt} compact />
        </List.Description>
      </List.Content>
    </List.Item>
  );
}

NotificationItem.propTypes = {
  markAsRead: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  hasRead: PropTypes.bool,
  showAvatar: PropTypes.bool,
};

NotificationItem.defaultProps = {
  avatar: null,
  hasRead: false,
  showAvatar: false,
};
