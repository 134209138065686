import PropTypes from "prop-types";
import React from "react";
import { Divider, Grid, Header } from "semantic-ui-react";

import colors from "../../../lib/colors";

function SettingsSection({ title, headerRight, children }) {
  return (
    <div
      style={{
        marginBottom: "40px",
      }}
    >
      <Grid>
        <Grid.Row
          columns={2}
          style={{
            padding: "24px 0 21px",
          }}
        >
          <Grid.Column width={8}>
            <Header
              as="h3"
              style={{ fontSize: "20px", color: colors.grayMedium }}
            >
              {title}
            </Header>
          </Grid.Column>

          <Grid.Column width={8}>
            {headerRight && <Header textAlign="right">{headerRight}</Header>}
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider
        style={{
          marginBottom: "8px",
          borderColor: colors.silverDark,
          borderBottom: "none",
          width: "110%",
        }}
      />

      {children}
    </div>
  );
}

export default SettingsSection;

SettingsSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  headerRight: PropTypes.node,
};

SettingsSection.defaultProps = {
  title: "",
  children: null,
  headerRight: null,
};
