import { t } from "localizify";
import propTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { Button, Form, Header, Message } from "semantic-ui-react";

import Link from "../../../components/link/index.web";
import PasswordStrengthIndicator from "../../../components/password-strength/index.web";
import prefixUrl from "../../../public-path";

const PasswordForm = ({
  error,
  loading,
  success,
  reset,
  currentPasswordRequired,
}) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordBlurred, setNewPasswordBlurred] = useState(false);
  const [confirmPasswordBlurred, setConfirmPasswordBlurred] = useState(false);
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [passwordPasses, setPasswordPasses] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);

  if (success) {
    return (
      <Fragment>
        <Header>{t("password.reset.success_heading")}</Header>
        {!currentPasswordRequired && (
          <>
            <p>{t("password.reset.success_message")}</p>
            <Button fluid primary as={Link} href={prefixUrl("/")}>
              {t("password.reset.cta_success")}
            </Button>
          </>
        )}
      </Fragment>
    );
  }

  const onSubmit = e => {
    e.preventDefault();

    if (currentPasswordRequired && !currentPassword) {
      return;
    }

    if (passwordPasses && passwordsMatch) {
      if (currentPasswordRequired) {
        reset(currentPassword, newPasswordConfirm);
      } else {
        reset(newPasswordConfirm);
      }
    }
  };

  return (
    <Form
      size="large"
      onSubmit={e => onSubmit(e)}
      error={!!error}
      disabled={loading}
      data-ui-container="change-password-form"
    >
      {currentPasswordRequired && (
        <Form.Input
          style={{ marginBottom: 50 }}
          fluid
          icon="lock"
          iconPosition="left"
          placeholder="Current password"
          type="password"
          name="currentPassword"
          id="currentPassword"
          onChange={({ target: { value } }) => {
            setCurrentPassword(value);
          }}
          value={currentPassword}
          label="Current Password"
          error={newPasswordBlurred && !currentPassword}
        />
      )}

      <Form.Input
        fluid
        icon="lock"
        iconPosition="left"
        placeholder="New password"
        type="password"
        name="newPassword"
        id="newPassword"
        onChange={({ target: { value } }) => {
          setNewPasswordConfirm("");
          setNewPassword(value);
        }}
        value={newPassword}
        label="New Password"
        onBlur={() => setNewPasswordBlurred(true)}
        error={newPasswordBlurred && !passwordPasses}
      />
      <PasswordStrengthIndicator
        password={newPassword}
        onPassing={() => setPasswordPasses(true)}
        onFailing={() => setPasswordPasses(false)}
      />
      <Form.Input
        fluid
        icon="lock"
        iconPosition="left"
        placeholder="Retype new password"
        type="password"
        label="Confirm Password"
        name="newPasswordConfirm"
        id="newPasswordConfirm"
        onChange={({ target: { value } }) => {
          setNewPasswordConfirm(value);
          setPasswordsMatch(value === newPassword);
        }}
        onBlur={({ target: { value } }) => {
          setPasswordsMatch(value === newPassword);
          setConfirmPasswordBlurred(true);
        }}
        value={newPasswordConfirm}
        error={confirmPasswordBlurred && !passwordsMatch}
      />

      <Message
        error
        header="Error Occurred"
        content={
          error ? error.message || "An unknown error occurred. Try again." : ""
        }
      />
      <Button
        style={{ marginTop: 40 }}
        fluid
        primary
        type="submit"
        loading={loading}
        disabled={
          !passwordPasses ||
          !passwordsMatch ||
          loading ||
          (currentPasswordRequired && !currentPassword)
        }
      >
        {t("password.reset.cta")}
      </Button>
    </Form>
  );
};

PasswordForm.propTypes = {
  error: propTypes.string,
  loading: propTypes.bool,
  success: propTypes.bool,
  reset: propTypes.func.isRequired,
  currentPasswordRequired: propTypes.bool,
};

PasswordForm.defaultProps = {
  error: "",
  loading: false,
  success: false,
  currentPasswordRequired: false,
};

export default PasswordForm;
