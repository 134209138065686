import { forOwn, get } from "lodash";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";

import { ERROR_CODES, getErrorCodeFromMessage } from "../error";
import actions from "./analytics-actions.json";
import categories from "./analytics-categories.json";
import { logPageSegment } from "./segment";
import { event, modalView, pageView } from "./tracking";

const DEFAULT_ACCT = "UA-123182406-1";

let initialized = false;

function getCustomDimensions(me) {
  const roles = get(me, "roles", []);
  const dimension1 = get(me, "communityCode");
  let dimension3;

  if (roles.includes("ADMIN")) {
    dimension3 = "ADMIN";
  } else if (roles.includes("MEMBER")) {
    dimension3 = "MEMBER";
  }

  return {
    dimension1, // communityCode
    // dimension2 isn't currently active in GA
    dimension3, // role
  };
}

function getCategory(path) {
  const found = get(categories, path);

  if (!found) {
    // eslint-disable-next-line no-console
    console.warn(`Missing GA Category for path: ${path}`);
    return (
      path ||
      `Missing Category | ${window.location.pathname + window.location.search}`
    );
  }

  return found;
}

function getAction(path) {
  const found = get(actions, path);

  if (!found) {
    // eslint-disable-next-line no-console
    console.warn(`Missing GA Action for path: ${path}`);
    return path || "Unknown Action";
  }

  return found;
}

export function logPageView(path) {
  if (initialized && path) {
    ReactGA.pageview(path);
    pageView(path);
  }

  logPageSegment();
}

export function logModalView(path) {
  ReactGA.modalview(path);
  modalView(path);
}

export function logEvent(category, action, label, value) {
  if (initialized && category && action) {
    ReactGA.event({
      category: getCategory(category),
      action: getAction(action),
      label,
      value,
    });
    event(category, action, label);
  }
}

export function logNonHumanEvent(category, action, label, value) {
  if (initialized && category && action) {
    ReactGA.event({
      category: getCategory(category),
      action: getAction(action),
      label,
      value,
      nonInteraction: true,
    });
    event(category, action, label);
  }
}

export function logException(description, fatal = false) {
  if (initialized && description) {
    ReactGA.exception({ description, fatal });
  }
}

function initGA(gaAcct, me) {
  const customDimensions = getCustomDimensions(me);
  const opts = { userId: me.id };

  ReactGA.initialize(
    process.env.NODE_ENV !== "production"
      ? DEFAULT_ACCT
      : gaAcct || DEFAULT_ACCT,
    {
      debug: process.env.NODE_ENV !== "production",
      gaOptions: opts,
    }
  );

  initialized = true;

  ReactGA.set(customDimensions);
}

function initTM(gtmId) {
  const tagManagerArgs = {
    gtmId,
    // The following 2 properties are needed to run in debug mode. These are example values, you can get the real values from your
    // debug sessions URL parameters. Ex. gtm_preview & gtm_auth
    // preview: "env-1",
    // auth: "584cB123a9CacId123_AbC",
  };
  TagManager.initialize(tagManagerArgs);
}

export function initializeGaFromConfig({
  community: { googleAnalytics, googleTagManager } = {},
  me: { id, communityCode, roles } = {},
} = {}) {
  if (!initialized) {
    const me = {
      id,
      communityCode,
      roles,
    };
    initGA(googleAnalytics, me);
    initTM(googleTagManager);
  }
}

// Form Helpers
export function logFormBlur(category, name, value) {
  if (category && name) {
    logEvent(
      category,
      value ? "form.blur-with-value" : "form.blur-no-value",
      name
    );
  }
}

export function logFormErrors(category, errors) {
  if (category && errors) {
    forOwn(errors, (value, key) => {
      if (value && value.hasError) {
        logEvent(
          category,
          "form.validate-fail",
          key,
          value && value.message
            ? getErrorCodeFromMessage(value.message)
            : ERROR_CODES.MISSING_INPUT
        );
      }
    });
  }
}
