import "./styles.css";

import { t } from "localizify";
import { get } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Header, Icon } from "semantic-ui-react";
import * as yup from "yup";

import { memberClient } from "../../../graphql";
import { logEvent } from "../../../lib/analytics";
import { LOGIN_REQUEST_AUTH_STRATEGIES } from "../graphql";
import { linkTo } from "../routes-links";
import ErrorText from "./error-text.web";
import { CHANNELS, getRememberMe, setRememberMe } from "./shared";

// Email input schema...
const channelSchema = yup
  .string()
  .email()
  .required();

// Validate the form
export function isValidChannelTo(emailValue) {
  let isValid = false;
  let error;

  try {
    const channel = channelSchema.validateSync(emailValue);
    if (channel) {
      isValid = true;
    }
  } catch (err) {
    error = {
      code: "Invalid.Email",
    };
  }
  return [isValid, error];
}

export default function SourceInputForm({
  dispatchLoginState,
  error,
  loading,
  channelTo,
  membershipInfoLink,
}) {
  const remembered = getRememberMe();
  const _channelTo = channelTo || remembered || "";
  const [initialValid] = isValidChannelTo(_channelTo);
  const history = useHistory();

  const [form, setForm] = React.useState({
    channelTo: _channelTo,
    isValid: initialValid,
    rememberEmail: true, // default to remember me checked
    unrecognizedEmail: error === "Unrecognized.Email",
    loading,
  });

  async function onSubmit() {
    const [isValid, invalidErr] = isValidChannelTo(form.channelTo.trim());

    if (!isValid) {
      return setForm({ ...form, isValid, hasChanged: true, error: invalidErr });
    }

    setRememberMe(form.rememberEmail, form.channelTo.trim());
    setForm({ ...form, loading: true });

    return memberClient
      .query({
        query: LOGIN_REQUEST_AUTH_STRATEGIES,
        fetchPolicy: "network-only",
        variables: {
          to: form.channelTo.trim(),
          channel: CHANNELS.EMAIL,
        },
      })
      .then(res => {
        const {
          data: { authStrategies },
        } = res;
        const hasAuthStrategy =
          authStrategies.passwordStrategy || authStrategies.otcSmsStrategy;
        if (hasAuthStrategy) {
          logEvent("MemberLogin", "FormSubmit", "Continue");
          return dispatchLoginState({
            type: "LOGIN_REQUESTED",
            channelTo: form.channelTo.trim(),
            ...authStrategies,
          });
        }
        logEvent("MemberLogin", "ErrorShown", "Email Not Found");
        return setForm({ ...form, loading: false, unrecognizedEmail: true });
      })
      .catch(err => {
        // redirect admins to the old admin login
        const isAdmin =
          get(err, "networkError.response.data.code") === "BadRequest.NoAdmins";
        if (isAdmin) {
          history.replace(`${linkTo.loginAdmin()}${window.location.search}`);
          dispatchLoginState({
            type: "ADMIN_LOGIN_REQUESTED",
            channelTo: form.channelTo.trim(),
          });
        } else {
          logEvent(
            "MemberLogin",
            "ErrorShown",
            get(err, "networkError.response.data.code") || "UnknownError"
          );
          setForm({ ...form, loading: false, error: err });
        }
      });
  }

  // We'll either have a validation error, or network / unknown error
  const formError = form.error || error;

  const text = {
    header: t("auth.login.source_input.heading"),
    intro: t("auth.login.source_input.intro"),
    nonMemberCTA: t("auth.login.source_input.non_member_cta"),
    nonMemberLink: t("auth.login.source_input.non_member_link"),
  };

  if (form.unrecognizedEmail) {
    text.header = t("auth.login.source_input.heading_unrecognized");
    text.intro = t("auth.login.source_input.intro_unrecognized");
  }

  return (
    <>
      <Header size="large" data-ui-container="login-form-header">
        {text.header}
      </Header>
      <p className="login-area-intro">{text.intro}</p>
      <Form size="small" onSubmit={onSubmit} className="login-area-form">
        <Form.Input
          disabled={form.loading}
          error={!!formError && formError !== "Unrecognized.Email"}
          id="source"
          loading={form.loading}
          name="source"
          icon={<Icon className="envelope-solid" />}
          iconPosition="left"
          placeholder={t("auth.login.source_input.placeholder_source")}
          className="login-area-input"
          size="small"
          type="email"
          autoCapitalize="none"
          fluid
          onChange={e => {
            const channelToValue = e.target.value;
            const formState = {
              ...form,
              channelTo: channelToValue,
            };

            const [isValid, err] = isValidChannelTo(channelToValue);
            formState.isValid = isValid;

            if (form.hasChanged) {
              formState.error = err;
            }

            setForm(formState);
          }}
          onBlur={() => {
            const [isValid, err] = isValidChannelTo(form.channelTo);
            setForm({ ...form, isValid, error: err, hasChanged: true });
          }}
          value={form.channelTo}
        />

        <ErrorText error={formError} />

        <Form.Checkbox
          className="login-area-checkbox"
          label={t("auth.login.source_input.remember_text")}
          onChange={(e, value) => {
            logEvent(
              "MemberLogin",
              "ElementToggle",
              `Remember Me ${value.checked ? "True" : "False"}`
            );

            // forget me right away if this gets checked!
            if (!value.checked) {
              setRememberMe(false);
            }
            setForm({ ...form, rememberEmail: value.checked });
          }}
          checked={form.rememberEmail}
        />

        <Button
          className="login-area-cta"
          disabled={!form.isValid || form.loading}
          loading={form.loading}
          secondary
          type="submit"
        >
          {t("auth.login.source_input.cta")}
        </Button>
      </Form>
      <p className="login-area-non-member-cta">
        {text.nonMemberCTA}{" "}
        <a className="login-area-non-member-link" href={membershipInfoLink}>
          {text.nonMemberLink}
        </a>
      </p>
    </>
  );
}

SourceInputForm.propTypes = {
  dispatchLoginState: PropTypes.func.isRequired,
  error: PropTypes.shape({ message: PropTypes.string }),
  loading: PropTypes.bool,
  channelTo: PropTypes.string,
  membershipInfoLink: PropTypes.string.isRequired,
};

SourceInputForm.defaultProps = {
  error: null,
  loading: false,
  channelTo: "",
};
