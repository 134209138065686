import get from "lodash/get";

const getServerError = error => {
  return get(error, "networkError.response.data") || {};
};

class ServerError extends Error {
  constructor(error) {
    const { statusCode, message, code } = getServerError(error);
    super(message);
    this.statusCode = statusCode;
    this.code = code;
  }
}
export default ServerError;
