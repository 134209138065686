import gql from "graphql-tag";

export default gql`
  query {
    me {
      id
      assistant {
        id
        name
        mobilePhone {
          countryCode
          number
          extension
        }
        officePhone {
          countryCode
          number
          extension
        }
        email
      }
      publicRelations {
        id
        name
        mobilePhone {
          countryCode
          number
          extension
        }
        officePhone {
          countryCode
          number
          extension
        }
        email
      }
    }
  }
`;
