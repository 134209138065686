import gql from "graphql-tag";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import {
  Button,
  Comment,
  Form,
  Icon,
  Message,
  Placeholder,
} from "semantic-ui-react";

import Avatar from "../../../components/avatar/index.web";
import { USER_SNAPSHOT_FRAGMENT } from "../../../components/member-card/graphql";
import useCurrentUser from "../../../hooks/current-user-hook";
import relativeTime from "../../../lib/relative-time";

const REMARK_FRAGMENT = gql`
  fragment Remark_Fragment on Remark {
    id
    remark
    createdAt
    hasRead
    author {
      id
      snapshot {
        ...UserSnapshot_Fragment
      }
    }
  }
  ${USER_SNAPSHOT_FRAGMENT}
`;

const ARTICLE_FEEDBACK = gql`
  query articleFeedback($articleId: String!, $last: Int, $before: String) {
    articleFeedback(articleId: $articleId, last: $last, before: $before) {
      pageInfo {
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...Remark_Fragment
        }
      }
    }
  }
  ${REMARK_FRAGMENT}
`;

const ARTICLE_FEEDBACK_CREATE = gql`
  mutation articleFeedbackCreate($articleId: String!, $feedback: String!) {
    articleFeedbackCreate(articleId: $articleId, feedback: $feedback) {
      ...Remark_Fragment
    }
  }
  ${REMARK_FRAGMENT}
`;

export default function Feedback({ articleId, onHasUnread }) {
  const { currentUser } = useCurrentUser();
  const [feedbackInput, setFeedbackInput] = useState("");
  const queryVariables = {
    articleId,
    last: 20,
  };

  const { data, loading } = useQuery(ARTICLE_FEEDBACK, {
    variables: queryVariables,
  });

  const articleFeedbackEdges = data ? data.articleFeedback.edges : [];
  const hasUnread =
    articleFeedbackEdges.length > 0 &&
    !!articleFeedbackEdges.find(({ node }) => !node.hasRead);

  useEffect(() => {
    if (hasUnread) {
      onHasUnread();
    }
  }, [onHasUnread, hasUnread]);

  const [addFeedback, { loading: saving, error }] = useMutation(
    ARTICLE_FEEDBACK_CREATE,
    {
      update(cache, { data: { articleFeedbackCreate } }) {
        const queryArticleFeedback = {
          query: ARTICLE_FEEDBACK,
          variables: queryVariables,
        };

        const cacheData = cache.readQuery(queryArticleFeedback);

        cacheData.articleFeedback.edges.push({
          cursor: "",
          node: {
            ...articleFeedbackCreate,
            hasRead: true,
          },
          __typename: "RemarksConnectionEdge",
        });

        cache.writeQuery({
          ...queryArticleFeedback,
          data: cacheData,
        });
      },
    }
  );

  function handleAddFeedback() {
    addFeedback({
      variables: {
        articleId,
        feedback: feedbackInput,
      },
    });
    setFeedbackInput("");
  }

  if (loading) {
    return (
      <div className="ie-card">
        <h4 className="ie-card-title">
          <Placeholder>
            <Placeholder.Header>
              <Placeholder.Line length="medium" />
            </Placeholder.Header>
          </Placeholder>
        </h4>

        <div className="ie-card-content">
          <Placeholder>
            <Placeholder.Line length="full" />
            <Placeholder.Line length="full" />
            <Placeholder.Line length="full" />
            <Placeholder.Line length="short" />
          </Placeholder>
        </div>
      </div>
    );
  }

  function formatRemark(remark) {
    return remark.split("\n").map((line, index, array) => (
      // eslint-disable-next-line react/no-array-index-key
      <React.Fragment key={index}>
        {line}
        {index !== array.length - 1 && <br />}
      </React.Fragment>
    ));
  }

  return (
    <div className="ie-card ie-feedback">
      <h4 className="ie-card-title">Article Feedback</h4>

      <div className="ie-feedback-messages-container">
        <Comment.Group className="ie-feedback-messages">
          {articleFeedbackEdges.map(({ node }) => (
            <Comment key={node.id}>
              <div className="ie-feedback-meta">
                <Avatar
                  className="ie-feedback-avatar"
                  src={node.author.snapshot.avatar}
                  size={40}
                />
                <Comment.Content>
                  <Comment.Author className="ie-feedback-author">
                    {currentUser.id === node.author.id
                      ? "You"
                      : node.author.snapshot.name}
                  </Comment.Author>
                  <Comment.Metadata className="ie-feedback-time">
                    <span>{relativeTime(node.createdAt)}</span>
                  </Comment.Metadata>
                </Comment.Content>
              </div>

              <Comment.Content className="ie-feedback-message">
                <Comment.Text>{formatRemark(node.remark)}</Comment.Text>
              </Comment.Content>
            </Comment>
          ))}
        </Comment.Group>
      </div>

      {error && (
        <Message
          negative
          className="ie-error-message"
          style={{ margin: "0 7px" }}
        >
          <Message.Header>
            An error occurred while submitting your feedback.
          </Message.Header>
          <p>Please try again.</p>
        </Message>
      )}

      <Form className="ie-feedback-form">
        <Form.TextArea
          className="ie-feedback-input"
          placeholder="Type a message..."
          value={feedbackInput}
          onChange={({ target: { value } }) => {
            setFeedbackInput(value);
          }}
        />
        <Button
          className="ie-button ie-feedback-button"
          disabled={feedbackInput === "" || saving}
          loading={saving}
          onClick={handleAddFeedback}
          fluid
        >
          Send <Icon className="send" />
        </Button>
      </Form>
    </div>
  );
}

Feedback.propTypes = {
  articleId: PropTypes.string,
  onHasUnread: PropTypes.func,
};

Feedback.defaultProps = {
  articleId: "",
  onHasUnread: () => {},
};
