import "react-credit-cards/es/styles-compiled.css";

import PropTypes from "prop-types";
import React from "react";
import { Placeholder, Table } from "semantic-ui-react";

import { namespacedT } from "../../../../lib/i18n";
import { useCardAuthLog } from "../../graphql";

const t = namespacedT("settings.payment.admin.authLog");

function formatDateString(dateString) {
  const date = new Date(dateString);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
}

function reversalStatus(reversalHttpCode) {
  if (reversalHttpCode === null) {
    return t("not_applicable");
  }

  return reversalHttpCode === 200 ? (
    <div style={{ color: "green" }}>✓</div>
  ) : (
    <div style={{ color: "red" }}>✗</div>
  );
}

const CardAuthLog = ({ cardAuthLog, loading }) => {
  const placeholder = () => (
    <Placeholder fluid>
      <Placeholder.Paragraph>
        <Placeholder.Line />
      </Placeholder.Paragraph>
    </Placeholder>
  );
  return (
    <Table striped selectable celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan={2} textAlign="left">
            {t("date")}
          </Table.HeaderCell>
          <Table.HeaderCell colSpan={2} textAlign="left">
            {t("amount")}
          </Table.HeaderCell>
          <Table.HeaderCell colSpan={2} textAlign="left">
            {t("auth_status")}
          </Table.HeaderCell>
          <Table.HeaderCell colSpan={2} textAlign="left">
            {t("auth_response_code")}
          </Table.HeaderCell>
          <Table.HeaderCell colSpan={2} textAlign="left">
            {t("reversed")}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {cardAuthLog.map(log => {
          return (
            <Table.Row key={log.id}>
              <Table.Cell colSpan={2}>
                {formatDateString(log.attemptedAt)}
              </Table.Cell>
              <Table.Cell colSpan={2}>{log.attemptAmount}</Table.Cell>
              <Table.Cell colSpan={2}>
                {log.authResponseCode !== null &&
                  (log.authResponseCode > 0 ? t("failure") : t("success"))}
              </Table.Cell>
              <Table.Cell colSpan={2}>
                {log.authResponseCode !== null &&
                  (log.authResponseCode === 0
                    ? t("not_applicable")
                    : t("success"))}
              </Table.Cell>
              <Table.Cell colSpan={2}>
                {reversalStatus(log.reversalHttpCode)}
              </Table.Cell>
            </Table.Row>
          );
        })}
        {loading &&
          [...Array(10)].map((elem, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Table.Row key={i}>
              <Table.Cell colSpan={2}>{placeholder()}</Table.Cell>
              <Table.Cell colSpan={2}>{placeholder()}</Table.Cell>
              <Table.Cell colSpan={2}>{placeholder()}</Table.Cell>
              <Table.Cell colSpan={2}>{placeholder()}</Table.Cell>
              <Table.Cell colSpan={2}>{placeholder()}</Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  );
};

CardAuthLog.propTypes = {
  cardAuthLog: PropTypes.arrayOf(
    PropTypes.shape({
      cardType: PropTypes.string,
      attemptAmount: PropTypes.number,
      authReversalCode: PropTypes.number,
      reversalResponseCode: PropTypes.number,
      attemptedAt: PropTypes.string,
    })
  ),
  loading: PropTypes.bool,
};

CardAuthLog.defaultProps = {
  cardAuthLog: [],
  loading: false,
};

function CardAuthLogWired({ id: paymentId }) {
  const { cardAuthLog, loading } = useCardAuthLog(paymentId);

  return (
    <CardAuthLog cardAuthLog={loading ? [] : cardAuthLog} loading={loading} />
  );
}

CardAuthLogWired.propTypes = {
  id: PropTypes.string,
};

CardAuthLogWired.defaultProps = {
  id: "",
};

export default CardAuthLogWired;
