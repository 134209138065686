import { isPossiblePhoneNumber } from "react-phone-number-input";
import * as yup from "yup";

import { ERROR_CODES, getErrorMessageFromCode } from "../../lib/error";

const isPhoneNumberHandler = (value = {}) => {
  const hasValue = !!(value && value.countryCode && value.number);
  return hasValue
    ? isPossiblePhoneNumber(`+${value.countryCode}${value.number}`)
    : true;
};

export const combinedPhoneNumberValidator = yup
  .object()
  .shape({
    countryCode: yup.string().nullable(),
    number: yup.string().nullable(),
    extension: yup.string().nullable(),
  })
  .nullable()
  .test(
    "isPhoneNumber",
    getErrorMessageFromCode(ERROR_CODES.INVALID_PHONE),
    isPhoneNumberHandler
  );

export const combinedPhoneNumberValidatorRequired = yup
  .object()
  .shape({
    countryCode: yup.string().required(),
    number: yup.string().required(),
    extension: yup.string().nullable(),
  })
  .required()
  .test(
    "isPhoneNumber",
    getErrorMessageFromCode(ERROR_CODES.INVALID_PHONE),
    isPhoneNumberHandler
  );
