import "tinymce/tinymce";
import "tinymce/plugins/link";
import "tinymce/plugins/paste";
import "tinymce/plugins/lists";
import "tinymce/icons/default";

import { Editor as TinyMCEEditor } from "@tinymce/tinymce-react";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { Button, Form, Input } from "semantic-ui-react";

import { sanitizeArticleHtml } from "../article-previewer/article-previewer.web";
import SubmitArticleModal from "../submit-article-modal/index.web";

export default function Editor({
  articleId,
  articleState,
  articleDispatch,
  handleSaveRevision,
  canSubmit,
  submitArticleForReview,
  scanArticle,
  submittingForReview,
  setSubmittingForReview,
  submitError,
}) {
  const [submitModalOpen, setSubmitModalOpen] = useState(false);

  const debouncedSaveRevision = useCallback(
    debounce(({ title, content }) => {
      handleSaveRevision(title, content);
    }, 3000),
    [handleSaveRevision]
  );

  async function handleSubmitArticleForReveiw() {
    setSubmittingForReview(true);
    await submitArticleForReview({
      id: articleId,
      title: articleState.title,
      content: articleState.content,
    });
    await scanArticle({ variables: { input: { articleId } } });
    setSubmittingForReview(false);
  }

  return (
    <>
      <Form className="ie-editor-container">
        <div className="ie-editor-top">
          <Form.Field className="ie-editor-title-input">
            <Input
              id="title"
              name="title"
              fluid
              placeholder="Enter a title for your article"
              value={articleState.title}
              onChange={({ target: { value } }) => {
                articleDispatch({
                  type: "updateTitle",
                  title: value,
                });
                debouncedSaveRevision({
                  ...articleState,
                  title: value,
                });
              }}
            />
          </Form.Field>

          <Button
            className="ie-button ie-editor-submit-button"
            disabled={!canSubmit || submittingForReview}
            loading={submittingForReview}
            onClick={() => setSubmitModalOpen(true)}
          >
            Submit
          </Button>
        </div>

        <TinyMCEEditor
          textareaName="content"
          value={articleState.content}
          init={{
            content_css: false,
            menubar: false,
            statusbar: false,
            plugins: "link paste lists",
            toolbar:
              "styleselect | bold italic underline | numlist bullist | link unlink",
            mobile: {
              toolbar_mode: "floating",
            },
            contextmenu: false,
            style_formats: [
              { title: "Paragraph", format: "p" },
              { title: "Headings" },
              { title: "Heading 1", block: "h1" },
              { title: "Heading 2", block: "h2" },
              { title: "Heading 3", block: "h3" },
              { title: "Heading 4", block: "h4" },
              { title: "Heading 5", block: "h5" },
              { title: "Heading 6", block: "h6" },
            ],
            link_title: false,
            default_link_target: "_blank",
            target_list: false,
            height: 500,
            paste_preprocess: (plugin, args) => {
              args.content = sanitizeArticleHtml(args.content);
            },
          }}
          onEditorChange={editorContent => {
            articleDispatch({
              type: "updateContent",
              content: editorContent,
            });
            debouncedSaveRevision({
              ...articleState,
              content: editorContent,
            });
          }}
        />
      </Form>

      <SubmitArticleModal
        open={submitModalOpen}
        onClose={() => setSubmitModalOpen(false)}
        onSubmit={handleSubmitArticleForReveiw}
        submitting={submittingForReview}
        error={submitError}
      />
    </>
  );
}

Editor.propTypes = {
  articleId: PropTypes.string,
  articleState: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
  }),
  articleDispatch: PropTypes.func,
  handleSaveRevision: PropTypes.func,
  canSubmit: PropTypes.bool,
  submitArticleForReview: PropTypes.func,
  scanArticle: PropTypes.func,
  submittingForReview: PropTypes.bool,
  setSubmittingForReview: PropTypes.func,
  submitError: PropTypes.shape({}),
};

Editor.defaultProps = {
  articleId: "",
  articleState: {
    title: "",
    content: "",
  },
  articleDispatch: () => {},
  handleSaveRevision: () => {},
  canSubmit: false,
  submitArticleForReview: () => {},
  scanArticle: () => {},
  submittingForReview: false,
  setSubmittingForReview: () => {},
  submitError: null,
};
