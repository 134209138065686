import { merge } from "lodash";

import AlertResolvers from "./alert";
import ArticleResolvers from "./article";
import AuthResolvers from "./auth";
import OnboardingResolvers from "./onboarding";
import PasswordResolvers from "./password";
import PaymentResolvers from "./payment";

export default merge(
  {},
  OnboardingResolvers,
  ArticleResolvers,
  AuthResolvers,
  AlertResolvers,
  PasswordResolvers,
  PaymentResolvers
);
