import React, { lazy } from "react";
import { Route } from "react-router-dom";

import { ONBOARDING } from "./routes-links";

const Onboarding = lazy(() =>
  import(/* webpackChunkName: "onboarding" */ "./index.web")
);

export default (
  <Route path={ONBOARDING}>
    <Onboarding />
  </Route>
);
