import PropTypes from "prop-types";
import React from "react";
import sanitizeHtml from "sanitize-html";
import { Grid, Header, Segment } from "semantic-ui-react";

import InputCounterLabel from "../../../components/input-counter-label/input-counter-label.web";

export function sanitizeArticleHtml(html) {
  return sanitizeHtml(html, {
    // prettier-ignore
    allowedTags: ["p", "a", "b", "i", "em", "strong",
    "h1", "h2", "h3", "h3", "h4", "h5", "h6",
    "ul", "ol", "nl", "li",
    "span",
    ],
    allowedAttributes: {
      a: ["href"],
      span: ["style"],
    },
    allowedStyles: {
      span: {
        "text-decoration": [/^underline$/],
      },
    },
  });
}

function ArticlePreviewer({ title, content }) {
  return (
    <Segment>
      <Header>{title}</Header>
      <div dangerouslySetInnerHTML={{ __html: sanitizeArticleHtml(content) }} />
      <Grid>
        <Grid.Column floated="right" textAlign="right" width={8}>
          <InputCounterLabel
            countWords
            isMaximum={false}
            string={content}
            min={500}
            max={1000}
          />
        </Grid.Column>
      </Grid>
    </Segment>
  );
}

export default ArticlePreviewer;

ArticlePreviewer.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
};

ArticlePreviewer.defaultProps = {
  title: "",
  content: "",
};
