import gql from "graphql-tag";
import { get } from "lodash";
import { useQuery } from "react-apollo";

const GET_CURRENT_USER = gql`
  query {
    me @client {
      id
      profile {
        avatar
      }
      email
    }
  }
`;

const useCurrentUser = () => {
  const { data } = useQuery(GET_CURRENT_USER);

  return {
    currentUser: {
      id: get(data, "me.id"),
      avatar: get(data, "me.profile.avatar"),
      email: get(data, "me.email"),
    },
  };
};

export default useCurrentUser;
