const cardTypes = {
  VISA: "Visa",
  MASTERCARD: "Master Card",
  AMERICAN_EXPRESS: "American Express",
  DISCOVER: "Discover",
  DINERS_CLUB: "Diners Club",
  JCB: "JCB",
};

export default cardTypes;
