import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, Grid, Icon, Popup } from "semantic-ui-react";

import PageHeader from "../page-header/index.web";

const TwoColumnLayout = ({
  title,
  sideColumn,
  fitted,
  children,
  collapsedSidebarLabel,
  collapsedSidebarIcon,
  collapsedSidebarIconStyles,
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  let styles = {};
  if (!fitted) {
    styles = { paddingTop: "1.5rem" };
  }
  const triggerProps = {
    floated: "right",
    icon: !!collapsedSidebarIcon,
  };
  if (collapsedSidebarLabel) {
    triggerProps.labelPosition = "left";
  }
  const TriggerButton = (
    <Button {...triggerProps}>
      {triggerProps.icon ? (
        <Icon
          style={collapsedSidebarIconStyles}
          className={collapsedSidebarIcon}
        />
      ) : null}
      {collapsedSidebarLabel}
    </Button>
  );

  const closeMobileSideColumn = () => setSidebarOpen(false);
  const openMobileSideColumn = () => setSidebarOpen(true);

  return (
    <div style={styles} className="two-column-layout">
      <PageHeader
        title={title}
        only={!title && "mobile"}
        compact={!title}
        headerRight={
          <Popup
            on="click"
            open={sidebarOpen}
            onClose={() => setSidebarOpen(false)}
            onOpen={() => setSidebarOpen(true)}
            trigger={TriggerButton}
            content={sideColumn({
              closeMobileSideColumn,
              openMobileSideColumn,
            })}
            position="bottom right"
          />
        }
        headerRightColumnProps={{
          only: "mobile",
        }}
      />

      <Grid>
        <Grid.Row>
          <Grid.Column only="tablet" tablet={5} computer={4}>
            {sideColumn({
              closeMobileSideColumn() {},
              openMobileSideColumn() {},
            })}
          </Grid.Column>
          <Grid.Column mobile={16} tablet={11} computer={12}>
            {children}
            <br />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default TwoColumnLayout;

TwoColumnLayout.propTypes = {
  children: PropTypes.node.isRequired,
  sideColumn: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  fitted: PropTypes.bool,
  title: PropTypes.string,
  collapsedSidebarLabel: PropTypes.string,
  collapsedSidebarIcon: PropTypes.string,
  collapsedSidebarIconStyles: PropTypes.shape({}),
};

TwoColumnLayout.defaultProps = {
  title: "",
  fitted: false,
  collapsedSidebarLabel: "",
  collapsedSidebarIcon: "bars",
  collapsedSidebarIconStyles: {},
};
