import MarkdownIt from "markdown-it";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Placeholder, Segment } from "semantic-ui-react";

const md = new MarkdownIt({
  linkify: true,
  html: true,
});

// eslint-disable-next-line react/prop-types
export const MarkdownRederer = ({ markdown, preWrap }) => (
  <Fragment>
    {preWrap ? (
      <Segment>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <span dangerouslySetInnerHTML={{ __html: md.render(markdown) }} />
        </p>
      </Segment>
    ) : (
      <span dangerouslySetInnerHTML={{ __html: md.render(markdown) }} />
    )}
  </Fragment>
);

const Markdown = ({ markdown, loading, numberOfSegments, preWrap }) => {
  return (
    <Fragment>
      {loading ? (
        [...Array(numberOfSegments).keys()].map(key => (
          <Placeholder key={key} fluid>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
          </Placeholder>
        ))
      ) : (
        <MarkdownRederer markdown={markdown} preWrap={preWrap} />
      )}
    </Fragment>
  );
};

Markdown.propTypes = {
  markdown: PropTypes.string,
  loading: PropTypes.bool,
  numberOfSegments: PropTypes.number,
  preWrap: PropTypes.bool,
};

Markdown.defaultProps = {
  markdown: "",
  loading: false,
  preWrap: false,
  numberOfSegments: 1,
};

export default Markdown;
