import PropTypes from "prop-types";
import React from "react";

import getAvatarUrl, { defaultAvatar } from "../../lib/avatar";
import ImageLoader from "../image-loader/index.web";

export const suiImageSizes = {
  xs: 24,
  mini: 35,
  tiny: 80,
  small: 150,
  medium: 300,
  large: 450,
};

function Avatar({
  src,
  size,
  imageSizeOverride,
  circular,
  shimmer,
  style,
  ...props
}) {
  const imageSize = suiImageSizes[size] ? suiImageSizes[size] : size;

  const circularStyles = {
    borderRadius: "100%",
    width: imageSize,
    height: imageSize,
    overflow: "hidden",
  };

  return (
    <div
      style={{
        ...(circular && circularStyles),
        ...style,
      }}
      {...props}
    >
      <ImageLoader
        height={imageSize}
        ratioHeight={1}
        ratioWidth={1}
        shimmer={shimmer}
        src={getAvatarUrl(src, (imageSizeOverride || imageSize) * 2)}
        width={imageSize}
        fallback={defaultAvatar}
      />
    </div>
  );
}

Avatar.propTypes = {
  src: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imageSizeOverride: PropTypes.number,
  circular: PropTypes.bool,
  shimmer: PropTypes.bool,
  style: PropTypes.shape({}),
};

Avatar.defaultProps = {
  src: "",
  size: "",
  imageSizeOverride: undefined,
  circular: true,
  shimmer: true,
  style: {},
};

export default Avatar;
