import "react-phone-number-input/style.css";
import "./style.css";

import propTypes from "prop-types";
import React, { Fragment } from "react";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import en from "react-phone-number-input/locale/en";
import { Form, Input } from "semantic-ui-react";

const PhoneNumberInput = ({
  className,
  name,
  label,
  placeholder,
  value,
  onChange,
  includeExt,
  error,
  ...phoneInputProps
}) => {
  const joinCountryCodeWithNumber = ({ countryCode = "", number = "" }) => {
    if (countryCode && number) {
      return `+${countryCode}${number}`;
    }
    return "";
  };

  const formatNumberForInput = ({
    countryCode = "",
    number = "",
    extension = "",
  } = {}) => {
    return {
      number: joinCountryCodeWithNumber({ countryCode, number }),
      extension: extension || "",
    };
  };

  const formatNumberForGql = ({ number = "", extension = "" }) => {
    const parsedPhone = parsePhoneNumber(number);
    return parsedPhone
      ? {
          countryCode: parsedPhone.countryCallingCode,
          number: parsedPhone.nationalNumber,
          extension,
        }
      : {
          countryCode: "",
          number,
          extension,
        };
  };

  const { number, extension } = formatNumberForInput(value || {});

  const handleOnChange = updatedPhoneNumberFromInput => {
    // format values for use-form's onChange call
    const onChangeValue = {
      value: formatNumberForGql(updatedPhoneNumberFromInput),
    };
    onChange(null, onChangeValue);
  };

  const renderExtensionInput = (
    <Input
      type="text"
      placeholder={en.ext}
      value={extension}
      onChange={e => handleOnChange({ number, extension: e.target.value })}
    />
  );

  return (
    <Fragment>
      <Form.Field className={className} error={error} label={label} />
      <PhoneInput
        {...phoneInputProps}
        country="US"
        name={name}
        placeholder={placeholder}
        ext={includeExt && renderExtensionInput}
        value={number}
        onChange={newValue => handleOnChange({ extension, number: newValue })}
        // Spreading the en locale into the labels so the ext label
        // can be removed without affecting anything else
        labels={{
          ...en,
          ext: "",
        }}
      />
    </Fragment>
  );
};

PhoneNumberInput.propTypes = {
  className: propTypes.string,
  name: propTypes.string,
  value: propTypes.shape({
    countryCode: propTypes.string,
    number: propTypes.string,
    extension: propTypes.string,
  }),
  onChange: propTypes.func,
  label: propTypes.oneOfType([propTypes.string, propTypes.node]),
  placeholder: propTypes.string,
  includeExt: propTypes.bool,
  error: propTypes.shape({
    content: propTypes.string,
    pointing: propTypes.string,
  }),
};

PhoneNumberInput.defaultProps = {
  className: "",
  name: "",
  value: {
    countryCode: "",
    number: "",
    extension: "",
  },
  onChange: () => {},
  label: "",
  placeholder: "",
  includeExt: false,
  error: null,
};

export default PhoneNumberInput;
