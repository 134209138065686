export function parseActivityId(id) {
  const match = id.match(
    /^([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}):(\w+)$/
  );

  if (match) {
    const [, objectId, type] = match;
    return { objectId, type };
  }

  return {};
}

export default parseActivityId;
