import PropTypes from "prop-types";
import React from "react";
import { Container } from "semantic-ui-react";

import prefixUrl from "../../public-path";
import Avatar from "../avatar/index.web";
import Link from "../link/index.web";

function Navbar({ appName, appIcon, user }) {
  return (
    <>
      <div className="app-navbar avert-container-masthead-rules">
        <Container
          className="app-navbar-container"
          style={{ justifyContent: "space-between" }}
        >
          <div style={{ display: "flex" }}>
            {appIcon && (
              <Link href={prefixUrl("/")}>
                <img
                  className="app-navbar-logo"
                  src={appIcon}
                  alt={appName}
                  data-ui-action="app-navbar-home"
                />
              </Link>
            )}

            <a
              href={prefixUrl("/")}
              style={{ alignSelf: "center", color: "inherit" }}
            >
              Back to {appName}
            </a>
          </div>

          <div className="app-navbar-secondary">
            <Avatar src={user.avatar} size={32} />
          </div>
        </Container>
      </div>
    </>
  );
}

export default Navbar;

Navbar.propTypes = {
  appName: PropTypes.string,
  appIcon: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.string,
    avatar: PropTypes.string,
    community: PropTypes.string,
  }),
};

Navbar.defaultProps = {
  appName: "",
  appIcon: "",
  user: {
    id: "",
    avatar: "",
    community: "",
  },
};
