import "react-credit-cards/es/styles-compiled.css";

import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, Modal } from "semantic-ui-react";

import ChargifyAddCardForm from "../../../../components/chargify-form";
import ErrorNotice from "../../../../components/error-notice/index.web";
import { namespacedT } from "../../../../lib/i18n";
import { useAddPaymentViaToken, usePaymentProcessor } from "../../graphql";
import cardTypes from "../../supported-card-types";

const t = namespacedT("settings.payment");

const AddCardModal = ({
  addPaymentViaToken,
  savingPayment,
  savingError,
  publicKey,
  serverHost,
  loading,
  closeModal,
  isAdmin,
}) => {
  const [callingChargify, setCallingChargify] = useState(false);

  const formConfig = {
    // These settings need to be defined in the config object being passed in
    // so that chargify can properly instantiate
    publicKey,
    serverHost,
  };

  return (
    <Modal open>
      <Modal.Header>Add Card</Modal.Header>
      <Modal.Content>
        {savingError && (
          <ErrorNotice
            message={isAdmin ? t("admin.error_add_card") : t("error_add_card")}
            contactEmail={t("need_help_email")}
          />
        )}
        {!loading && !savingError && (
          <ChargifyAddCardForm
            acceptedCards={Object.keys(cardTypes)}
            paymentType="card"
            formConfig={formConfig}
            submitForm={callingChargify}
            onTokenCreate={token => {
              if (!savingPayment) {
                addPaymentViaToken(token).then(closeModal);
              }
              setCallingChargify(false);
            }}
            onTokenError={() => {
              // It appears that the iframe handles errors on fields during validaiton and
              // that there isn't much we should do besides clear loading/saving state
              setCallingChargify(false);
            }}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={callingChargify || savingPayment}
          onClick={closeModal}
        >
          Cancel
        </Button>
        {!savingError && (
          <Button
            content="Add Card"
            labelPosition="right"
            icon="plus"
            disabled={callingChargify || savingPayment}
            loading={callingChargify || savingPayment}
            onClick={() => {
              setCallingChargify(true);
            }}
            positive
          />
        )}
        {savingError && <Button primary content="Done" onClick={closeModal} />}
      </Modal.Actions>
    </Modal>
  );
};

AddCardModal.propTypes = {
  loading: PropTypes.bool,
  addPaymentViaToken: PropTypes.func,
  savingPayment: PropTypes.bool,
  savingError: PropTypes.shape({ message: PropTypes.string }),
  publicKey: PropTypes.string,
  serverHost: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
};

AddCardModal.defaultProps = {
  publicKey: "",
  loading: false,
  serverHost: "",
  savingError: null,
  savingPayment: false,
  addPaymentViaToken: () => {},
  isAdmin: false,
};

function AddCardModalWired({ userId, closeModal, isAdmin }) {
  const {
    addPaymentViaToken,
    loading: savingPayment,
    error: savingError,
  } = useAddPaymentViaToken({ isAdmin, userId });

  const {
    processor: { publicKey, serverHost },
    loading,
    error: processorError,
  } = usePaymentProcessor({ isAdmin, userId });

  return (
    <AddCardModal
      loading={loading}
      publicKey={publicKey}
      serverHost={serverHost}
      closeModal={closeModal}
      savingPayment={savingPayment}
      addPaymentViaToken={addPaymentViaToken}
      savingError={savingError || processorError}
      isAdmin={isAdmin}
    />
  );
}

AddCardModalWired.propTypes = {
  userId: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
};

AddCardModalWired.defaultProps = {
  userId: "",
  isAdmin: false,
};

export default AddCardModalWired;
