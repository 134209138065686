import { get } from "lodash";
import { useQuery } from "react-apollo";

import GET_TEAM_SETTINGS_INFO from "./graphql";

const emptyPhoneNumber = () => ({
  countryCode: "",
  number: "",
  extension: "",
});

const emptySupportContact = () => ({
  name: "",
  email: "",
  mobilePhone: emptyPhoneNumber(),
  officePhone: emptyPhoneNumber(),
});

export default () => {
  const { loading, error, data } = useQuery(GET_TEAM_SETTINGS_INFO);
  const assistant = get(data, "me.assistant") || emptySupportContact();
  const publicRelations =
    get(data, "me.publicRelations") || emptySupportContact();

  return {
    loading,
    error,
    assistant,
    publicRelations,
  };
};
