import gql from "graphql-tag";
import PropTypes from "prop-types";
import React from "react";
import { useQuery } from "react-apollo";
import { NavLink } from "react-router-dom";

import { ARTICLES } from "../../../../containers/article/routes-links";
import { LOGIN } from "../../../../containers/auth/routes-links";
import { DIRECTORY_HOME } from "../../../../containers/directory/routes-links";
import { EXPERT_PANELS_HOME } from "../../../../containers/expert-panels/routes-links";
import { FORUM_HOME } from "../../../../containers/forum/route-links";
import { OFFERS_HOME } from "../../../../containers/offers/routes-links";
import { namespacedT } from "../../../../lib/i18n";

const t = namespacedT("app_navbar");

function MenuItem({ classNamePrefix, title, href, onClose }) {
  return (
    <li className={`${classNamePrefix}-item`}>
      <NavLink
        className={`${classNamePrefix}-link`}
        to={href}
        onClick={onClose}
        data-ui-action="primary-menu-link"
      >
        {title}
      </NavLink>
    </li>
  );
}

const COMMUNITY_CONFIG = gql`
  query Community {
    community @client {
      execLink
    }
  }
`;
function PrimaryMenuItems({ classNamePrefix, hasForum, onClose }) {
  const {
    data: { community },
  } = useQuery(COMMUNITY_CONFIG);

  function renderMenuItem(title, href) {
    return (
      <MenuItem
        classNamePrefix={classNamePrefix}
        title={title}
        href={href}
        onClose={onClose}
      />
    );
  }

  return (
    <>
      {hasForum && renderMenuItem(t("forum"), FORUM_HOME)}
      {renderMenuItem(t("members"), DIRECTORY_HOME)}
      {renderMenuItem(t("expert_panels"), EXPERT_PANELS_HOME)}
      {renderMenuItem(t("articles"), ARTICLES)}
      {renderMenuItem(community.execLink ? "EXEC" : t("offers"), OFFERS_HOME)}
    </>
  );
}

export const SignIn = ({ classNamePrefix, onClose }) => {
  return (
    <MenuItem
      classNamePrefix={classNamePrefix}
      title={t("sign_in")}
      href={LOGIN}
      onClose={onClose}
    />
  );
};

export default PrimaryMenuItems;

MenuItem.propTypes = {
  classNamePrefix: PropTypes.string,
  title: PropTypes.string,
  href: PropTypes.string,
  onClose: PropTypes.func,
};

MenuItem.defaultProps = {
  classNamePrefix: "",
  title: "",
  href: "",
  onClose: () => {},
};

PrimaryMenuItems.propTypes = {
  classNamePrefix: PropTypes.string,
  hasForum: PropTypes.bool,
  onClose: PropTypes.func,
};

PrimaryMenuItems.defaultProps = {
  classNamePrefix: "",
  hasForum: false,
  onClose: () => {},
};

SignIn.propTypes = {
  classNamePrefix: PropTypes.string,
  onClose: PropTypes.func,
};

SignIn.defaultProps = {
  classNamePrefix: "",
  onClose: () => {},
};
