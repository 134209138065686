import { get } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useHistory } from "react-router";
import {
  Button,
  Divider,
  Grid,
  Icon,
  List,
  Placeholder,
} from "semantic-ui-react";

import ErrorBoundary from "../../../../components/error-boundary/error-boundary.web";
import colors from "../../../../lib/colors";
import { namespacedT } from "../../../../lib/i18n";
import useQueryParams from "../../../../lib/use-query-params";
import NotificationSetting from "../../notification-setting/index.web";
import { linkTo } from "../../routes-links";
import SettingsSection from "../../settings-section/index.web";
import AppLinks from "./app-links.web";
import {
  useNotificationPreferences,
  useSetNotificationPreferences,
} from "./hooks";

const t = namespacedT("settings");

function filterPreferences(notificationPreferences, optionId) {
  const matchId = option => option.id === optionId;
  for (let i = 0; i < notificationPreferences.length; i++) {
    const group = notificationPreferences[i];
    const foundOption = group.options.find(matchId);
    if (foundOption) {
      return [{ ...group, options: [foundOption] }];
    }
  }
  return null;
}

const channels = [
  { prop: "forEmail", as: "email" },
  { prop: "forWeb", as: "web" },
  { prop: "forMobile", as: "mobile" },
];

function visibleGroups(preferences) {
  return preferences.reduce(
    (accum, group) => Object.assign(accum, visibleOptions(group)),
    {}
  );
}

function visibleOptions({ options }) {
  return channels.reduce((visible, { prop, as }) => {
    if (options.some(option => option[prop])) {
      visible[as] = true;
    }
    return visible;
  }, {});
}

function SectionHeaderRight({ mobileVisible, webVisible, emailVisible }) {
  return (
    <Grid columns={3}>
      {mobileVisible && (
        <Grid.Column floated="right" width={1}>
          <Icon
            className="mobile-phone"
            size="huge"
            fitted
            style={{ padding: "0 16px", color: colors.grayBase }}
          />
        </Grid.Column>
      )}
      {emailVisible && (
        <Grid.Column floated="right" width={1}>
          <Icon
            className="envelope-o"
            size="big"
            fitted
            style={{ padding: "0 16px", color: colors.grayBase }}
          />
        </Grid.Column>
      )}
      {webVisible && (
        <Grid.Column floated="right" width={1}>
          <Icon
            className="notification"
            size="big"
            fitted
            style={{ padding: "0 16px", color: colors.grayBase }}
          />
        </Grid.Column>
      )}
    </Grid>
  );
}

SectionHeaderRight.propTypes = {
  mobileVisible: PropTypes.bool,
  webVisible: PropTypes.bool,
  emailVisible: PropTypes.bool,
};

SectionHeaderRight.defaultProps = {
  mobileVisible: false,
  webVisible: false,
  emailVisible: false,
};

const NotificationSettingsLoading = () => (
  <SettingsSection title="Loading..." headerRight={<SectionHeaderRight />}>
    <Placeholder>
      <Placeholder.Header image>
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Header>
    </Placeholder>
  </SettingsSection>
);

function SubsectionNotifications() {
  const queryParams = useQueryParams();
  const [pageOption, setPageOption] = useState(queryParams.get("option"));
  const history = useHistory();

  const toggleOptions = ["mobile", "email"];
  const {
    loading,
    error,
    notificationPreferences: allPreferences = [],
  } = useNotificationPreferences();
  const { setNotificationPreferences } = useSetNotificationPreferences();

  if (error) {
    ErrorBoundary.setError({
      error,
      message: t("errors.bad_request"),
    });
  }

  if (loading) {
    return <NotificationSettingsLoading />;
  }

  let notificationPreferences = allPreferences;

  if (pageOption) {
    // show only selected option, if doesn't exist show full page
    const filtered = filterPreferences(allPreferences, pageOption);
    notificationPreferences = filtered || allPreferences;
  }

  const visibleHeadersRight = visibleGroups(notificationPreferences);

  const showManageButton =
    notificationPreferences !== allPreferences &&
    (allPreferences.length > 1 ||
      allPreferences.some(group => group.options.length > 1));

  return (
    <div style={{ paddingRight: "70px" }}>
      {notificationPreferences.map(preference => {
        const visibleSetting = visibleOptions(preference);
        return (
          <SettingsSection
            title={preference.group}
            headerRight={
              <SectionHeaderRight
                mobileVisible={visibleHeadersRight.mobile}
                webVisible={visibleHeadersRight.web}
                emailVisible={visibleHeadersRight.email}
              />
            }
          >
            <List divided relaxed style={{ marginop: "0" }}>
              {(preference.options || []).map(preferenceOption => {
                const optionsChecked = channels.reduce(
                  (accum, { prop, as }) => {
                    if (get(preferenceOption, [prop, "selected"])) {
                      accum[as] = true;
                    } else {
                      accum[as] = false;
                    }
                    return accum;
                  },
                  {}
                );
                return (
                  <NotificationSetting
                    title={preferenceOption.name}
                    description={preferenceOption.description}
                    name={preferenceOption.id}
                    options={toggleOptions}
                    checked={optionsChecked}
                    mobileVisibleColumn={visibleHeadersRight.mobile}
                    webVisibleColumn={visibleHeadersRight.web}
                    emailVisibleColumn={visibleHeadersRight.email}
                    mobileVisible={visibleSetting.mobile}
                    webVisible={visibleSetting.web}
                    emailVisible={visibleSetting.email}
                    onChange={(_, { name, checked }) => {
                      const [, headerType] = name.split("-");
                      const { prop } = channels.find(h => h.as === headerType);
                      const id = get(preferenceOption, [prop, "id"]);
                      return setNotificationPreferences(id, checked).catch(
                        graphError =>
                          ErrorBoundary.setError({
                            error: graphError,
                            message: t("errors.bad_request"),
                          })
                      );
                    }}
                  />
                );
              })}
            </List>
          </SettingsSection>
        );
      })}
      {showManageButton && (
        <Button
          onClick={() => {
            history.push(linkTo.notifications());
            setPageOption("");
          }}
        >
          {t("notification_settings.manage_settings")}
        </Button>
      )}
      <Divider
        style={{
          marginBottom: "8px",
          borderColor: colors.silverDark,
          borderBottom: "none",
          width: "110%",
        }}
      />
      <AppLinks />
    </div>
  );
}

export default SubsectionNotifications;
