import "./style.css";

import React, { useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { Segment } from "semantic-ui-react";

import ScrollToTop from "../../../components/scroll-to-top/index.web";
import TitleWrapper from "../../../components/title-wrapper/index.web";
import TwoColumnLayout from "../../../components/two-column-layout/two-column-layout.web";
import { namespacedT } from "../../../lib/i18n";
import {
  SETTINGS_BILLING,
  SETTINGS_HOME,
  SETTINGS_NOTIFICATIONS,
  SETTINGS_TEAM,
} from "../routes-links";
import SubsectionBilling from "../subsections/billing/index.web";
import SubsectionNotifications from "../subsections/notifications/index.web";
import SubsectionSecurity from "../subsections/security/index.web";
import SubsectionTeam from "../subsections/team/index.web";
import SettingsMenu, { getActiveTabName } from "./settings-menu.web";

const tSettings = namespacedT("settings");

export default function Layout() {
  const location = useLocation();
  const [title, setPageTitle] = useState(tSettings("page_title"));

  return (
    <TitleWrapper title={title}>
      <div className="settings-container">
        <ScrollToTop />
        <div className="settings-header">{title}</div>
        <TwoColumnLayout
          sideColumn={() => <SettingsMenu />}
          fitted
          collapsedSidebarLabel={getActiveTabName(location)}
          collapsedSidebarIcon="ellipsis-v"
          collapsedSidebarIconStyles={{ padding: "11px 0" }}
        >
          <div className="settings-content">
            <Segment style={{ padding: "20px" }}>
              <Switch location={location}>
                <Route
                  exact
                  path={SETTINGS_HOME}
                  component={SubsectionSecurity}
                />
                <Route path={SETTINGS_TEAM} render={() => <SubsectionTeam />} />
                <Route
                  path={SETTINGS_NOTIFICATIONS}
                  render={() => {
                    setPageTitle(tSettings("notification_settings.page_title"));
                    return <SubsectionNotifications />;
                  }}
                />
                <Route path={SETTINGS_BILLING} component={SubsectionBilling} />
              </Switch>
            </Segment>
          </div>
        </TwoColumnLayout>
      </div>
    </TitleWrapper>
  );
}
