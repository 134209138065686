import prefixUrl from "../../../public-path";
import fetch, { handleMsgFromServer } from "../fetch-manager";

export default {
  Mutation: {
    async login(root, { username, password, targetCommunity }, { cache }) {
      const uri = prefixUrl(
        targetCommunity ? "/rest/admin/login" : "/rest/login"
      );

      const res = await fetch(uri, {
        method: "POST",
        body: JSON.stringify({ username, password, targetCommunity }),
        headers: { "Content-Type": "application/json" },
      });
      const { displayName } = await handleMsgFromServer(res);

      const me = {
        __typename: "Me",
        id: "temp_id", // userId is not returned from the login response
        name: {
          __typename: "UserName",
          formatted: displayName,
        },
      };

      cache.writeData({ data: { me } });
      return true;
    },

    async logout(root, vars, { cache }) {
      const uri = prefixUrl("/rest/logout");

      const res = await fetch(uri, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });
      await handleMsgFromServer(res);

      cache.writeData({
        data: {
          me: {
            id: "",
            name: {
              formatted: "",
              __typename: "",
            },
            profile: {
              avatar: "",
              publicUrl: "",
              company: {
                name: "",
                __typename: "",
              },
              location: {
                name: "",
                familiarName: "",
                __typename: "UserProfileLocation",
              },
              __typename: "UserProfile",
            },
            email: "",
            __typename: "Me",
            roles: [],
            communityCode: "",
          },
        },
      });
      return true;
    },

    async tokenRefresh() {
      const uri = prefixUrl("/rest/token-refresh");

      const res = await fetch(uri, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });
      await handleMsgFromServer(res);

      return true;
    },

    async needsAuthentication(root, { needsAuthentication }, { cache }) {
      return cache.writeData({
        data: { app: { needsAuthentication, __typename: "App" } },
      });
    },

    async oneTimeCodeGenerate(root, { input: { channel, to } }) {
      const uri = prefixUrl("/rest/login/one-time");

      const res = await fetch(uri, {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify({
          channel,
          to,
        }),
      });

      if (!res.ok) {
        const err = new Error(`FetchError: HTTP ${res.status}`);
        const errText = await res.text();
        err.response = res;
        err.response.data = JSON.parse(errText);
        throw err;
      }
      return res;
    },

    async oneTimeCodeVerify(root, { input: { to, code } }) {
      const uri = prefixUrl("/rest/login/one-time-redeem");

      const res = await fetch(uri, {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify({
          to,
          code,
        }),
      });
      const data = await handleMsgFromServer(res);

      return {
        user: data.user,
        community: data.community,
        authToken: data.authToken,
        refreshToken: data.refreshToken,
        roles: data.roles,
        __typename: "OneTimeCodeVerified",
      };
    },
  },

  Query: {
    async availableCommunities() {
      const uri = prefixUrl("/rest/admin/available-communities");

      const res = await fetch(uri, {
        headers: { "Content-Type": "application/json" },
      });
      const { availableCommunities } = await handleMsgFromServer(res);

      availableCommunities.forEach(community => {
        community.__typename = "Community";
      });
      return availableCommunities;
    },

    async authStrategies(root, { input: { channel, to } }) {
      const uri = prefixUrl("/rest/login/auth-strategies");

      const res = await fetch(uri, {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify({
          channel,
          to,
        }),
      });
      const data = await handleMsgFromServer(res);
      return {
        passwordStrategy: data.passwordStrategy,
        otcSmsStrategy: data.otcSmsStrategy,
        __typename: "AuthStrategyResponse",
        smsOptions: data.smsOptions.map(smsOption => ({
          ...smsOption,
          __typename: "ChannelChoiceOption",
        })),
      };
    },
  },
};
