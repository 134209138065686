import PropTypes from "prop-types";
import React from "react";
import { Button, Modal } from "semantic-ui-react";

import { ASSISTANT_TYPE } from "../../../components/team-member-toggle/index.web";
import { namespacedT } from "../../../lib/i18n";
import useForm from "../../../lib/use-form";
import { useMyTeamMemberContact } from "./hooks";
import TeamMemberForm, {
  defaultInitialValues,
  initialValueProps,
} from "./member-form.web";
import schema from "./schema";

const tContact = namespacedT("onboard.contact");

export const AddTeamMember = ({
  initialValues,
  type,
  open,
  onSave,
  onCancel,
}) => {
  let formTitle;
  switch (type) {
    case ASSISTANT_TYPE.PR:
      formTitle = tContact("pr_marketing_support");
      break;
    default:
      formTitle = tContact("executive_assistant");
  }

  const { saving, onSave: doSave } = useMyTeamMemberContact(type);
  const { onSubmit, decorateInput } = useForm(
    initialValues,
    schema,
    values => doSave(values).then(() => onSave(values)),
    saving,
    "form.onboarding-team"
  );

  return (
    <Modal open={open} onClose={onCancel}>
      <Modal.Header>{formTitle}</Modal.Header>
      <Modal.Content>
        <TeamMemberForm
          initialValues={initialValues}
          decorateInput={decorateInput}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={onCancel}>
          Cancel
        </Button>
        <Button
          data-ui-action="add-team-member-submit"
          primary
          onClick={onSubmit}
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AddTeamMember;

AddTeamMember.propTypes = {
  initialValues: initialValueProps,
  type: PropTypes.string,
  open: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

AddTeamMember.defaultProps = {
  initialValues: defaultInitialValues,
  type: "",
  open: false,
  onSave: () => {},
  onCancel: () => {},
};
