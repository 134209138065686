import { get } from "lodash";
import { useQuery } from "react-apollo";

import GET_ACCOUNT_SETTINGS_INFO from "./graphql";

export default () => {
  const { loading, error, data } = useQuery(GET_ACCOUNT_SETTINGS_INFO);
  const username = get(data, "username") || "";
  const mobilePhone = get(data, "me.mobilePhone") || {};

  return {
    loading,
    error,
    username,
    mobilePhone,
  };
};
