import gql from "graphql-tag";
import { get } from "lodash";
import React from "react";
import { Query } from "react-apollo";

import { linkTo } from "../../containers/profile/routes-links";

export const USER_ADMIN_FRAGMENT = gql`
  fragment User_Admin_Fragment on User {
    id
    engagement {
      lastAccessed {
        anyDevice
      }
    }
    snapshot {
      id
      name
      health
      joinedAt
      communityName
      companyName
      avatar
      jobTitle
      isActive
      location
      standing
      isFounding
      vertical
      pronouns
      displayPronouns
    }
    community {
      code
    }
    membershipTier
  }
`;

const MEMBER_CARD_ADMIN = gql`
  query member($id: ID!) {
    user(id: $id) {
      ...User_Admin_Fragment
    }
  }
  ${USER_ADMIN_FRAGMENT}
`;

export const USER_SNAPSHOT_FRAGMENT = gql`
  fragment UserSnapshot_Fragment on UserSnapshot {
    id
    name
    isModerator
    companyName
    avatar
    jobTitle
    location
    isActive
    pronouns
    displayPronouns
  }
`;

const MEMBER_CARD_MEMBER = gql`
  query member($id: ID!) {
    user(id: $id) {
      id
      snapshot {
        ...UserSnapshot_Fragment
      }
    }
  }
  ${USER_SNAPSHOT_FRAGMENT}
`;

const QueryMemberCard = Component => ownProps => (
  <Query
    query={ownProps.isAdmin ? MEMBER_CARD_ADMIN : MEMBER_CARD_MEMBER}
    variables={{ id: ownProps.userId }}
  >
    {({ loading, error, data = {} }) => {
      const props = {
        loading,
        error: error && error.message,
        isActive: get(data, "user.snapshot.isActive"),
        joinedAt: get(data, "user.snapshot.joinedAt"),
        communityName: get(data, "user.snapshot.communityName"),
        standing: get(data, "user.snapshot.standing"),
        location: get(data, "user.snapshot.location"),
        name: get(data, "user.snapshot.name"),
        health: get(data, "user.snapshot.health"),
        avatar: get(data, "user.snapshot.avatar"),
        jobTitle: get(data, "user.snapshot.jobTitle"),
        companyName: get(data, "user.snapshot.companyName"),
        // TODO: Once access logs are in place update this property to be more accurate
        lastActivity: get(data, "user.engagement.lastAccessed.anyDevice"),
        profileUrl: linkTo.member(ownProps.userId),
        isFounding: get(data, "user.snapshot.isFounding"),
        vertical: get(data, "user.snapshot.vertical"),
        communityCode: get(data, "user.community.code"),
        membershipTier: get(data, "user.membershipTier"),
        pronouns: get(data, "user.snapshot.pronouns"),
        displayPronouns: get(data, "user.snapshot.displayPronouns"),
        ...ownProps,
      };
      return <Component {...props} />;
    }}
  </Query>
);

export default QueryMemberCard;
