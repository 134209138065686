import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { logPageSegment } from "../../../lib/analytics/segment";
import trackChargifyJsError from "../../../lib/payment";
import {
  useGetUserData,
  usePaySubscriptionUnauthed,
  useQueryLoginConfig,
} from "../graphql";
import DunningMemberForm from "./form/index.web";

function DunningMemberFormWired() {
  const query = new URLSearchParams(useLocation().search);
  const userId = query.get("userId");
  const subscriptionId = query.get("subscriptionId");

  const {
    addPaymentViaToken,
    loading: savingPayment,
    error: savingError,
  } = usePaySubscriptionUnauthed(userId);

  const {
    fullName,
    outstandingBalance,
    renewalDate,
    showNoBalanceDue,
    publicKey,
    serverHost,
    loading: loadingUserData,
    error: userDataError,
  } = useGetUserData({ userId, subscriptionId });

  const {
    backgroundImage,
    logo,
    name,
    conciergeEmail,
    conciergePhone,
  } = useQueryLoginConfig();

  const trackFailedChargifyJS = error => {
    trackChargifyJsError({
      userId,
      error,
      category: "Payment",
      value: outstandingBalance,
    });
  };

  useEffect(() => {
    logPageSegment();
  }, []);

  return (
    <DunningMemberForm
      layoutConfig={{ backgroundImage, logo }}
      loading={loadingUserData}
      publicKey={publicKey}
      serverHost={serverHost}
      savingPayment={savingPayment}
      addPaymentViaToken={addPaymentViaToken}
      hasError={userDataError}
      paymentError={savingError}
      userName={fullName}
      currentBalance={outstandingBalance}
      showNoBalanceDue={showNoBalanceDue}
      renewalDate={renewalDate}
      loadingUserData={loadingUserData}
      brandName={name}
      conciergeEmail={conciergeEmail}
      conciergePhone={conciergePhone}
      trackFailedChargifyJS={trackFailedChargifyJS}
    />
  );
}

export default DunningMemberFormWired;
