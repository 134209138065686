import amex from "payment-icons/min/flat/amex.svg";
import diners from "payment-icons/min/flat/diners.svg";
import discover from "payment-icons/min/flat/discover.svg";
import jcb from "payment-icons/min/flat/jcb.svg";
import mastercard from "payment-icons/min/flat/mastercard.svg";
import visa from "payment-icons/min/flat/visa.svg";
import PropTypes from "prop-types";
import React from "react";
import { Image } from "semantic-ui-react";

import colorsTheme from "../../lib/colors";

const cardImages = {
  AMERICAN_EXPRESS: amex,
  DINERS_CLUB: diners,
  DISCOVER: discover,
  JCB: jcb,
  MASTERCARD: mastercard,
  VISA: visa,
};

function AcceptedCards({ cards, limit }) {
  if (!cards.length) return null;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
        textAlign: "right",
      }}
    >
      <Image.Group size="mini">
        {cards.map((card, index) => {
          if (limit && index >= limit) return null;
          return <Image src={cardImages[card]} key={card} />;
        })}
      </Image.Group>
      {limit && (
        <span style={{ color: colorsTheme.grayMedium }}>and more...</span>
      )}
    </div>
  );
}

AcceptedCards.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.oneOf([
      "VISA",
      "MASTERCARD",
      "AMERICAN_EXPRESS",
      "DISCOVER",
      "DINERS_CLUB",
      "JCB",
    ])
  ),
};

AcceptedCards.propTypes = {
  limit: PropTypes.number,
};

AcceptedCards.defaultProps = {
  cards: [],
  limit: undefined,
};

export default AcceptedCards;
