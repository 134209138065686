import "./styles.css";

import { t } from "localizify";
import propTypes from "prop-types";
import React, { useLayoutEffect } from "react";
import { Grid, Header, Responsive, Segment } from "semantic-ui-react";

import Alerts from "../../../components/alerts/index.web";
import Link from "../../../components/link/index.web";
import setTitle from "../../../lib/webpage";
// import AppBadges from "./app-badges.web";
import defaultBackground from "./img/bg.jpg";

export default function Layout({
  children,
  logo,
  slug,
  backgroundImage,
  textColor,
  // playStoreLink,
  // appStoreLink,
  termsLink,
  privacyLink,
  copyright,
}) {
  useLayoutEffect(() => {
    setTitle(t("auth.login.page_title"));
  }, []);

  const Welcome = () => (
    <React.Fragment>
      <img
        alt={t("app.name")}
        style={{ maxWidth: "200px", maxHeight: "50px" }}
        src={logo}
      />
      <Header as="h2" style={{ color: textColor, fontWeight: 400 }}>
        {slug}
      </Header>
    </React.Fragment>
  );

  const Copyright = () => (
    <div>
      <p style={{ color: "white", textShadow: "1px 1px 3px black" }}>
        © {new Date().getFullYear()} {copyright}
        <br />
        <Link inherit href={termsLink}>
          Terms and Conditions
        </Link>
        {" | "}
        <Link inherit href={privacyLink}>
          Privacy Policy
        </Link>
      </p>
    </div>
  );

  // Badges are being temporarily removed from non-authenticated pages
  // until everyone is converted to the new convoy styled mobile app
  // to prevent confusion as to which app to use. Remove div and comment
  // when all brands are cutover.
  const Badges = () => (
    <>
      <div style={{ paddingBottom: 75, paddingTop: 25 / 2 }} />
      {/* <AppBadges playStoreLink={playStoreLink} appStoreLink={appStoreLink} /> */}
    </>
  );

  return (
    <>
      <div className="login-layout">
        <div
          className="login-layout-background"
          style={{
            backgroundImage: `url(${backgroundImage || defaultBackground})`,
          }}
        />

        <Grid stackable columns={2}>
          <Grid.Row>
            <Grid.Column tablet={6} computer={8}>
              <Responsive
                {...Responsive.onlyMobile}
                as="div"
                style={{ textAlign: "center" }}
              >
                <Welcome />
              </Responsive>
              <Responsive
                minWidth={Responsive.onlyTablet.minWidth}
                as="div"
                style={{ maxWidth: 400 }}
              >
                <Welcome />
                <Badges />
                <Copyright />
              </Responsive>
            </Grid.Column>
            <Grid.Column tablet={10} computer={8}>
              <Segment className="login-area" raised textAlign="center">
                <div style={{ width: "100%" }}>{children}</div>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16} only="mobile">
              <Responsive
                {...Responsive.onlyMobile}
                as="div"
                style={{ textAlign: "center" }}
              >
                <Badges />
                <Copyright />
              </Responsive>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <Alerts float />
    </>
  );
}

Layout.propTypes = {
  backgroundImage: propTypes.string,
  children: propTypes.node.isRequired,
  slug: propTypes.string.isRequired,
  appStoreLink: propTypes.string,
  playStoreLink: propTypes.string,
  termsLink: propTypes.string.isRequired,
  privacyLink: propTypes.string.isRequired,
  copyright: propTypes.string.isRequired,
  logo: propTypes.string.isRequired,
  textColor: propTypes.string,
};

Layout.defaultProps = {
  backgroundImage: "",
  appStoreLink: "",
  playStoreLink: "",
  textColor: "white",
};
