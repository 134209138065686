import { t } from "localizify";
import { useMutation } from "react-apollo";

import { LOGOUT } from "./graphql";

const useLogout = () => {
  const [mutate, { error, loading }] = useMutation(LOGOUT);

  // Log the user out anyway on error
  if (error) {
    document.cookie = "AuthToken=";
    console.error(
      t("auth.logout.error_message", {
        error_message: error.message || t("app.error_unknown"),
      })
    );
  }

  const logout = async () => {
    await mutate();
  };

  return {
    loading,
    error,
    logout,
  };
};

export default useLogout;
