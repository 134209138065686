import { ADMIN } from "../../containers/admin/routes-links";
import { ONBOARDING } from "../../containers/onboarding/routes-links";

// eslint-disable-next-line import/prefer-default-export
export const isAdminUrl = path => {
  return path.includes(ADMIN);
};

export const isOnboardingUrl = path => {
  return path.includes(ONBOARDING);
};
