import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
import { HttpLink } from "apollo-link-http";
import { defaultsDeep, each, get } from "lodash";

import parseActivityId from "../lib/activity";
import prefixUrl from "../public-path";
import { typeDefs } from "./client-schema";
import defaultState from "./default-state";
import introspectionQueryResultData from "./fragmentTypes.json";
import resolvers from "./resolvers";
import fetch from "./resolvers/fetch-manager";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

export function writeDefaults(...defaults) {
  return defaultsDeep(...defaults, defaultState);
}

const cache = new InMemoryCache({
  fragmentMatcher,
  cacheRedirects: {
    Query: {
      user: (_, args, { getCacheKey }) => {
        const id = get(args, "id");
        return getCacheKey({ __typename: "Me", id });
      },
      activity: (_, args, { getCacheKey }) => {
        const id = get(args, "input.targetId");
        // Client is supposed to be agnostic about ids, but we need to know what this derived type is cached as.
        const { type: derivedType } = parseActivityId(id);
        // TODO - handle interaction types.
        switch (derivedType) {
          case "ForumPost":
          case "Poll":
            return getCacheKey({ __typename: `Activity${derivedType}`, id });
          default:
            return undefined;
        }
      },
    },
  },
});

export const config = ({ uri, headers, credentials = "include" }) => {
  return {
    connectToDevTools: process.env.NODE_ENV !== "production",

    link: ApolloLink.from([
      onError(({ graphQLErrors }) => {
        each(graphQLErrors, err => {
          // Throw error if our GraphQL schema is invalid
          if (err.extensions.code === "GRAPHQL_VALIDATION_FAILED") {
            throw err;
          }
          if (get(err, "extensions.exception.statusCode") === 401) {
            cache.writeData({
              user: { __typename: "Me", isLoggedIn: false },
            });
          }
        });
      }),

      new HttpLink({
        uri,
        credentials,
        headers,
        fetch,
      }),
    ]),
    cache,
    resolvers,
    typeDefs,
  };
};

cache.writeData({ data: writeDefaults(window.__APOLLO_STATE__) });

export const memberClient = new ApolloClient({
  ...config({ uri: prefixUrl("/graphql") }),
});

export const adminClient = new ApolloClient({
  ...config({ uri: prefixUrl("/graphqlAdmin") }),
});
