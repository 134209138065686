import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

export default function ImageLoader({
  src,
  ratioWidth,
  ratioHeight,
  shimmer,
  height,
  width,
  fallback,
}) {
  const [showShimmer, setShowShimmer] = useState(true);
  const [imageSrc, setImageSrc] = useState(src);

  useEffect(() => {
    setImageSrc(src);
  }, [src]);

  let wrapperStyle = {};

  let element;

  if (height) {
    wrapperStyle = {
      height,
      width,
    };
    element = (
      <img
        className="image"
        style={{
          height,
          width,
          visibility: showShimmer ? "hidden" : "visible",
        }}
        src={imageSrc}
        onError={() => {
          setShowShimmer(false);
          if (fallback) setImageSrc(fallback);
        }}
        onLoad={() => setShowShimmer(false)}
        alt="avatar"
      />
    );
  } else {
    const ratio = (ratioHeight / ratioWidth) * 100;

    element = (
      <div
        className="image"
        style={{
          paddingTop: `${ratio}%`,
          background: src
            ? `url(${src}) center center / cover no-repeat`
            : null,
        }}
      />
    );
  }

  const classNames = `ui${shimmer ? " placeholder" : ""}`;

  return (
    <div className={showShimmer ? classNames : null} style={wrapperStyle}>
      {element}
    </div>
  );
}

ImageLoader.propTypes = {
  src: PropTypes.string,
  ratioWidth: PropTypes.number,
  ratioHeight: PropTypes.number,
  shimmer: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  fallback: PropTypes.string,
};

ImageLoader.defaultProps = {
  src: "",
  ratioWidth: 1,
  ratioHeight: 1,
  shimmer: true,
  height: 0,
  width: 0,
  fallback: "",
};
