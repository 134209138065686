import isFunction from "lodash/isFunction";
import PropTypes from "prop-types";
import { cloneElement, useCallback, useEffect } from "react";

import { logPageSegment } from "../../lib/analytics/segment";

export default function SegmentPage(props) {
  const { view, logOnEvent, shouldLogOnViewChange, children } = props;
  const { [logOnEvent]: fn } = children.props;

  if (process.env.NODE_ENV !== "production") {
    if (children.length > 1)
      throw new Error(
        `There are ${children.length} of Child Components defined. You can only have one child of a SegmentPage`
      );
  }

  useEffect(() => {
    if (shouldLogOnViewChange && view) {
      logPageSegment(view);
    }
  }, [shouldLogOnViewChange, view]);

  const trackingOnClick = useCallback(
    (...args) => {
      logPageSegment(view);

      if (!isFunction(fn)) return () => {};
      return fn(...args);
    },
    [fn, view]
  );

  const childProps = {
    ...children.props,
  };
  if (!shouldLogOnViewChange) {
    childProps[logOnEvent] = trackingOnClick;
  }
  return cloneElement(children, childProps);
}

SegmentPage.propTypes = {
  view: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  shouldLogOnViewChange: PropTypes.bool,
  logOnEvent: PropTypes.string,
};

SegmentPage.defaultProps = {
  logOnEvent: "onMount",
};
