import { ALL_VALUE } from "../containers/admin/constants";

export function getSearchParam(key, defaultValue = "") {
  if (!document || !document.location || !document.location.href) {
    return defaultValue;
  }
  const url = new URL(document.location.href);
  return url.searchParams.get(key) || defaultValue;
}

export function setSearchParam(history, key, val) {
  const params = new URLSearchParams(history.location.search);
  if (val === "" || val === undefined || val === null || val === ALL_VALUE) {
    params.delete(key);
  } else {
    params.set(key, val);
  }

  history.replace(`${history.location.pathname}?${params.toString()}`);
}
