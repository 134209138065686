import { first } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { Container, Responsive } from "semantic-ui-react";

import { FORUM_HOME } from "../../containers/forum/route-links";
import useIdentity from "../../lib/use-identity";
import AdContainer, { adContainerProps } from "../ad-container/index.web";
import Navbar from "./navbar/index.web";

const fullWidthMobileRoutes = [FORUM_HOME];

export default function Layout({
  AlertsComponent,
  children,
  loading,
  location,
  ads,
  ...navBarProps
}) {
  const { isLoggedIn } = useIdentity();

  const mastheadAd = first(ads);

  if (loading) {
    return null;
  }

  const { pathname } = location;
  const layoutWidthClass = fullWidthMobileRoutes.includes(pathname)
    ? "mobile-full-width"
    : "";
  return (
    <>
      {mastheadAd && isLoggedIn && (
        <Responsive
          minWidth={Responsive.onlyTablet.minWidth}
          as={AdContainer}
          {...mastheadAd}
        />
      )}
      <Navbar isLoggedIn={isLoggedIn} {...navBarProps} />
      {AlertsComponent && <AlertsComponent />}
      <Container className={`app-layout ${layoutWidthClass}`}>
        {children}
      </Container>
    </>
  );
}

Layout.propTypes = {
  AlertsComponent: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  ads: PropTypes.arrayOf(PropTypes.shape(adContainerProps)),
};

Layout.defaultProps = {
  loading: false,
  location: {
    pathname: null,
  },
  ads: [],
};
