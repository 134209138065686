import gql from "graphql-tag";
import { get } from "lodash";
import { useQuery } from "react-apollo";

export const GET_HAS_UNVIEWED = gql`
  query notifications {
    notifications {
      pageInfo {
        hasUnviewed
      }
    }
  }
`;

export function useHasUnviewed() {
  const { data } = useQuery(GET_HAS_UNVIEWED, {
    pollInterval: 30000,
  });

  return {
    hasUnviewed: get(data, "notifications.pageInfo.hasUnviewed"),
  };
}
