import "./style.css";

import { t } from "localizify";
import PropTypes from "prop-types";
import React from "react";
import { Icon, List, Popup } from "semantic-ui-react";

import { LANGUAGE_DEFAULTS } from "../../lib/i18n";

export default function ContactUsButton({ contactPhone, contactEmail }) {
  const hasPhone = contactPhone && contactPhone !== LANGUAGE_DEFAULTS.PHONE;
  const hasEmail = contactEmail && contactEmail !== LANGUAGE_DEFAULTS.EMAIL;

  return (
    <Popup
      flowing
      position="top center"
      trigger={
        <div className="contact-us-button">
          <Icon className="comments contact-us-button-icon" />

          <p className="contact-us-button-label">{t("contact_us.label")}</p>
        </div>
      }
      content={
        <List className="contact-list">
          {hasPhone && (
            <List.Item className="contact-list-item">
              <List.Icon
                className="mobile-phone contact-list-item-icon"
                style={{ fontSize: "24px" }}
              />
              <List.Content className="contact-list-item-content">
                {t("contact_us.text_us")} <span>{contactPhone}</span>
              </List.Content>
            </List.Item>
          )}

          {hasEmail && (
            <List.Item className="contact-list-item">
              <List.Icon
                className="envelope-o contact-list-item-icon"
                style={{ fontSize: "16px" }}
              />
              <List.Content className="contact-list-item-content">
                {t("contact_us.email_us")}{" "}
                <a href={`mailTo:${contactEmail}`}>{contactEmail}</a>
              </List.Content>
            </List.Item>
          )}
        </List>
      }
      on="click"
    />
  );
}

ContactUsButton.propTypes = {
  contactPhone: PropTypes.string,
  contactEmail: PropTypes.string,
};

ContactUsButton.defaultProps = {
  contactPhone: "",
  contactEmail: "",
};
