import { t } from "localizify";
import PropTypes from "prop-types";
import React from "react";
import { Button, Header } from "semantic-ui-react";

import { logEvent } from "../../../lib/analytics";

export default function OtcLockout({ dispatchLoginState, channelTo }) {
  return (
    <>
      <Header size="large">{t("auth.login.otc_lockout.heading")}</Header>

      <p className="login-area-intro">{t("auth.login.otc_lockout.intro")}</p>

      <div style={{ maxWidth: 252, margin: "0 auto" }}>
        <Button
          type="button"
          onClick={() => {
            logEvent(
              "MemberLogin",
              "ButtonClick",
              "Account Locked Go to Sign In"
            );
            dispatchLoginState({ type: "LOGIN_REQUESTED", channelTo });
          }}
          secondary
          fluid
          className="login-area-cta"
        >
          {t("auth.login.reset_confirmation.cta")}
        </Button>
      </div>
    </>
  );
}

OtcLockout.propTypes = {
  dispatchLoginState: PropTypes.func.isRequired,
  channelTo: PropTypes.string.isRequired,
};
