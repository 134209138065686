const tests = {
  case: /(?=.*[a-z])(?=.*[A-Z])/,
  length: /^.{8,128}$/,
  numbers_or_symbols: [/[0-9]/, /[^A-Za-z0-9]/],
};

export default function testPassword(password) {
  const results = {
    failCount: 0,
    passCount: 0,
    testCount: 0,
    tests: {},
  };

  for (const test in tests) {
    const result = Array.isArray(tests[test])
      ? tests[test].some(regex => regex.test(password))
      : tests[test].test(password);

    results.tests[test] = result;
    result ? results.passCount++ : results.failCount++;
    results.testCount++;
  }

  results.acceptable =
    results.tests.length &&
    results.tests.case &&
    results.tests.numbers_or_symbols;
  results.percentPassing = (results.passCount / results.testCount) * 100;
  results.percentFailing = (results.failCount / results.testCount) * 100;

  return results;
}
