import { utils } from "@communityco/errors";
import { get, isPlainObject } from "lodash";
import React from "react";
import { ApolloConsumer, Mutation, Query } from "react-apollo";
import { matchPath } from "react-router-dom";

import { ADMIN } from "../containers/admin/routes-links";

function buildTransaction(type) {
  const Transaction = type === "query" ? Query : Mutation;
  return (mapOriginalPropsToTransactionProps = {}, mapResultToProps) => {
    return Component => originalProps => {
      const transactionProps = isPlainObject(mapOriginalPropsToTransactionProps)
        ? mapOriginalPropsToTransactionProps
        : mapOriginalPropsToTransactionProps(originalProps);
      return (
        <ApolloConsumer>
          {client => {
            const path = get(originalProps, "location.pathname");
            const isAdminRoute = path && !!matchPath(path, { path: ADMIN });
            const apolloClient = isAdminRoute
              ? client.adminClient
              : client.memberClient;
            return (
              <Transaction {...transactionProps} client={apolloClient}>
                {(...apolloArgs) => {
                  let apollo;
                  let mutationFunc;
                  if (type === "mutation") {
                    [mutationFunc, apollo] = apolloArgs;
                  } else {
                    [apollo] = apolloArgs;
                  }

                  if (apollo.error) {
                    castErrors(apollo.error.errors);
                  }
                  let props = { ...originalProps, ...apollo, mutationFunc };

                  if (mapResultToProps) {
                    props = mapResultToProps(
                      apollo,
                      originalProps,
                      transactionProps,
                      mutationFunc
                    );
                  }
                  return <Component {...props} />;
                }}
              </Transaction>
            );
          }}
        </ApolloConsumer>
      );
    };
  };
}

export const build = {
  mutation: buildTransaction("mutation"),
  query: buildTransaction("query"),
};

export function castErrors(errors = []) {
  errors.forEach(error => {
    error.platformError = utils.deserialize(
      get(error, "extensions.response.body")
    );
  });
}

export function relayUpdateQuery(
  namespace,
  updateAction,
  prev,
  { fetchMoreResult }
) {
  if (!fetchMoreResult) return prev;
  if (!["append", "prepend"].includes(updateAction)) {
    throw new Error("updateAction must be either append or prepend");
  }
  let edges;
  if (updateAction === "append") {
    edges = [...prev[namespace].edges, ...fetchMoreResult[namespace].edges];
  } else {
    edges = [...fetchMoreResult[namespace].edges, ...prev[namespace].edges];
  }
  return {
    ...fetchMoreResult,
    [namespace]: {
      ...fetchMoreResult[namespace],
      edges,
      pageInfo: {
        ...fetchMoreResult[namespace].pageInfo,
      },
    },
  };
}
