import { t } from "localizify";
import PropTypes from "prop-types";
import React from "react";
import { Modal } from "semantic-ui-react";

import Acknowledge from "../../../../components/acknowledgement/index.web";
import Markdown from "../../../../components/markdown/index.web";
import { queryAndAcceptProductTerms } from "../../graphql/product-agreement";

export function ProductAgreementModal({
  loading,
  terms,
  onSubmit,
  acceptProductAgreement,
  acceptProductAgreementLoading,
}) {
  return (
    <Modal open closeOnDimmerClick={false}>
      <Modal.Header>{t("articles.product_agreement.modal_title")}</Modal.Header>
      <Modal.Content scrolling>
        <p>{t("articles.product_agreement.agreement_subtitle")}</p>

        <Markdown
          markdown={terms}
          loading={loading}
          numberOfSegments={5}
          preWrap
        />
      </Modal.Content>
      <Modal.Actions>
        <Acknowledge
          acknowledgeText={t("articles.product_agreement.cta_text")}
          confirmText={t("articles.product_agreement.cta_confirm")}
          onAccept={() => {
            acceptProductAgreement();
            onSubmit();
          }}
          loading={acceptProductAgreementLoading}
        />
      </Modal.Actions>
    </Modal>
  );
}

ProductAgreementModal.propTypes = {
  terms: PropTypes.string,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  acceptProductAgreement: PropTypes.func,
  acceptProductAgreementLoading: PropTypes.bool,
};

ProductAgreementModal.defaultProps = {
  terms: "",
  loading: false,
  onSubmit: () => {},
  acceptProductAgreement: () => {},
  acceptProductAgreementLoading: false,
};

export default queryAndAcceptProductTerms(ProductAgreementModal);
