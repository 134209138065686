export const SETTINGS_HOME = "/settings";
export const SETTINGS_NOTIFICATIONS = `${SETTINGS_HOME}/notifications`;
export const SETTINGS_TEAM = `${SETTINGS_HOME}/team`;
export const SETTINGS_BILLING = `${SETTINGS_HOME}/billing`;

export const NATIVE_SETTINGS_NOTIFICATIONS = "NotificationSettings";
export const NATIVE_SETTINGS_NOTIFICATION_DETAIL = "NotificationSettingDetail";

export const linkTo = {
  security: () => SETTINGS_HOME,
  notifications: () => SETTINGS_NOTIFICATIONS,
  team: () => SETTINGS_TEAM,
  billing: () => SETTINGS_BILLING,
};

export const segmentCategories = {
  [SETTINGS_HOME]: "MyAccount",
  [SETTINGS_TEAM]: "MyAccount",
  [SETTINGS_BILLING]: "MyAccount",
};
