import "./style.css";

import React, { useState } from "react";
import { Container, Grid, Message } from "semantic-ui-react";

import SectionHeader from "../../../../components/section-header/index.web";
import SegmentPage from "../../../../components/segment/page";
import TeamMemberToggle, {
  ASSISTANT_TYPE,
} from "../../../../components/team-member-toggle/index.web";
import { namespacedT } from "../../../../lib/i18n";
import { useMyTeamMemberContactDelete } from "../../add-team-member/hooks";
import AddTeamMember from "../../add-team-member/index.web";
import useGetTeamSettings from "./hooks";

const tSettings = namespacedT("settings");
const tMemberToggle = namespacedT("onboard.team_member_toggle");

export const SubsectionTeam = () => {
  const [showTeamContact, setShowTeamContact] = useState({
    open: false,
  });

  const { assistant, publicRelations, error } = useGetTeamSettings();

  const { handleDelete: handleAssistantDelete } = useMyTeamMemberContactDelete(
    ASSISTANT_TYPE.EA
  );
  const { handleDelete: handlePrDelete } = useMyTeamMemberContactDelete(
    ASSISTANT_TYPE.PR
  );

  return (
    <>
      {error ? (
        <Message negative>{error}</Message>
      ) : (
        <>
          <Container>
            <Grid columns={1}>
              <Grid.Row>
                <Grid.Column>
                  <SectionHeader title={tSettings("subsections.team")} />
                  <Grid columns={2} stackable>
                    <Grid.Column>
                      <SegmentPage
                        view="SettingsAssistantContact"
                        logOnEvent="handleEdit"
                      >
                        <TeamMemberToggle
                          data-ui-action="add-team-member-executive-open"
                          data-ui-container="team-member-executive"
                          memberData={assistant}
                          handleEdit={() =>
                            setShowTeamContact({
                              open: true,
                              type: ASSISTANT_TYPE.EA,
                              values: assistant,
                            })
                          }
                          handleDelete={handleAssistantDelete}
                        />
                      </SegmentPage>
                    </Grid.Column>
                    <Grid.Column>
                      <SegmentPage
                        view="SettingsPRContact"
                        logOnEvent="handleEdit"
                      >
                        <TeamMemberToggle
                          data-ui-action="add-team-member-pr-open"
                          type={ASSISTANT_TYPE.PR}
                          memberData={publicRelations}
                          secondLine={tMemberToggle("pr_marketing")}
                          thirdLine={tMemberToggle("support")}
                          handleEdit={() => {
                            setShowTeamContact({
                              open: true,
                              type: ASSISTANT_TYPE.PR,
                              values: publicRelations,
                            });
                          }}
                          handleDelete={handlePrDelete}
                        />
                      </SegmentPage>
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>

          <AddTeamMember
            open={showTeamContact.open}
            type={showTeamContact.type}
            initialValues={showTeamContact.values}
            onSave={() => setShowTeamContact({ open: false })}
            onCancel={() => setShowTeamContact({ open: false })}
          />
        </>
      )}
    </>
  );
};

export default SubsectionTeam;
