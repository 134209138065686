import { merge, pickBy } from "lodash";

import prefixUrl from "../../../public-path";
import fetch, { handleMsgFromServer } from "../fetch-manager";

export default {
  Mutation: {
    submitPayment: async (root, { input }) => {
      const uri = prefixUrl("/rest/get-started/payment");
      const params = new URLSearchParams(window.location.search);
      const { token, nonce, email } = input;

      try {
        const res = await fetch(uri, {
          method: "POST",
          body: JSON.stringify(
            pickBy({
              dealId: params.get("dealId"),
              planId: params.get("productId"),
              email: params.get("email"),
              paymentToken: token,
              paymentNonce: nonce,
              paymentEmail: email,
              pricePointId: params.get("pricePointId") || null,
            })
          ),
          headers: { "Content-Type": "application/json" },
        });
        await handleMsgFromServer(res);
      } catch (e) {
        e.type = "payment";
        throw e;
      }
    },
    createPassword: async (root, { input }) => {
      const uri = prefixUrl("/rest/get-started/password");
      const params = new URLSearchParams(window.location.search);
      const { username, password } = input;
      try {
        const res = await fetch(uri, {
          method: "POST",
          body: JSON.stringify(
            pickBy({
              dealId: params.get("dealId"),
              username,
              password,
            })
          ),
          headers: { "Content-Type": "application/json" },
        });
        await handleMsgFromServer(res);
      } catch (e) {
        e.type = "payment";
        throw e;
      }
    },
  },
  Query: {
    async onboardingData() {
      const { search } = window.location;
      const uri = prefixUrl(`/rest/get-started${search}`);

      let data;
      try {
        const res = await fetch(uri, {
          headers: { "Content-Type": "application/json" },
        });
        data = await handleMsgFromServer(res);
      } catch (e) {
        if (
          (e.response && e.response.status === 400) ||
          e.message.includes("status code 400")
        ) {
          e.type = "invalid_membership";
        }
        throw e;
      }

      // When doing client side resolving, you yourself need to set __typename compared to the server automatically doing it
      merge(data, {
        __typename: "OnboardingData",
      });
      if (data.paymentMembershipOffer) {
        merge(data, {
          paymentMembershipOffer: {
            __typename: "OnboardingPaymentMembershipOffer",
          },
        });
      }
      if (!data.email) {
        const params = new URLSearchParams(search);
        data.email = params.get("email");
      }

      if (!data.braintreeClientToken) {
        data.braintreeClientToken = null;
      }

      return data;
    },
  },
};
