import { t } from "localizify";
import PropTypes from "prop-types";
import React from "react";
import { useMutation } from "react-apollo";
import { Button, Divider, Header } from "semantic-ui-react";

import ButtonTertiary from "../../../components/button/tertiary/index.web";
import { logEvent } from "../../../lib/analytics";
import { FORGOT } from "../../password/graphql";
import { useRequestOneTimeCode } from "./shared";

export function PasswordLinkSentConfirmation({ dispatchLoginState }) {
  return (
    <>
      <Header size="large">{t("auth.login.reset_confirmation.heading")}</Header>

      <p className="login-area-intro">
        {t("auth.login.reset_confirmation.intro")}
      </p>

      <Button
        type="button"
        secondary
        className="login-area-cta"
        onClick={() => {
          logEvent(
            "MemberLogin",
            "ButtonClick",
            "Password Reset Go To Sign In"
          );
          dispatchLoginState({ type: "LOGIN_CANCELED" });
        }}
      >
        {t("auth.login.reset_confirmation.cta")}
      </Button>
    </>
  );
}

export default function LoginForgotPassword({ dispatchLoginState, channelTo }) {
  const [sendResetEmail, { loading: sendResetEmailLoading }] = useMutation(
    FORGOT,
    {
      variables: {
        username: channelTo,
      },
      onCompleted: () => {
        logEvent("MemberLogin", "FormSubmit", "Email Password Reset Link");
        dispatchLoginState({
          type: "RESET_LINK_REQUESTED",
        });
      },
    }
  );

  const {
    requestOneTimeCode,
    requestOneTimeCodeLoading,
  } = useRequestOneTimeCode({
    dispatch: dispatchLoginState,
    channelTo,
  });

  return (
    <>
      <Header size="large">{t("auth.login.forgot_password.heading")}</Header>

      <p className="login-area-intro">
        {t("auth.login.forgot_password.intro")}
        <strong>{channelTo}</strong>?
      </p>

      <div style={{ maxWidth: 252, margin: "0 auto" }}>
        <Button
          type="button"
          onClick={sendResetEmail}
          loading={sendResetEmailLoading}
          secondary
          fluid
          className="login-area-cta"
        >
          {t("auth.login.cta_reset_password")}
        </Button>

        <Divider horizontal className="login-area-divider">
          {t("auth.login.text_split")}
        </Divider>
        <Button
          type="button"
          onClick={e => {
            logEvent(
              "MemberLogin",
              "ButtonClick",
              "Forgot Password Sign In with OTC"
            );
            requestOneTimeCode(e);
          }}
          loading={requestOneTimeCodeLoading}
          secondary
          fluid
          className="login-area-cta"
        >
          {t("auth.login.cta_request_code")}
        </Button>

        <ButtonTertiary
          onClick={() => {
            logEvent("MemberLogin", "LinkClick", "Cancel Forgot Password");
            dispatchLoginState({ type: "LOGIN_REQUESTED", channelTo });
          }}
          type="button"
          style={{
            margin: "10px auto 0",
          }}
          content={t("auth.login.cta_full_cancel")}
          basic
          underlined
          subtle
        />
      </div>
    </>
  );
}

LoginForgotPassword.propTypes = {
  dispatchLoginState: PropTypes.func.isRequired,
  channelTo: PropTypes.string.isRequired,
};

PasswordLinkSentConfirmation.propTypes = {
  dispatchLoginState: PropTypes.func.isRequired,
};
