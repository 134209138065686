export const PROFILE_HOME = "/profile";
export const PROFILE_MEMBER = `${PROFILE_HOME}/:userId`;
export const PROFILE_SELF = `${PROFILE_MEMBER}/self`;

export const linkTo = {
  member: userId => PROFILE_MEMBER.replace(":userId", userId),
  self: userId => PROFILE_SELF.replace(":userId", userId),
};

export const segmentCategories = {
  [PROFILE_HOME]: "Profile",
};
