import PropTypes from "prop-types";
import React from "react";

import { getErrorMessage } from "./shared";

export default function ErrorText({ error, message, ...props }) {
  if (!error && !message) {
    return null;
  }

  return (
    <p className="login-area-input-error-text" {...props}>
      {message || getErrorMessage(error)}
    </p>
  );
}

ErrorText.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  message: PropTypes.string,
};

ErrorText.defaultProps = {
  error: null,
  message: "",
};
