import { t } from "localizify";
import { get } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useMutation } from "react-apollo";
import { Button, Divider, Form, Header } from "semantic-ui-react";

import ButtonTertiary from "../../../components/button/tertiary/index.web";
import { logEvent } from "../../../lib/analytics";
import { LOGIN } from "../graphql";
import ErrorText from "./error-text.web";
import { useRequestOneTimeCode } from "./shared";

export default function LoginWithPasswordForm({
  dispatchLoginState,
  channelTo,
  error,
  loading,
  password,
  onLoginComplete,
}) {
  const [form, setForm] = React.useState({
    password,
    isValid: password.length > 0,
    error,
  });

  const [loginWithPassword, { loading: mutationLoading }] = useMutation(LOGIN, {
    variables: { username: channelTo.trim(), password: form.password },
    onCompleted: () => {
      logEvent("MemberLogin", "LoginSuccess", "Password Success");
      onLoginComplete();
    },
    onError: err => {
      logEvent(
        "MemberLogin",
        "ErrorShown",
        get(err, "networkError.response.data.code") || "UnknownError"
      );

      setForm({ ...form, error: err });
    },
  });

  const {
    requestOneTimeCode,
    requestOneTimeCodeLoading,
  } = useRequestOneTimeCode({
    dispatch: dispatchLoginState,
    channelTo,
  });

  const isLoading = loading || mutationLoading;

  return (
    <>
      <Header size="large">
        {t("auth.login.login_with_password.heading")}
      </Header>

      <p className="login-area-intro">
        {t("auth.login.login_with_password.intro")}
        <strong>{channelTo}</strong>
      </p>
      <Form
        size="small"
        onSubmit={e => {
          logEvent("MemberLogin", "FormSubmit", "Sign In");
          loginWithPassword(e);
        }}
        className="login-area-form"
      >
        <Form.Input
          disabled={isLoading}
          error={!!form.error}
          id="password"
          icon="lock"
          iconPosition="left"
          loading={isLoading}
          name="password"
          type="password"
          placeholder={t("auth.login.placeholder_password")}
          className="login-area-input"
          size="small"
          fluid
          onChange={async e => {
            const passwordValue = e.target.value;
            const isValid = passwordValue.length > 0;
            setForm({
              ...form,
              isValid,
              password: passwordValue,
              error: undefined,
            });
          }}
          value={form.password}
        />
        <ErrorText error={form.error} />

        <Button
          type="submit"
          loading={isLoading}
          disabled={!form.isValid || isLoading}
          secondary
          className="login-area-cta"
        >
          {t("auth.login.cta_sign_in")}
        </Button>

        <ButtonTertiary
          onClick={() => {
            logEvent("MemberLogin", "LinkClick", "Forgot Password");
            dispatchLoginState({ type: "FORGOT_PASSWORD_REQUESTED" });
          }}
          type="button"
          style={{
            margin: "10px auto 0",
          }}
          content={t("auth.login.cta_forgot_password")}
          basic
          underlined
          subtle
        />

        <Divider horizontal className="login-area-divider">
          {t("auth.login.text_split")}
        </Divider>
      </Form>

      <Button
        type="button"
        loading={requestOneTimeCodeLoading}
        onClick={e => {
          logEvent("MemberLogin", "LinkClick", "Sign In with OTC");
          requestOneTimeCode(e);
        }}
        secondary
        className="login-area-cta"
      >
        {t("auth.login.cta_request_code")}
      </Button>
    </>
  );
}

LoginWithPasswordForm.propTypes = {
  dispatchLoginState: PropTypes.func.isRequired,
  error: PropTypes.shape({ message: PropTypes.string }),
  loading: PropTypes.bool,
  password: PropTypes.string,
  channelTo: PropTypes.string.isRequired,
  onLoginComplete: PropTypes.func.isRequired,
};

LoginWithPasswordForm.defaultProps = {
  error: null,
  loading: false,
  password: "",
};
