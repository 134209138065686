import { t } from "localizify";
import React, { Suspense, lazy } from "react";
import { Route } from "react-router";
import { Redirect, Switch } from "react-router-dom";
import { Loader } from "semantic-ui-react";

import AppLayout from "./components/app-layout/index.web";
import SimplifiedAppLayout from "./components/simplified-app-layout/index.web";
import TitleWrapper from "./components/title-wrapper/index.web";
import AdminSitesList from "./containers/admin/components/admin-sites-list.web";
import { ADMIN_SITES } from "./containers/admin/routes-links";
import Admin from "./containers/admin/routes.web";
import ProductAgreementGateway from "./containers/article/agreements/product-agreement/gateway.web";
import InterimEditorPage from "./containers/article/interim-editor/index.web";
import { linkTo as articlesLinkTo } from "./containers/article/routes-links";
import { linkTo as authLinkTo } from "./containers/auth/routes-links";
import Auth from "./containers/auth/routes.web";
import { ONBOARDING as onboardingRoute } from "./containers/onboarding/routes-links";
import Onboarding from "./containers/onboarding/routes.web";
import PublicPassword from "./containers/password/routes.web";
import PaymentGate from "./containers/payment/dunning-member/routes.web";
import { SETTINGS_HOME } from "./containers/settings/routes-links";
import Settings from "./containers/settings/routes.web";
import useIdentity, { getAuthRedirectWeb } from "./lib/use-identity";

const MemberLazy = lazy(() =>
  import(/* webpackChunkName: "member" */ "./routes-member.web")
);

const MemberProtectedRoutes = () => {
  const { gate, isLoggedIn } = useIdentity();

  if (!isLoggedIn) {
    return <Redirect to={getAuthRedirectWeb(authLinkTo.login())} />;
  }

  if (gate === "onboarding") {
    return <Redirect to={onboardingRoute} />;
  }

  return <MemberLazy />;
};

const InterimEditor = () => {
  const { isLoggedIn } = useIdentity();

  if (!isLoggedIn) {
    return <Redirect to={getAuthRedirectWeb(authLinkTo.login())} />;
  }

  return <InterimEditorPage />;
};

const SettingsSimplifiedLayout = () => {
  const { isLoggedIn } = useIdentity();

  if (!isLoggedIn) {
    return <Redirect to={getAuthRedirectWeb(authLinkTo.login())} />;
  }

  return <SimplifiedAppLayout>{Settings}</SimplifiedAppLayout>;
};

export default function routesWeb() {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {/* Auth Routes */}
        {Auth}

        {/* This route can be redirected to before the admin protected routes are loaded */}
        <Route path={ADMIN_SITES} render={AdminSitesList} />

        {/* Admin Routes */}
        {Admin}

        {/* Onboarding Routes */}
        {Onboarding}

        {/* Payment */}
        {PaymentGate}

        <Route
          exact
          path={articlesLinkTo.edit()}
          render={props => (
            <TitleWrapper title={t("articles.edit_article.page_title")}>
              {/* eslint-disable-next-line react/prop-types */}
              <ProductAgreementGateway history={props.history}>
                <InterimEditor {...props} />
              </ProductAgreementGateway>
            </TitleWrapper>
          )}
        />

        <Route
          path={SETTINGS_HOME}
          render={props => <SettingsSimplifiedLayout {...props} />}
        />

        <AppLayout>
          <Switch>
            {/* Publicly Accessible Password Routes */}
            {PublicPassword}

            {/* Assume all other requests are authed members */}
            <MemberProtectedRoutes />
          </Switch>
        </AppLayout>
      </Switch>
    </Suspense>
  );
}
