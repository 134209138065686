import { get } from "lodash";
import { useQuery } from "react-apollo";

import { formatGraphQLErrorMessage } from "../../../../lib/error/utils";
import STATEMENTS_INFO from "./graphql";

export default () => {
  const { loading, error, data } = useQuery(STATEMENTS_INFO);
  const statements = get(data, "statements") || [];

  return {
    loading,
    error: formatGraphQLErrorMessage(error),
    statements,
  };
};
