import admin from "./containers/admin/language.en";
import article from "./containers/article/language.en.json";
import auth from "./containers/auth/language.en.json";
import directory from "./containers/directory/language.en.json";
import badges from "./containers/dynamic-badge/language.en.json";
import expertPanels from "./containers/expert-panels/language.en.json";
import forum from "./containers/forum/language.en.json";
import invite from "./containers/invite/language.en.json";
import memberProfile from "./containers/member-profile/language.en";
import messages from "./containers/messages/language.en.json";
import notifications from "./containers/notifications/language.en.json";
import offers from "./containers/offers/language.en.json";
import onboarding from "./containers/onboarding/language.en.json";
import password from "./containers/password/language.en.json";
import payment from "./containers/payment/language.en.json";
import settings from "./containers/settings/language.en.json";
import app from "./language.en.json";

const en = {
  ...article,
  ...auth,
  ...badges,
  ...directory,
  ...forum,
  ...invite,
  ...messages,
  ...notifications,
  ...password,
  ...onboarding,
  ...expertPanels,
  ...payment,
  ...settings,
  ...app,
  ...memberProfile,
  ...admin,
  ...offers,
};

export default en;
