export const ERROR_CODES = {
  INVALID_URL: 100,
  INVALID_PHONE: 110,
  PASSWORD_MISMATCH: 120,
  PASSWORD_STRENGTH: 121,
  INVALID_EMAIL: 130,
  INVALID_DATE: 140,
  MISSING_INPUT: 900,
  INVALID_ERROR: 999,
};

export const ERROR_LIST = [
  {
    code: ERROR_CODES.INVALID_URL,
    message: "errors.invalid_url",
  },
  {
    code: ERROR_CODES.INVALID_PHONE,
    message: "errors.invalid_phone",
  },
  {
    code: ERROR_CODES.PASSWORD_MISMATCH,
    message: "errors.password_mismatch",
  },
  {
    code: ERROR_CODES.PASSWORD_STRENGTH,
    message: "errors.password_strength",
  },
  {
    code: ERROR_CODES.INVALID_EMAIL,
    message: "errors.invalid_email",
  },
  {
    code: ERROR_CODES.INVALID_DATE,
    message: "errors.invalid_date",
  },
  {
    code: ERROR_CODES.MISSING_INPUT,
    message: "errors.missing_input",
  },
  {
    code: ERROR_CODES.INVALID_ERROR,
    message: "errors.invalid_error",
  },
];
