import "./style.css";

import PropTypes from "prop-types";
import React from "react";
import { Icon } from "semantic-ui-react";

export default function ValidationRequirement({ isValid, label }) {
  return (
    <span
      style={{
        marginLeft: "15px",
        fontSize: "12px",
      }}
    >
      <Icon
        style={{
          verticalAlign: "text-bottom",
          marginRight: "3px",
        }}
        className={isValid ? "check-circle" : "circle"}
      />{" "}
      {label}
    </span>
  );
}

ValidationRequirement.propTypes = {
  isValid: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};
