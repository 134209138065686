import prefixUrl from "../../../public-path";
import fetch, { handleMsgFromServer } from "../fetch-manager";

export default {
  Query: {
    async subscriptionBalance(root, { input: { subscriptionId, userId } }) {
      const uri = prefixUrl("/rest/payment/check-balance");

      const res = await fetch(uri, {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify({
          subscriptionId,
          userId,
        }),
      });
      const data = await handleMsgFromServer(res);
      return {
        memberName: data.memberName,
        publicKey: data.publicKey,
        serverHost: data.serverHost,
        outstandingBalance: data.outstandingBalance,
        showNoBalanceDue: data.showNoBalanceDue,
        renewalDate: data.renewalDate,
        __typename: "SubscriptionBalance",
      };
    },
  },
  Mutation: {
    async paySubscriptionByToken(root, { input: { token, userId } }) {
      const uri = prefixUrl("/rest/payment/pay-balance");

      const res = await fetch(uri, {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify({
          token,
          userId,
        }),
      });
      const data = await handleMsgFromServer(res);
      return data.id;
    },
  },
};
