import { t } from "localizify";
import propTypes from "prop-types";
import React, { useLayoutEffect } from "react";

import setTitle from "../../../../lib/webpage";
import { resetPassword, validateToken } from "../../../password/graphql";
import { useQueryLoginConfig } from "../../graphql";
import Layout from "../../login/layout.web";
import ResetPasswordForm from "./form.web";
import ResetPasswordState from "./states.web";

const WiredResetPasswordForm = resetPassword(ResetPasswordForm);

function ResetPassword({ type, ...props }) {
  const config = useQueryLoginConfig();

  useLayoutEffect(() => {
    const title =
      type === "reset"
        ? t("password.reset.welcome_heading_reset")
        : t("password.reset.welcome_heading_setup");

    setTitle(title);
  }, [type]);

  return (
    <Layout {...config}>
      <ResetPasswordState {...props}>
        <WiredResetPasswordForm type={type} token={props.token} />
      </ResetPasswordState>
    </Layout>
  );
}

export default validateToken(ResetPassword);

ResetPassword.propTypes = {
  token: propTypes.string.isRequired,
  type: propTypes.oneOf(["reset", "setup"]).isRequired,
};
