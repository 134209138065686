import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Form, Message } from "semantic-ui-react";

import PhoneNumberInput from "../../../components/phone-number-input/index.web";
import { namespacedT } from "../../../lib/i18n";

const t = namespacedT("");

export const TeamMemberForm = ({
  decorateInput,
  doSubmit,
  serverErrors = [],
}) => {
  return (
    <Fragment>
      <Form onSubmit={doSubmit}>
        <Form.Field
          control={Form.Input}
          {...decorateInput("name")}
          required
          className="onboarding-form-input"
          label={t("onboard.add_team_contact_form.name_label")}
          placeholder={`${t(
            "onboard.add_team_contact_form.name_placeholder"
          )}...`}
        />

        <Form.Field
          control={Form.Input}
          {...decorateInput("email")}
          required
          className="onboarding-form-input"
          label={t("onboard.add_team_contact_form.email_label")}
          placeholder={`${t("onboard.add_team_contact_form.email_label")}...`}
        />

        <Form.Field
          control={PhoneNumberInput}
          {...decorateInput("mobilePhone")}
          className="onboarding-form-input"
          label={t("onboard.add_team_contact_form.mobile_phone_label")}
          placeholder={`${t(
            "onboard.add_team_contact_form.mobile_phone_label"
          )}...`}
        />

        <Form.Field
          control={PhoneNumberInput}
          {...decorateInput("officePhone")}
          className="onboarding-form-input"
          label={t("onboard.add_team_contact_form.office_phone_label")}
          placeholder={`${t(
            "onboard.add_team_contact_form.office_phone_label"
          )}...`}
          includeExt
        />
      </Form>
      {serverErrors && serverErrors.length > 0 && (
        <Message negative list={serverErrors} />
      )}
    </Fragment>
  );
};

export default TeamMemberForm;

export const initialValueProps = PropTypes.shape({
  name: PropTypes.string,
  email: PropTypes.string,
  mobilePhone: PropTypes.shape({
    countryCode: PropTypes.string,
    number: PropTypes.string,
    extension: PropTypes.string,
  }),
  officePhone: PropTypes.shape({
    countryCode: PropTypes.string,
    number: PropTypes.string,
    extension: PropTypes.string,
  }),
  assistant: PropTypes.shape({
    mobilePhone: PropTypes.shape({
      countryCode: PropTypes.string,
      number: PropTypes.string,
      extension: PropTypes.string,
    }),
    officePhone: PropTypes.shape({
      countryCode: PropTypes.string,
      number: PropTypes.string,
      extension: PropTypes.string,
    }),
  }),
  publicRelations: PropTypes.shape({
    mobilePhone: PropTypes.shape({
      countryCode: PropTypes.string,
      number: PropTypes.string,
      extension: PropTypes.string,
    }),
    officePhone: PropTypes.shape({
      countryCode: PropTypes.string,
      number: PropTypes.string,
      extension: PropTypes.string,
    }),
  }),
});

export const defaultInitialValues = {
  id: "",
  name: "",
  email: "",
  mobilePhone: {
    countryCode: "",
    number: "",
    extension: "",
  },
  officePhone: {
    countryCode: "",
    number: "",
    extension: "",
  },
  assistant: {
    mobilePhone: {
      countryCode: "",
      number: "",
      extension: "",
    },
    officePhone: {
      countryCode: "",
      number: "",
      extension: "",
    },
  },
  publicRelations: {
    mobilePhone: {
      countryCode: "",
      number: "",
      extension: "",
    },
    officePhone: {
      countryCode: "",
      number: "",
      extension: "",
    },
  },
};

TeamMemberForm.propTypes = {
  decorateInput: PropTypes.func.isRequired,
  doSubmit: PropTypes.func,
  serverErrors: PropTypes.arrayOf(PropTypes.string),
};

TeamMemberForm.defaultProps = {
  doSubmit: () => {},
  serverErrors: [],
};
