import { t } from "localizify";
import { get } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useMutation, useQuery } from "react-apollo";
import { Button, Dropdown, Form, Header, Icon } from "semantic-ui-react";

import { GET_AVAILABLE_COMMUNITIES, LOGIN } from "../graphql";
import ErrorText from "./error-text.web";
import { isValidChannelTo } from "./login-source-input.web";
import { setRememberMe } from "./shared";

export default function AdminLogin({
  channelTo,
  error,
  loading,
  password,
  onLoginComplete,
}) {
  // form state
  const [form, setForm] = React.useState({
    channelTo,
    password,
    error,
    validChannelTo: isValidChannelTo(channelTo),
    rememberEmail: true,
  });
  const [targetCommunity, setTargetCommunity] = React.useState(null);

  const {
    data: availableCommunitiesData,
    loading: availableCommunitiesLoading,
  } = useQuery(GET_AVAILABLE_COMMUNITIES);
  const availableCommunities =
    get(availableCommunitiesData, "availableCommunities") || [];

  // Set the first community to the initial community selected
  React.useEffect(() => {
    if (!targetCommunity && availableCommunities.length > 0) {
      setTargetCommunity(availableCommunities[0].code);
    }
  }, [availableCommunities, targetCommunity]);

  const [loginWithPassword, { loading: mutationLoading }] = useMutation(LOGIN, {
    variables: {
      username: form.channelTo.trim(),
      password: form.password,
      targetCommunity,
    },
    onCompleted: () => {
      setRememberMe(form.rememberEmail, form.channelTo.trim());
      onLoginComplete();
    },
    onError: () => {
      setForm({ ...form, error: {} });
    },
  });

  const isLoading = loading || mutationLoading || availableCommunitiesLoading;

  return (
    <>
      <Header size="large">{t("auth.admin_login.heading")}</Header>

      <p className="login-area-intro">{t("auth.admin_login.intro")}</p>
      <Form
        size="small"
        onSubmit={loginWithPassword}
        className="login-area-form"
      >
        <Form.Input
          disabled={isLoading}
          error={!!form.channelToError}
          id="source"
          loading={isLoading}
          name="source"
          icon={<Icon className="envelope-solid" />}
          iconPosition="left"
          placeholder={t("auth.login.source_input.placeholder_source")}
          className="login-area-input"
          size="small"
          type="email"
          autoCapitalize="none"
          fluid
          onChange={async e => {
            const channelToValue = e.target.value;
            const formState = {
              ...form,
              error: null,
              channelTo: channelToValue,
            };

            const [validChannelTo, err] = isValidChannelTo(form.channelTo);
            formState.validChannelTo = validChannelTo;

            if (form.hasChanged) {
              formState.channelToError = err;
            }

            setForm(formState);
          }}
          onBlur={() => {
            const [validChannelTo, err] = isValidChannelTo(form.channelTo);
            setForm({
              ...form,
              validChannelTo,
              channelToError: err,
              hasChanged: true,
            });
          }}
          value={form.channelTo}
        />

        <ErrorText error={form.error || form.channelToError} />

        <Form.Checkbox
          className="login-area-checkbox"
          label={t("auth.login.source_input.remember_text")}
          onChange={(e, value) => {
            // forget me right away if this gets checked!
            if (!value.checked) {
              setRememberMe(false);
            }
            setForm({ ...form, rememberEmail: value.checked });
          }}
          checked={form.rememberEmail}
        />

        <Form.Input
          disabled={isLoading}
          error={form.passwordError}
          id="password"
          icon="lock"
          iconPosition="left"
          loading={isLoading}
          name="password"
          type="password"
          placeholder={t("auth.login.placeholder_password")}
          className="login-area-input"
          size="small"
          fluid
          onChange={e => {
            const passwordValue = e.target.value;
            const validPassword = passwordValue.length > 0;
            setForm({
              ...form,
              validPassword,
              password: passwordValue,
              passwordError: !validPassword,
            });
          }}
          value={form.password}
        />

        <Dropdown
          className="login-area-dropdown"
          placeholder={t("auth.admin_login.placeholder_community")}
          fluid
          selection
          loading={availableCommunitiesLoading}
          options={availableCommunities.map(({ code, familiarName }) => ({
            key: code,
            value: code,
            text: familiarName,
          }))}
          onChange={(e, { value }) => {
            setTargetCommunity(value);
          }}
          value={targetCommunity}
        />

        <Button
          type="submit"
          loading={isLoading}
          disabled={isLoading || !form.validChannelTo || !form.validPassword}
          secondary
          className="login-area-cta"
        >
          {t("auth.login.cta_sign_in")}
        </Button>
      </Form>
    </>
  );
}

AdminLogin.propTypes = {
  error: PropTypes.shape({ message: PropTypes.string }),
  loading: PropTypes.bool,
  password: PropTypes.string,
  channelTo: PropTypes.string.isRequired,
  onLoginComplete: PropTypes.func.isRequired,
};

AdminLogin.defaultProps = {
  error: null,
  loading: false,
  password: "",
};
