export default function DateTime({ date }) {
  const todayZeroedTime = new Date();
  todayZeroedTime.setHours(0, 0, 0, 0);
  const dateZeroedTime = new Date(date);
  dateZeroedTime.setHours(0, 0, 0, 0);

  if (todayZeroedTime.getTime() === dateZeroedTime.getTime()) {
    return new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
    }).format(new Date(date));
  }

  return new Intl.DateTimeFormat("en-US", {
    day: "numeric",
    month: "short",
  }).format(new Date(date));
}
