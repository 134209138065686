import prefixUrl from "../../../public-path";
import fetch, { handleMsgFromServer } from "../fetch-manager";

export default {
  Query: {
    async passwordTokenIsValid(root, { token }) {
      const uri = prefixUrl(`/rest/validate-reset-token?token=${token}`);

      const res = await fetch(uri, {
        headers: { "Content-Type": "application/json" },
      });
      const { isValid } = await handleMsgFromServer(res);

      return {
        isValid,
        __typename: "PasswordTokenIsValidResponse",
      };
    },
  },
  Mutation: {
    async passwordForgot(root, { username }) {
      const uri = prefixUrl("/rest/password-forgot");

      const res = await fetch(uri, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username }),
      });
      await handleMsgFromServer(res);

      return true;
    },
    async passwordReset(root, { input: { resetToken, newPassword } }) {
      const uri = prefixUrl("/rest/reset-password");

      const res = await fetch(uri, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ resetToken, newPassword }),
      });
      await handleMsgFromServer(res);

      return true;
    },
  },
};
