/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useLocation } from "react-router";

import { PROFILE_HOME } from "../../containers/profile/routes-links";
import { logPageSegment } from "../../lib/analytics/segment";
import useIdentity from "../../lib/use-identity";
import Alerts from "../alerts/index.web";
import Layout from "./Layout.web";
import { appQuery } from "./graphql";

function logPage(mutations, observer) {
  logPageSegment();
  observer.disconnect();
}
const mutationObserver = new MutationObserver(logPage);
// configuration of the observer:
const config = { subtree: true, characterData: true, childList: true };

function LayoutWithAlert(props) {
  const location = useLocation();
  const identity = useIdentity();

  useEffect(() => {
    if (
      !identity.hasRole("ADMIN") &&
      /\/(?:forum|groups|members|profile|expert-panels|offers|notifications)/.test(
        location.pathname
      )
    ) {
      window.location.href = "/v2";
    }

    // Using a reduce function on OR'd conditions is not as efficient as a stright up IF statement,
    // but using an array of conditions is more readable IMO, and more maintainable if more conditions need to be added
    // IMPORTANT: CONDITIONS WILL BE OR'D TOGETHER. AND CONDITIONS NEED TO BE GROUPED TO A SINGLE ITEM
    const waitForTitleChangeConditions = [
      // On initial load, the title is hard coded to "Loading...". In that case the proper time to log the page is after the title has changed
      // NOTE: If the title changes in /client/web/public/index.html changes, this will need to be changed as well.
      document.title === "Loading...",

      // On profile the title will switch to the User's name after a fetch to the graph to get the DisplayName, which will render with the previous page's title at first
      // so, if not on /self, then this needs to wait for a title change
      location.pathname.startsWith(PROFILE_HOME) &&
        !location.pathname.endsWith("self"),
    ];
    if (waitForTitleChangeConditions.some(x => x)) {
      const target = document.querySelector("title");
      mutationObserver.observe(target, config);
      return;
    }
    // All other route changes need to setTitle in a useLayoutEffect because that is a synchronous change
    logPageSegment();
    // Disabling exhaustive-deps here as we only want to run this when the route changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search]);

  return <Layout {...props} AlertsComponent={Alerts} />;
}

export default appQuery(LayoutWithAlert);
