import { t } from "localizify";
import PropTypes from "prop-types";
import React from "react";

import SegmentPage from "../../../components/segment/page";
import AgreementModal from "../agreements/modal.web";

export default function ApproveArticleModal({
  open,
  onClose,
  submitting,
  error,
  onSubmit,
}) {
  return (
    <SegmentPage view="ArticleSubmitConfirm" logOnEvent="onOpen">
      <AgreementModal
        headerText={t("articles.article_approval_agreement.modal_title")}
        bodyText={t("articles.article_approval_agreement.agreement_text")}
        boldBodyText={t(
          "articles.article_approval_agreement.agreement_text_bold_message"
        )}
        acknowledgementText={t(
          "articles.article_approval_agreement.acknowledgement_text"
        )}
        confirmText={t("articles.article_approval_agreement.cta_confirm")}
        cancelText={t("articles.article_approval_agreement.cta_cancel")}
        errorText={error && t("articles.error_approve_article")}
        loading={submitting}
        onAccept={async () => {
          await onSubmit();
        }}
        onClose={onClose}
        open={open}
      />
    </SegmentPage>
  );
}

ApproveArticleModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  submitting: PropTypes.bool,
  error: PropTypes.string,
  onSubmit: PropTypes.func,
};

ApproveArticleModal.defaultProps = {
  open: false,
  onClose: () => {},
  submitting: false,
  error: "",
  onSubmit: () => {},
};
