import crossFetch from "cross-fetch";

let managedFetch = crossFetch;

/**
 * All front-end components calling platform REST
 * MUST obtain fetch from this file like:
 * import fetch from "../fetch-manager";
 *
 * The GraphQL HttpLink also uses this!
 */
export default function fetch(...args) {
  return managedFetch(...args);
}

/**
 * This is used by React Native to provide a wrapped version of fetch()
 * which handles auth token management and long-term token storage
 *
 * Browser code uses cookies and is able to use the default implementation
 * @param {Fetch} fetch
 */
export function setFetch(customFetch) {
  managedFetch = customFetch;
}

export async function handleMsgFromServer(res) {
  const body = await res.json();
  if (!res.ok) {
    const err = new Error(`FetchError: HTTP ${res.status}`);
    err.response = res;
    err.response.data = body;
    throw err;
  }
  return body;
}
