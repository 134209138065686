import PropTypes from "prop-types";
import React from "react";
import { Message, Modal, Transition } from "semantic-ui-react";

import Acknowledge from "../../../components/acknowledgement/index.web";

function AgreementModal({
  onClose,
  onAccept,
  loading,
  confirmText,
  headerText,
  bodyText,
  boldBodyText,
  errorText,
  acknowledgementText,
  checked,
  ...modalProps
}) {
  return (
    <Modal onClose={onClose} closeIcon {...modalProps}>
      <Modal.Header>{headerText}</Modal.Header>
      <Modal.Content scrolling>
        <p style={{ whiteSpace: "break-spaces" }}>
          {bodyText} {boldBodyText && <strong>{boldBodyText}</strong>}
        </p>
      </Modal.Content>
      <Modal.Actions>
        {errorText && (
          <Transition animation="shake" duration="300" transitionOnMount>
            <Message
              negative
              style={{ textAlign: "left" }}
              content={errorText}
            />
          </Transition>
        )}
        {acknowledgementText && (
          <Acknowledge
            acknowledgeText={acknowledgementText}
            onAccept={onAccept}
            loading={loading}
            confirmText={confirmText}
            checked={checked}
          />
        )}
      </Modal.Actions>
    </Modal>
  );
}

export default AgreementModal;

AgreementModal.propTypes = {
  onClose: PropTypes.func,
  onAccept: PropTypes.func,
  loading: PropTypes.bool,
  confirmText: PropTypes.string,
  headerText: PropTypes.string,
  bodyText: PropTypes.string,
  boldBodyText: PropTypes.string,
  errorText: PropTypes.string,
  acknowledgementText: PropTypes.string,
  checked: PropTypes.bool,
};

AgreementModal.defaultProps = {
  onClose: () => {},
  onAccept: () => {},
  loading: false,
  confirmText: "Okay",
  headerText: "",
  bodyText: "",
  boldBodyText: "",
  errorText: "",
  acknowledgementText: "",
  checked: false,
};
