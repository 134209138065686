const colors = {
  white: "#FFFFFF",
  black: "#000000",
  grayDarkest: "#1A1A1A",
  grayMedium: "#5E5E5E",
  grayBase: "#929292",
  silverDark: "#C8C7CC",
  silverBase: "#E5E5E5",
  silverLight: "#FBFBFB",
  blueBase: "#1499C3",
  redBase: "#dfb3b4",
  redLight: "#fff5f5",
  redInputError: "#e8bfbf",
  redText: "#DB2828", // Figma Red
};

// Would be nice to put these into CSS variables for use in stylesheets

export default {
  ...colors,
};
