import { t } from "localizify";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Menu, Segment } from "semantic-ui-react";

import {
  SETTINGS_BILLING,
  SETTINGS_HOME,
  SETTINGS_NOTIFICATIONS,
  SETTINGS_TEAM,
  linkTo,
} from "../routes-links";

export function getActiveTabName(location) {
  switch (location.pathname) {
    case SETTINGS_HOME:
      return t("settings.subsections.security");
    case SETTINGS_TEAM:
      return t("settings.subsections.team");
    case SETTINGS_BILLING:
      return t("settings.subsections.billing");
    case SETTINGS_NOTIFICATIONS:
      return t("settings.subsections.notifications");
    default:
      return t("settings.subsections.security");
  }
}

function SettingsMenu() {
  const location = useLocation();
  return (
    <Segment className="settings-menu-container">
      <Menu
        secondary
        vertical
        style={{ position: "relative", width: "100%" }}
        data-ui-container="settings-menu"
      >
        <Menu.Item
          as={NavLink}
          exact
          to={linkTo.security()}
          name="security"
          active={location.pathname === SETTINGS_HOME}
        >
          {t("settings.subsections.security")}
        </Menu.Item>
        <Menu.Item
          as={NavLink}
          to={linkTo.team()}
          name="team"
          active={location.pathname === SETTINGS_TEAM}
        >
          {t("settings.subsections.team")}
        </Menu.Item>
        <Menu.Item
          as={NavLink}
          to={linkTo.billing()}
          name="billing"
          active={location.pathname === SETTINGS_BILLING}
        >
          {t("settings.subsections.billing")}
        </Menu.Item>
      </Menu>
    </Segment>
  );
}

export default SettingsMenu;
