import { defaults, find, remove } from "lodash";

import generateUuid from "../../../lib/uuid";
import { GET_ALERTS } from "../../client-schema";

export default {
  Mutation: {
    alert(root, variables, { cache }) {
      const newAlert = defaults(variables, {
        id: generateUuid(),
        __typename: "Alert",
        ttl: 5000,
        title: "",
      });

      const { alerts } = cache.readQuery({ query: GET_ALERTS });
      const data = { alerts: [...alerts, newAlert] };
      cache.writeData({ data });
      return null;
    },

    dismissAlert(root, { id }, { cache }) {
      const { alerts } = cache.readQuery({ query: GET_ALERTS });
      const alertToRemove = find(alerts, { id });
      if (!alertToRemove) {
        // no alert by this id found
        return null;
      }
      remove(alerts, { id });
      cache.writeData({ data: { alerts } });
      return null;
    },
  },
};
