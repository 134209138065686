import React, { lazy } from "react";
import { useLocation } from "react-router";
import { Redirect, Route } from "react-router-dom";

import useIdentity, { ADMIN, getAuthRedirectWeb } from "../../lib/use-identity";
import { linkTo as authLinkTo } from "../auth/routes-links";
import { ADMIN as ADMIN_PATH, linkTo as adminLinkTo } from "./routes-links";

const AdminLazy = lazy(() =>
  import(/* webpackChunkName: "admin" */ "./admin-routes.web")
);

const AdminProtectedRoutes = () => {
  const { hasRole, communityCode } = useIdentity();
  const { pathname } = useLocation();

  if (!hasRole(ADMIN)) {
    return <Redirect to={getAuthRedirectWeb(authLinkTo.loginAdmin())} />;
  }

  // Always forward to the sites list if on the admin site
  if (communityCode === "admin" && pathname.indexOf(ADMIN_PATH) === 0) {
    return <Redirect to={adminLinkTo.sites()} />;
  }

  return <AdminLazy />;
};

export default <Route path={ADMIN_PATH} component={AdminProtectedRoutes} />;
