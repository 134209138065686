import { get, pickBy } from "lodash";

import prefixUrl from "../public-path";

export default function trackChargifyJsError({
  error,
  userId,
  category,
  email,
  value,
}) {
  let errorMsg = error;

  if (error.networkError) {
    errorMsg = get(error, "networkError.response.data.message");
  }

  if (error.errors) {
    errorMsg = Array.isArray(error.errors)
      ? error.errors.join(" ")
      : error.errors;
  }

  const uri = prefixUrl("/rest/chargifyJsError");
  const payload = pickBy({
    error: errorMsg,
    userId,
    category,
    email,
    value,
    uri: window.location.href,
  });
  // Sends to Segment through svc-payment
  fetch(uri, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: { "Content-Type": "application/json" },
  }).catch(err => {
    console.log("Error calling ", uri);
    console.log(payload);
    console.log(err);
  });

  if (errorMsg) {
    const logClientPaymentErrorPayload = {
      errorMsg,
      email,
      uri: window.location.href,
    };

    // Sends to GCP logs through svc-community
    fetch("/rest/get-started/logClientPaymentError", {
      method: "POST",
      body: JSON.stringify(logClientPaymentErrorPayload),
      headers: { "Content-Type": "application/json" },
    }).catch(err => {
      console.log("Error calling /rest/get-started/logClientPaymentError");
      console.log(logClientPaymentErrorPayload);
      console.log(err);
    });
  }
}
