import { t } from "localizify";
import _ from "lodash";
import moment from "moment";

const EMPTY_PLACEHOLDER = " - ";

export function completeTime(timestamp) {
  if (!timestamp) return EMPTY_PLACEHOLDER;
  return moment(timestamp).format(t("app.relative_timestamp_long"));
}

function relativeTime(
  timestamp,
  capitalize,
  compact,
  threshold = { unit: "days", quantity: 21 },
  showYear,
  round
) {
  if (!timestamp) return EMPTY_PLACEHOLDER;
  if (compact) {
    let relativeTimestamp = moment(timestamp).fromNow(true);
    const lastWeek = moment().subtract(7, "days");
    const lastYear = moment().subtract(1, "years");

    if (relativeTimestamp === "a few seconds") {
      relativeTimestamp = t("app.relative_timestamp_now");
    } else if (moment(timestamp).isSameOrBefore(lastYear, "year")) {
      relativeTimestamp = moment(timestamp).format("D MMM YYYY");
    } else if (moment(timestamp).isSameOrBefore(lastWeek, "day")) {
      relativeTimestamp = moment(timestamp).format("D MMM");
    } else {
      const splitTimeStamp = relativeTimestamp.split(" ");
      const fullSuffix = splitTimeStamp[1];
      if (splitTimeStamp[0] === "an" || splitTimeStamp[0] === "a") {
        splitTimeStamp[0] = 1;
      }
      relativeTimestamp = `${splitTimeStamp[0]}${fullSuffix[0]}`;
    }

    if (capitalize) {
      relativeTimestamp = _.upperFirst(relativeTimestamp);
    }
    return relativeTimestamp;
  }

  const secondsDiff =
    (new Date().getTime() - new Date(timestamp).getTime()) / 1000;

  let relativeTimestamp = "";
  if (Math.abs(secondsDiff) <= 30) {
    relativeTimestamp = t("app.relative_timestamp_now");
  } else {
    relativeTimestamp = moment(timestamp).fromNow();
    const timestampGreaterThanYear =
      Math.abs(moment().diff(timestamp, "year", true)) >= 1;
    const timestampInPast = moment() - moment(timestamp) > 0;
    const dateFormat =
      timestampGreaterThanYear || (timestampInPast && showYear)
        ? "MMM Do, YYYY"
        : "MMM Do";

    let timestampDiff = Math.abs(
      moment().diff(timestamp, threshold.unit, true)
    );

    if (round) {
      timestampDiff = Math.round(timestampDiff);
    }

    if (threshold && timestampDiff >= threshold.quantity) {
      relativeTimestamp = moment(timestamp).format(dateFormat);
    }
  }

  if (capitalize) {
    relativeTimestamp = _.upperFirst(relativeTimestamp);
  }

  return relativeTimestamp;
}

export default relativeTime;
