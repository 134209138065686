import "./style.css";

import { t } from "localizify";
import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "semantic-ui-react";

import { TrackButton } from "../../../components/segment/track";
import { Guidelines } from "../guidelines/guidelines.web";

export function GuidelinesModal({ isOpen, handleClose }) {
  const history = useHistory();
  return (
    <Modal open={isOpen} className="article-guidelines-modal">
      <Modal.Header className="article-guidelines-modal-header">
        {t("articles.dashboard.guidelines_modal_title")}
      </Modal.Header>
      <Modal.Content scrolling>
        <Guidelines />
      </Modal.Content>
      <Modal.Actions>
        <TrackButton
          className="article-guidelines-modal-button"
          eventLabel={t("articles.dashboard.got_it")}
          onClick={() => {
            handleClose();
            history.goBack();
          }}
        >
          {t("articles.dashboard.got_it")}
        </TrackButton>
      </Modal.Actions>
    </Modal>
  );
}

GuidelinesModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};

GuidelinesModal.defaultProps = {
  isOpen: false,
  handleClose: () => {},
};

export default GuidelinesModal;
