import { get } from "lodash";
import React from "react";

import useCommunity from "../../notification-setting/hooks";

const AppLinks = () => {
  const { config } = useCommunity();
  const appStoreLink = get(config, "appStoreLink") || "";
  const playStoreLink = get(config, "playStoreLink") || "";

  return (
    <span>
      Please download the app (
      {appStoreLink ? (
        <span>
          <a href={appStoreLink} target="_blank" rel="noopener noreferrer">
            iOS
          </a>{" "}
          or{" "}
        </span>
      ) : (
        ""
      )}
      {playStoreLink ? (
        <a href={playStoreLink} target="_blank" rel="noopener noreferrer">
          Android
        </a>
      ) : (
        ""
      )}
      ) or enable push notification permissions for the app on your device.
    </span>
  );
};

export default AppLinks;
