import React from "react";
import { Route } from "react-router-dom";

import ErrorBoundary from "../../../components/error-boundary/error-boundary.web";
import TitleWrapper from "../../../components/title-wrapper/index.web";
import { namespacedT } from "../../../lib/i18n";
import { DUNNING_URL } from "../routes-links";
import DunningMember from "./index.web";

const t = namespacedT("payment.dunning_member");

export default (
  <Route exact path={DUNNING_URL}>
    <TitleWrapper title={t("page_title")}>
      <ErrorBoundary title="Error" message="An Error has occurred">
        <DunningMember />
      </ErrorBoundary>
    </TitleWrapper>
  </Route>
);
