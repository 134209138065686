import PropTypes from "prop-types";
import { useLayoutEffect } from "react";

import setTitle from "../../lib/webpage";

function TitleWrapper({ title, children }) {
  useLayoutEffect(() => {
    setTitle(title);
  }, [title]);
  return children;
}

export default TitleWrapper;

TitleWrapper.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

TitleWrapper.defaultProps = {
  title: "",
  children: null,
};
