import "../styles.css";

import PropTypes from "prop-types";
import React from "react";
import { Header } from "semantic-ui-react";

import AnimatedCheck from "../../../../components/animated-check/index.web";
import { TrackLink } from "../../../../components/segment/track";
import colorsTheme from "../../../../lib/colors";
import { namespacedT } from "../../../../lib/i18n";
import formatRenewalDate from "../utils";

function SuccessView({ showNoBalanceDue, renewalDate, brandName }) {
  const t = namespacedT("payment.dunning_member");

  return (
    <div className="success-container">
      <div className="check-container">
        <AnimatedCheck />
      </div>

      <div className="success-container-content">
        <div>
          <Header
            as="h2"
            align="center"
            style={{ color: colorsTheme.black, marginBottom: 0 }}
          >
            {showNoBalanceDue ? t("no_balance_due") : t("thank_you")}
          </Header>
          {!showNoBalanceDue && (
            <i style={{ color: colorsTheme.grayMedium }}>
              {t("membership_expires_on", {
                expirationDate: formatRenewalDate(renewalDate),
              })}
            </i>
          )}
        </div>
        <TrackLink
          to={"/"}
          style={{ color: colorsTheme.black, fontWeight: "bold" }}
          eventType="LinkClick"
          eventLabel={t("log_in_cta", { brandName })}
        >
          {t("log_in_cta", { brandName })}
        </TrackLink>
        {!showNoBalanceDue && (
          <TrackLink
            to={"/settings/billing"}
            style={{ color: colorsTheme.grayBase }}
            eventLabel={t("download_statement_cta")}
          >
            {t("download_statement_cta")}
          </TrackLink>
        )}
      </div>
    </div>
  );
}

SuccessView.propTypes = {
  showNoBalanceDue: PropTypes.string.isRequired,
  renewalDate: PropTypes.string.isRequired,
  brandName: PropTypes.string.isRequired,
};

export default SuccessView;
