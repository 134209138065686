import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Popup } from "semantic-ui-react";

import relativeTime, { completeTime } from "../../lib/relative-time";

export default function RelativeTime({
  timestamp,
  capitalize,
  threshold,
  compact,
  tooltip,
  showYear,
  round,
  style,
  ...rest
}) {
  const formatTimestamp = useCallback(
    () =>
      relativeTime(timestamp, capitalize, compact, threshold, showYear, round),
    [timestamp, capitalize, compact, threshold, showYear, round]
  );

  const [formattedTimestamp, setFormattedTimestamp] = useState(
    formatTimestamp()
  );
  const intervalRef = useRef();

  const hoursRemaining = Math.abs(moment().diff(timestamp, "hour", true));

  // Updates the shown relative time if the timestamp prop changes
  useEffect(() => {
    setFormattedTimestamp(formatTimestamp());
  }, [timestamp, formatTimestamp]);

  useEffect(() => {
    const clearIntervalRef = () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = undefined;
      }
    };
    const setIntervalRef = ms => {
      intervalRef.current = setInterval(
        () => setFormattedTimestamp(formatTimestamp()),
        ms
      );
    };

    clearIntervalRef();

    if (hoursRemaining > 0) {
      if (hoursRemaining < 1) {
        setIntervalRef(60 * 1000);
      } else if (hoursRemaining < 3) {
        setIntervalRef(60 * 60 * 1000);
      }
    }
    return clearIntervalRef;
  }, [hoursRemaining, intervalRef, formatTimestamp, setFormattedTimestamp]);

  const content = <span style={style}>{formattedTimestamp}</span>;

  if (tooltip) {
    return (
      <Popup {...rest} content={completeTime(timestamp)} trigger={content} />
    );
  }

  return content;
}

RelativeTime.propTypes = {
  timestamp: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  capitalize: PropTypes.bool,
  compact: PropTypes.bool,
  threshold: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      unit: PropTypes.string,
      quantity: PropTypes.number,
    }),
  ]),
  tooltip: PropTypes.bool,
  showYear: PropTypes.bool,
  style: PropTypes.shape({}),
};

RelativeTime.defaultProps = {
  timestamp: "",
  capitalize: false,
  compact: false,
  threshold: {
    unit: "days",
    quantity: 21,
  },
  tooltip: false,
  showYear: false,
  style: {},
};
