import gql from "graphql-tag";
import PropTypes from "prop-types";
import React from "react";
import { useMutation } from "react-apollo";
import { Button, Form, Message, Modal } from "semantic-ui-react";

import PhoneNumberInput from "../../../../../components/phone-number-input/index.web";
import SegmentPage from "../../../../../components/segment/page";
import { namespacedT } from "../../../../../lib/i18n";
import useForm from "../../../../../lib/use-form";
import { USER_PHONE_FRAGMENT } from "../graphql";
import schema from "./schema";

const tSettings = namespacedT("settings");

const MY_ACCOUNT_MOBILE_PHONE = gql`
  mutation myAccount($input: UserAccountInput!) {
    myAccount(input: $input) {
      mobilePhone {
        ...UserPhone_Fragment
      }
    }
  }
  ${USER_PHONE_FRAGMENT}
`;

const PhoneNumberModal = ({ open, onClose, mobilePhone }) => {
  const defaultValues = {
    mobilePhone: {
      countryCode: mobilePhone.countryCode,
      number: mobilePhone.number,
    },
  };

  const [editMobilePhoneNumber, { loading: saving, error }] = useMutation(
    MY_ACCOUNT_MOBILE_PHONE,
    {
      onCompleted: onClose,
    }
  );

  function handleEditMobilePhoneNumber(values) {
    editMobilePhoneNumber({
      variables: { input: values },
    });
  }

  const { onSubmit, decorateInput } = useForm(
    defaultValues,
    schema,
    handleEditMobilePhoneNumber,
    saving
  );

  return (
    <SegmentPage view="SettingsMobile">
      <Modal style={{ maxWidth: 500 }} open={open}>
        <Modal.Header>
          {tSettings("modals.mobile_phone_number.edit")}
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field
              {...decorateInput("mobilePhone")}
              name="mobilePhone"
              control={PhoneNumberInput}
              label={tSettings("modals.mobile_phone_number.label")}
              placeholder={tSettings("modals.mobile_phone_number.label")}
              value={mobilePhone}
            />

            {error && (
              <Message className="error-label pull-right" color="red">
                {tSettings("modals.mobile_phone_number.error")}
              </Message>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button secondary onClick={onClose}>
            {tSettings("modals.close_button_label")}
          </Button>

          <Button
            data-ui-action="edit-mobile-phone-submit"
            primary
            onClick={onSubmit}
            disabled={saving}
            loading={saving}
          >
            {tSettings("modals.submit_button_label")}
          </Button>
        </Modal.Actions>
      </Modal>
    </SegmentPage>
  );
};

export default PhoneNumberModal;

PhoneNumberModal.defaultProps = {
  open: false,
  onClose: () => {},
  mobilePhone: {},
};

PhoneNumberModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  mobilePhone: PropTypes.shape({
    countryCode: PropTypes.string,
    number: PropTypes.string,
  }),
};
