import { get } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Accordion, Icon, Message } from "semantic-ui-react";

class ErrorBoundary extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  static setError({ error, message }) {
    this._instance.setState({ hasError: true, error, message });
  }

  constructor(props) {
    if (ErrorBoundary._instance) {
      return ErrorBoundary._instance;
    }
    super(props);
    const error = get(props, "error");
    this.state = {
      hasError: !!error,
      error,
      expanded: false,
      message: get(props, "message"),
    };
    // Only show the trace detail when in dev mode
    this.showTrace = process.env.NODE_ENV !== "production";
    this.handleClick = this.handleClick.bind(this);
    ErrorBoundary._instance = this;
  }

  static _instance = null;

  componentDidCatch() {
    // TODO: Log to error service
  }

  handleClick() {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  }

  render() {
    const { title, message } = this.props;
    const { expanded, error = {}, message: messageOverride } = this.state;
    const { stack } = error;

    return (
      <div>
        {this.state.hasError && (
          <Message negative size="tiny" floating>
            <Message.Header>{title}</Message.Header>
            {messageOverride || message}

            {this.showTrace && (
              <Message.Content>
                <br />
                <Accordion>
                  <Accordion.Title
                    active={expanded}
                    index={0}
                    onClick={this.handleClick}
                  >
                    <Icon name="dropdown" />
                    Show Error
                  </Accordion.Title>
                  <Accordion.Content active={expanded}>
                    <p style={{ whiteSpace: "pre-wrap", overflow: "auto" }}>
                      {stack}
                    </p>
                  </Accordion.Content>
                </Accordion>
              </Message.Content>
            )}
          </Message>
        )}
        {this.props.children}
      </div>
    );
  }
}

export default ErrorBoundary;

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
};

ErrorBoundary.defaultProps = {
  title: "Something went wrong",
  message: "",
};
