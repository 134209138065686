export const EXPERT_PANELS_HOME = "/expert-panels";
export const EXPERT_PANELS_QUESTION = `${EXPERT_PANELS_HOME}`;
export const NATIVE_EXPERT_PANELS_HOME = "ExpertPanel";
export const NATIVE_EXPERT_PANELS_EXISTING_ANSWERS = "ExistingExpertAnswers";

export const linkTo = {
  expert_panels: () => EXPERT_PANELS_HOME,
  question: id => `${EXPERT_PANELS_QUESTION}/${id}`,
};

export const segmentCategories = {
  [EXPERT_PANELS_HOME]: "EPList",
};
