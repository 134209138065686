import React from "react";
import { Message } from "semantic-ui-react";

import prefixUrl from "../../public-path";
import Layout from "../layout-one-column-narrow/index.web";
import Link from "../link/index.web";
import TitleWrapper from "../title-wrapper/index.web";

export default () => (
  <TitleWrapper title="404">
    <Layout>
      <Message warning compact>
        <Message.Header>Page Not Found</Message.Header>
        <Message.Content>
          The page you are trying to reach can&#39;t be found.
        </Message.Content>
      </Message>

      <Link href={prefixUrl("/")}>Back to Home</Link>
    </Layout>
  </TitleWrapper>
);
