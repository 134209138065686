import gql from "graphql-tag";

export const STATEMENTS_FRAGMENT = gql`
  fragment Statements_Fragment on Statement {
    id
    name
    purchasedAt
    amount
    statementUrl
  }
`;

export default gql`
  query {
    me {
      id
    }
    statements {
      ...Statements_Fragment
    }
  }
  ${STATEMENTS_FRAGMENT}
`;
