import { pick } from "lodash";
import { useMutation } from "react-apollo";

import { ASSISTANT_TYPE } from "../../../components/team-member-toggle/index.web";
import {
  MY_ASSISTANT,
  MY_ASSISTANT_DELETE,
  MY_PUBLIC_RELATION,
  MY_PUBLIC_RELATIONS_DELETE,
} from "./graphql";

export const useMyTeamMemberContact = type => {
  let mutationString;
  switch (type) {
    case ASSISTANT_TYPE.PR:
      mutationString = MY_PUBLIC_RELATION;
      break;
    default:
      mutationString = MY_ASSISTANT;
  }

  const [mutate, { loading: saving }] = useMutation(mutationString);

  const onSave = async ({ name, mobilePhone, officePhone, email }) => {
    const { data: mutatedData } = await mutate({
      variables: {
        input: {
          name,
          mobilePhone: pick(mobilePhone, [
            "countryCode",
            "number",
            "extension",
          ]),
          officePhone: pick(officePhone, [
            "countryCode",
            "number",
            "extension",
          ]),
          email,
        },
      },
    });

    return mutatedData;
  };

  return {
    saving,
    onSave,
  };
};

export const useMyTeamMemberContactDelete = type => {
  let mutation;
  if (type === ASSISTANT_TYPE.EA) {
    mutation = MY_ASSISTANT_DELETE;
  } else if (type === ASSISTANT_TYPE.PR) {
    mutation = MY_PUBLIC_RELATIONS_DELETE;
  }
  const [mutate] = useMutation(mutation);
  return {
    handleDelete: mutate,
  };
};
