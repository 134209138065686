import "./style.css";

import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

export default function EnhancedLink({
  children,
  inherit,
  secondary,
  subtle,
  newTab,
  ...linkProps
}) {
  const classes = classnames({
    pointer: true,
    inherit,
    secondary,
    subtle,
  });

  if (!linkProps.to) {
    return (
      <a className={classes} {...linkProps}>
        {children}
      </a>
    );
  }

  if (linkProps.as === "a") {
    return (
      <a
        className={classes}
        href={linkProps.to}
        target="_blank"
        rel="noopener noreferrer"
        {...linkProps}
      >
        {children}
      </a>
    );
  }

  return (
    <Link className={classes} {...linkProps} target={newTab ? "_blank" : null}>
      {children}
    </Link>
  );
}

EnhancedLink.propTypes = {
  children: PropTypes.node.isRequired,
  inherit: PropTypes.bool,
  secondary: PropTypes.bool,
  subtle: PropTypes.bool,
  to: PropTypes.string,
  as: PropTypes.oneOf(["a"]),
  newTab: PropTypes.bool,
};

EnhancedLink.defaultProps = {
  inherit: false,
  secondary: false,
  subtle: false,
  to: null,
  as: null,
  newTab: false,
};
