import React, { useCallback, useEffect, useRef, useState } from "react";
import { Form, Header, Loader } from "semantic-ui-react";

import colorsTheme from "../../lib/colors";
import AcceptedCards from "./accepted-cards";
import {
  baseConfig,
  chargifyFormProps,
  chargifyFormPropsDefaults,
} from "./chargify-helpers";

const chargifyUrl = "https://js.chargify.com/latest/chargify.js";

const ChargifyForm = ({
  formConfig,
  acceptedCards,
  onTokenCreate,
  onTokenError,
  submitForm,
}) => {
  const formId = "chargify-form";

  const config = {
    // selector where the iframe will be included in the host's HTML (i.e. '#chargify-form')
    // optional if you have a `selector` on each and every field
    selector: `#${formId}`,

    ...baseConfig,

    // points to your Chargify site
    // serverHost: "https://acme.chargify.test",
    ...formConfig,
  };
  const chargify = useRef(new window.Chargify());

  const handleSubmit = useCallback(() => {
    chargify.current.token(
      document.getElementById(formId),
      onTokenCreate,
      onTokenError
    );
  }, [onTokenCreate, onTokenError]);

  useEffect(() => {
    chargify.current.load(config);

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    chargify.current.load(config);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      chargify.current.unload();
    };
    // Since this is a ref instance outside of react things
    // can get a little wonky (clear form, reload iframes, etc) if
    // you always pass new config
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargify]);

  useEffect(() => {
    if (submitForm) {
      handleSubmit();
    }
  }, [submitForm, handleSubmit]);

  return (
    <Form id={formId} name={formId} style={{ margin: "0 auto" }}>
      <div className="form-header">
        <div>
          <Header as="h4" style={{ margin: 0, fontSize: 16 }}>
            Credit Card Information
          </Header>
          <i style={{ color: colorsTheme.grayMedium }}>
            All transactions are secure and encrypted
          </i>
        </div>
        <AcceptedCards cards={acceptedCards} limit={3} />
      </div>
      <div className="cc-form-wrapper">
        <Form.Group style={{ marginBottom: 0 }}>
          <Form.Input required name="firstName">
            <div
              required
              id="chargify-first-name"
              className="ui input required"
            />
          </Form.Input>
          <Form.Input name="lastName">
            <div id="chargify-last-name" className="ui input" />
          </Form.Input>
        </Form.Group>
        <Form.Group style={{ marginBottom: 0 }}>
          <Form.Input name="ccNumber">
            <div id="chargify-card" className="ui input" />
          </Form.Input>
          <Form.Input name="exp">
            <div id="chargify-month" />
          </Form.Input>
        </Form.Group>
      </div>
      <input type="submit" id="chargify-submit-form" hidden />
    </Form>
  );
};

ChargifyForm.propTypes = chargifyFormProps;
ChargifyForm.deafultProps = chargifyFormPropsDefaults;

function ChargifyFormWithLoader({
  paymentType,
  formConfig,
  acceptedCards,
  onTokenCreate,
  onTokenError,
  submitForm,
}) {
  // Keeps track of whether or not our script tag has officialy been loaded and ready
  const [chargifyScriptLoaded, setChargifyScriptLoaded] = useState(false);

  const [error, setError] = useState();

  useEffect(() => {
    // Try to find if the script tag currently exists
    let chargifyScriptTag = document.querySelector(
      `script[src="${chargifyUrl}"`
    );

    if (!chargifyScriptTag) {
      // No script tag found so lets go ahead and place one on the DOM
      chargifyScriptTag = document.createElement("script");
      chargifyScriptTag.setAttribute("src", chargifyUrl);
      chargifyScriptTag.setAttribute("SameSite", "None");
      chargifyScriptTag.setAttribute("Secure", "");
      chargifyScriptTag.addEventListener("load", () => {
        setChargifyScriptLoaded(true);
      });
      chargifyScriptTag.addEventListener("error", err => {
        setChargifyScriptLoaded(true);
        setError(err);
      });
      document.head.appendChild(chargifyScriptTag);
    } else {
      setChargifyScriptLoaded(true);
    }
  }, []);

  if (!chargifyScriptLoaded) return <Loader active inline="centered" />;
  if (error) return <div>It looks like we encountered an error</div>;

  return (
    <ChargifyForm
      paymentType={paymentType}
      formConfig={formConfig}
      acceptedCards={acceptedCards}
      onTokenCreate={onTokenCreate}
      onTokenError={onTokenError}
      submitForm={submitForm}
    />
  );
}

ChargifyFormWithLoader.propTypes = chargifyFormProps;

ChargifyFormWithLoader.defaultProps = chargifyFormPropsDefaults;

export default ChargifyFormWithLoader;
