import React from "react";
import { Button, Grid, Message, Table } from "semantic-ui-react";

import Link from "../../../../components/link/index.web";
import SectionHeader from "../../../../components/section-header/index.web";
import colors from "../../../../lib/colors";
import { formatShortMonthDate } from "../../../../lib/date";
import { localizedCurrency, namespacedT } from "../../../../lib/i18n";
import useIdentity from "../../../../lib/use-identity";
import { PaymentContainerWiredMember } from "../../payment/card-container/index.web";
import useGetStatements from "./hooks";

const tSettings = namespacedT("settings");
const tStatements = namespacedT("settings.statements");

export default function SubsectionBilling() {
  const { statements, error, loading } = useGetStatements();
  const { userId } = useIdentity();
  const showCards = false; // TODO: make cards show with useQueryParams based on '?cards=show' after Chargify delayed webhook requests are fixed

  function renderPaymentSection() {
    if (showCards) {
      return (
        <>
          <PaymentContainerWiredMember userId={userId} />
          <div style={{ marginTop: "20px" }}>
            <SectionHeader title={tSettings("subsections.statements")} />
          </div>
        </>
      );
    }

    return null;
  }

  return (
    <Grid.Row>
      <Grid.Column>
        <SectionHeader
          title={
            showCards
              ? tSettings("subsections.payment")
              : tSettings("subsections.statements")
          }
        />
        {renderPaymentSection()}
        {error && <Message negative>{error.message}</Message>}
        {loading && !error && (
          <Message
            content={tStatements("text_loading")}
            style={{ textAlign: "center" }}
          />
        )}
        {!loading && !error && statements.length === 0 && (
          <Message
            content={tStatements("text_no_statements")}
            style={{ textAlign: "center" }}
          />
        )}
        <Table basic="very">
          <Table.Body>
            {statements.map(statement => {
              return (
                <Table.Row key={statement.id} data-ui-container="statement-row">
                  <Table.Cell style={{ color: colors.grayMedium }}>
                    {formatShortMonthDate(statement.purchasedAt)}
                    <div
                      style={{
                        fontSize: "1.2em",
                        marginTop: 3,
                      }}
                    >
                      {localizedCurrency(statement.amount)}
                    </div>
                  </Table.Cell>
                  <Table.Cell style={{ fontWeight: "bold" }}>
                    {statement.name}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Button
                      data-ui-action="download-statement-link"
                      primary
                      as={Link}
                      href={statement.statementUrl}
                      target="_blank"
                      content={tStatements("button_download")}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Grid.Column>
    </Grid.Row>
  );
}
