import gql from "graphql-tag";
import React from "react";
import { Query } from "react-apollo";

import { DISMISS_ALERT } from "../../graphql/client-schema";

export const GET_ALERTS = gql`
  {
    alerts @client {
      id
      message
      title
      displayType
      ttl
    }
  }
`;

export const alertsQuery = Component => ownProps => (
  <Query query={GET_ALERTS}>
    {({ data, client }) => {
      const dismiss = alertId => {
        client.mutate({
          mutation: DISMISS_ALERT,
          variables: { alertId },
        });
      };

      return <Component {...ownProps} alerts={data.alerts} dismiss={dismiss} />;
    }}
  </Query>
);
