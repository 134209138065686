import { t } from "localizify";
import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";
import sanitizeHtml from "sanitize-html";
import { Label } from "semantic-ui-react";

import wordCount from "../../lib/wordCount";

// TinyMCE returns the following entities
// other HTML entities are normal UTF8 encoded
const entities = {
  gt: ">",
  lt: "<",
  amp: "&",
};
function fixEntities(str) {
  return str.replace(/&(gt|lt|amp);/gi, (_, m) => entities[m] || m);
}

function InputCounterLabel({
  className,
  string,
  countWords,
  min,
  max,
  onInputValidated,
  plain,
}) {
  const htmlStrippedString = sanitizeHtml(string, {
    allowedTags: [],
    allowedAttributes: {},
  });
  const count = countWords
    ? wordCount(htmlStrippedString)
    : fixEntities(htmlStrippedString).length;
  const countType = countWords
    ? t("app.label_words")
    : t("app.label_characters");
  const minValid = count >= min;
  const maxValid = max ? count <= max : true;

  const isValid = minValid && maxValid;
  useEffect(() => {
    onInputValidated(isValid);
  }, [isValid, onInputValidated]);

  function renderCounterText() {
    return (
      <Fragment>
        {count}
        {max && `/${max}`} {countType}{" "}
        <span style={{ opacity: "0.6", paddingLeft: "3px" }}>
          {min !== 0 && `(${min} ${t("app.label_minimum")})`}
        </span>
      </Fragment>
    );
  }

  if (plain) {
    const classes = [className];
    if (!maxValid) classes.push("max-invalid");
    if (!minValid) classes.push("min-invalid");

    return <p className={classes.join(" ")}>{renderCounterText()}</p>;
  }

  return (
    <Label
      color={
        (string && string.length > 0 && !minValid) || !maxValid
          ? "red"
          : undefined
      }
    >
      {renderCounterText()}
    </Label>
  );
}

export default InputCounterLabel;

InputCounterLabel.propTypes = {
  className: PropTypes.string,
  string: PropTypes.string,
  countWords: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  onInputValidated: PropTypes.func,
  plain: PropTypes.bool,
};

InputCounterLabel.defaultProps = {
  className: "",
  string: "",
  countWords: false,
  min: 0,
  max: undefined,
  onInputValidated: () => {},
  plain: false,
};
