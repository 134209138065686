import PropTypes from "prop-types";
import React from "react";
import { Container, Message } from "semantic-ui-react";

function Alert({ onDismiss, header, content, ttl, type }) {
  const alertStyle = {
    borderRadius: 0,
  };

  const getHeader = () => {
    if (!header) {
      return null;
    }

    return <Message.Header>{header}</Message.Header>;
  };

  if (ttl) {
    setTimeout(() => onDismiss(), ttl);
  }

  return (
    <Message
      info={type === "info"}
      negative={type === "negative"}
      onDismiss={onDismiss}
      positive={type === "positive"}
      style={alertStyle}
      warning={type === "warning"}
    >
      <Container>
        {getHeader()}
        <p>{content}</p>
      </Container>
    </Message>
  );
}

Alert.propTypes = {
  content: PropTypes.node.isRequired,
  header: PropTypes.node.isRequired,
  onDismiss: PropTypes.func.isRequired,
  ttl: PropTypes.number,
  type: PropTypes.string,
};

Alert.defaultProps = {
  ttl: 1000,
  type: "",
};

export default function Alerts({ alerts, dismiss, float }) {
  const wrapperStyle = {
    left: 0,
    position: "fixed",
    right: 0,
    zIndex: 500,
  };
  if (float) wrapperStyle.top = 0;

  return (
    <div style={wrapperStyle} id="app-alerts">
      {alerts.map(({ displayType, message, title, ttl, id }) => (
        <Alert
          key={id}
          header={title}
          content={message}
          type={displayType}
          ttl={ttl}
          onDismiss={() => dismiss(id)}
        />
      ))}
    </div>
  );
}

Alerts.propTypes = {
  alerts: PropTypes.arrayOf(
    PropTypes.shape({
      displayType: PropTypes.string,
      id: PropTypes.string,
      message: PropTypes.node,
      title: PropTypes.node,
      ttl: PropTypes.number,
    })
  ),
  dismiss: PropTypes.func.isRequired,
  float: PropTypes.bool,
};

Alerts.defaultProps = {
  alerts: [],
  float: false,
};
