import gql from "graphql-tag";
import { get } from "lodash";
import { useMutation, useQuery } from "react-apollo";

export const REQUEST_SUBSCRIPTION_BALANCE = gql`
  query subscriptionBalance($userId: String!, $subscriptionId: String!) {
    subscriptionBalance(
      input: { userId: $userId, subscriptionId: $subscriptionId }
    ) @client {
      memberName
      outstandingBalance
      showNoBalanceDue
      renewalDate
      publicKey
      serverHost
    }
  }
`;

export const PAY_SUBSCRIPTION = gql`
  mutation paySubscriptionByToken($input: AddSavedPaymentInput!) {
    paySubscriptionByToken(input: $input) @client {
      id
    }
  }
`;

export const GET_USER_DATA = gql`
  query {
    me {
      id
      name {
        fullName
      }
      subscription {
        outstandingBalance
      }
    }
  }
`;

export function useGetUserData({ userId, subscriptionId }) {
  const { data, loading, error } = useQuery(REQUEST_SUBSCRIPTION_BALANCE, {
    variables: {
      userId,
      subscriptionId,
    },
  });

  const {
    memberName,
    outstandingBalance,
    renewalDate,
    showNoBalanceDue,
    publicKey,
    serverHost,
  } = get(data, ["subscriptionBalance"]) || {};

  return {
    fullName: memberName,
    outstandingBalance,
    renewalDate,
    showNoBalanceDue,
    publicKey,
    serverHost,
    loading,
    error,
  };
}

export function usePaySubscriptionUnauthed(userId) {
  const [mutation, { error, loading }] = useMutation(PAY_SUBSCRIPTION);

  const addPaymentViaToken = token => {
    return mutation({
      variables: {
        input: {
          userId,
          token,
        },
      },
    });
  };

  return {
    addPaymentViaToken,
    error,
    loading,
  };
}

const GET_LOGIN_CONFIG = gql`
  {
    community @client {
      name
      conciergeEmail
      conciergePhone
      products {
        auth {
          config {
            slug
            backgroundImage
            logo
          }
        }
      }
    }
  }
`;

export function useQueryLoginConfig() {
  const { data, loading, error } = useQuery(GET_LOGIN_CONFIG);

  return {
    loading,
    error: get(error, "message"),
    backgroundImage: get(
      data,
      "community.products.auth.config.backgroundImage"
    ),
    logo: get(data, "community.products.auth.config.logo"),
    slug: get(data, "community.products.auth.config.slug"),
    name: get(data, "community.name"),
    conciergeEmail: get(data, "community.conciergeEmail"),
    conciergePhone: get(data, "community.conciergePhone"),
  };
}
