import gql from "graphql-tag";
import _ from "lodash";
import React from "react";
import { Mutation, Query } from "react-apollo";

export const ARTICLE_PRODUCT_AGREEMENT_STATUS = gql`
  query articleProductAgreementStatus {
    articleProductAgreementStatus {
      createdAt
    }
  }
`;

export const ARTICLE_PRODUCT_TERMS = gql`
  query articleProductTerms {
    articleProductTerms {
      terms
    }
  }
`;

export const ARTICLE_ACCEPT_PRODUCT_AGREEMENT = gql`
  mutation articleAcceptProductAgreement {
    articleAcceptProductAgreement
  }
`;

export const mutationArticleAcceptProductAgreement = Component => {
  return ownProps => (
    <Mutation mutation={ARTICLE_ACCEPT_PRODUCT_AGREEMENT}>
      {(mutate, { loading }) => {
        return <Component onAccept={mutate} loading={loading} {...ownProps} />;
      }}
    </Mutation>
  );
};

export const queryProductAgreementStatus = Component => {
  return ownProps => (
    <Query query={ARTICLE_PRODUCT_AGREEMENT_STATUS}>
      {({ loading, data }) => {
        const props = {
          loading,
          hasAccepted: !!_.get(data, "articleProductAgreementStatus.createdAt"),
          ...ownProps,
        };
        return <Component {...props} />;
      }}
    </Query>
  );
};

export const queryArticleProductTerms = Component => {
  return ownProps => (
    <Query query={ARTICLE_PRODUCT_TERMS}>
      {({ loading, data }) => {
        const props = {
          loading,
          terms: _.get(data, "articleProductTerms.terms"),
          ...ownProps,
        };
        return <Component {...props} />;
      }}
    </Query>
  );
};

export const queryAndAcceptProductTerms = Component => {
  return ownProps => (
    <Query query={ARTICLE_PRODUCT_TERMS}>
      {({ loading, data }) => {
        const props = {
          loading,
          terms: _.get(data, "articleProductTerms.terms"),
          ...ownProps,
        };

        return (
          <Mutation
            mutation={ARTICLE_ACCEPT_PRODUCT_AGREEMENT}
            update={cache => {
              cache.writeQuery({
                query: ARTICLE_PRODUCT_AGREEMENT_STATUS,
                data: {
                  articleProductAgreementStatus: {
                    createdAt: new Date().toISOString(),
                    __typename: "ArticleProductAgreementStatus",
                  },
                },
              });
            }}
          >
            {(mutate, { loading: acceptProductAgreementLoading }) => {
              return (
                <Component
                  acceptProductAgreement={mutate}
                  acceptProductAgreementLoading={acceptProductAgreementLoading}
                  {...props}
                />
              );
            }}
          </Mutation>
        );
      }}
    </Query>
  );
};
