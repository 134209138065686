import gql from "graphql-tag";
import { extend, first, get, map } from "lodash";
import React from "react";
import { Mutation, Query, useMutation } from "react-apollo";

import { USER } from "../../../graphql/client-schema";
import { relayUpdateQuery } from "../../../lib/graphql";

export const PUBLISHED_AND_PENDING_PUBLISHED_ARTICLES = gql`
  query published {
    articlesPublished {
      id
      author {
        id
      }
      editor {
        id
      }
      status
      publishedDate
      currentRevision {
        title
        id
      }
      articleUrl
    }
    articlesPendingPublication {
      id
      scheduledDate
      currentRevision {
        title
        id
      }
      articleUrl
    }
  }
`;

export const ARTICLE_STATUS_FRAGMENT = gql`
  fragment ArticleStatusFragment on Article {
    canView
    id
    status
    statusUpdatedAt
  }
`;

const SUBMIT_ARTICLE_FOR_REVIEW = gql`
  mutation articleSendForReview($input: ArticleRevisionInput!) {
    articleSendForReview(input: $input) {
      ...ArticleStatusFragment
    }
  }
  ${ARTICLE_STATUS_FRAGMENT}
`;

export const COPYLEAKS_SCAN_FRAGMENT = gql`
  fragment CopyLeaksScan_Fragment on CopyLeaksScan {
    id
    exportId
    status
    identicalWords
    minorChangedWords
    relatedMeaningWords
    aggregatedScore
    aiProbability
    createdAt
    updatedAt
    totalExcluded
    totalWords
  }
`;

export const ARTICLE_SCAN_CREATE = gql`
  mutation articleScanCreate($input: ArticleScanCreateInput!) {
    articleScanCreate(input: $input) {
      ...CopyLeaksScan_Fragment
    }
  }
  ${COPYLEAKS_SCAN_FRAGMENT}
`;

export const APPROVE_ARTICLE_FOR_PUBLICATION = gql`
  mutation articleApproveForPublishing($id: String!) {
    articleApproveForPublishing(id: $id) {
      ...ArticleStatusFragment
    }
  }
  ${ARTICLE_STATUS_FRAGMENT}
`;

const ARTICLE_REVISION = gql`
  mutation articleAutoSaveRevision($input: ArticleRevisionInput) {
    articleAutoSaveRevision(input: $input) {
      id
      currentRevision {
        id
        title
        content
        updatedAt
      }
    }
  }
`;

const ARTICLES_ACTIVE = gql`
  query articleInFlight {
    articleInFlight {
      id
      status
      currentRevision {
        id
        title
        content
        updatedAt
      }
      editor {
        id
      }
      canView
    }
    articlesPendingPublication {
      id
      status
      currentRevision {
        id
        title
      }
      scheduledDate
    }
    community @client {
      conciergeEmail
    }
  }
`;

export const ARTICLE_INFLIGHT = gql`
  query articleInFlight {
    articleInFlight {
      id
      status
      currentRevision {
        id
        title
        content
        updatedAt
      }
      editor {
        id
      }
    }
  }
`;

export const ARTICLE_CREATE = gql`
  mutation articleCreate {
    articleCreate {
      id
      status
    }
  }
`;

export const ARTICLE_FEEDBACK = gql`
  query articleFeedback($articleId: String!, $last: Int, $before: String) {
    articleFeedback(articleId: $articleId, last: $last, before: $before) {
      pageInfo {
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          remark
          createdAt
          hasRead
          author {
            id
            snapshot {
              id
              avatar
              name
            }
          }
          isPersisting @client
        }
      }
    }
  }
`;

const ARTICLE_FEEDBACK_CREATE = gql`
  mutation articleFeedbackCreate($articleId: String!, $feedback: String!) {
    articleFeedbackCreate(articleId: $articleId, feedback: $feedback) {
      id
      remark
      createdAt
      hasRead
      author {
        id
        snapshot {
          id
          avatar
          name
        }
      }
      isPersisting @client
    }
  }
`;

export const ArticlesActiveQuery = Component => {
  return ownProps => (
    <Query query={ARTICLES_ACTIVE} errorPolicy="ignore">
      {({ loading, error, data }) => {
        const props = {
          loading,
          error: error && error.message,
          articleId: get(data, "articleInFlight.id"),
          status: get(data, "articleInFlight.status"),
          revisionId: get(data, "articleInFlight.currentRevision.id"),
          revisionUpdatedAt:
            get(data, "articleInFlight.currentRevision.updatedAt") || "",
          title: get(data, "articleInFlight.currentRevision.title"),
          content: get(data, "articleInFlight.currentRevision.content") || "",
          conciergeEmail: get(data, "community.conciergeEmail"),
          editorId: get(data, "articleInFlight.editor.id"),
          articlesPendingPublication: get(data, "articlesPendingPublication"),
          canView: get(data, "articleInFlight.canView"),
          ...ownProps,
        };
        return <Component {...props} />;
      }}
    </Query>
  );
};

export const ArticleCreate = Component => {
  return ownProps => (
    <Mutation
      mutation={ARTICLE_CREATE}
      refetchQueries={[
        {
          query: ARTICLE_INFLIGHT,
        },
      ]}
    >
      {(mutate, { loading, error }) => {
        const onSubmit = async () => {
          const {
            data: {
              articleCreate: { id, status },
            },
          } = await mutate();
          return { id, status };
        };
        return (
          <Component
            onSubmit={onSubmit}
            loading={loading}
            error={error}
            {...ownProps}
          />
        );
      }}
    </Mutation>
  );
};

export const PublishedArticlesQuery = Component => {
  return ownProps => (
    <Query query={PUBLISHED_AND_PENDING_PUBLISHED_ARTICLES}>
      {({ loading, error, data = {} }) => {
        const props = {
          loading,
          error: error && error.message,
          articles: loading
            ? []
            : map(data.articlesPublished, article => ({
                id: article.id,
                title: article.currentRevision.title,
                publishedDate: article.publishedDate,
                articleUrl: article.articleUrl,
              })),
          articlesPendingPublication: loading
            ? []
            : map(data.articlesPendingPublication, article => ({
                id: article.id,
                title: article.currentRevision.title,
                articleUrl: article.articleUrl,
                scheduledDate: article.scheduledDate,
              })),
          ...ownProps,
        };
        return <Component {...props} />;
      }}
    </Query>
  );
};

export const useSubmitArticleForReview = () => {
  const [mutationFunc, { loading, error }] = useMutation(
    SUBMIT_ARTICLE_FOR_REVIEW
  );
  return {
    submitArticleForReview({ id, title, content }) {
      return mutationFunc({
        variables: {
          input: {
            articleId: id,
            title,
            content,
          },
        },
      });
    },
    error,
    loading,
  };
};

export function useArticleRevisionMutation(articleId, revisionId) {
  const [mutation, { error, loading }] = useMutation(ARTICLE_REVISION, {
    update: (cache, { data: articleRevision }) => {
      const { articleInFlight } = cache.readQuery({
        query: ARTICLE_INFLIGHT,
      });
      cache.writeQuery({
        query: ARTICLE_INFLIGHT,
        data: {
          articleInFlight: extend(articleInFlight, {
            currentRevision: {
              id: get(
                articleRevision,
                "articleAutoSaveRevision.currentRevision.id"
              ),
              title: get(
                articleRevision,
                "articleAutoSaveRevision.currentRevision.title"
              ),
              content: get(
                articleRevision,
                "articleAutoSaveRevision.currentRevision.content"
              ),
              updatedAt: get(
                articleRevision,
                "articleAutoSaveRevision.currentRevision.updatedAt"
              ),
              __typename: "ArticleRevision",
            },
          }),
        },
      });
    },
  });

  const onSubmit = (title, content) => {
    return mutation({
      optimisticResponse: {
        articleAutoSaveRevision: {
          id: articleId,
          currentRevision: {
            id: revisionId,
            title,
            content,
            updatedAt: new Date().toISOString(),
            __typename: "ArticleRevision",
          },
          __typename: "Article",
        },
      },
      variables: {
        input: {
          articleId,
          title,
          content,
        },
      },
    });
  };

  return {
    onSubmit,
    loading,
    error,
  };
}

export const approveArticleForPublication = Component => {
  return ownProps => (
    <Mutation mutation={APPROVE_ARTICLE_FOR_PUBLICATION}>
      {(mutate, { loading, error }) => {
        const onSubmit = articleId => {
          return mutate({
            variables: {
              id: articleId,
            },
          });
        };
        return (
          <Component
            onSubmit={onSubmit}
            loading={loading}
            error={error}
            {...ownProps}
          />
        );
      }}
    </Mutation>
  );
};

export const QueryMutateArticleFeedback = Component => {
  // eslint-disable-next-line react/prop-types
  return ({ articleId, ...ownProps }) => {
    const queryVariables = {
      articleId,
      last: 10,
    };

    return (
      <Query
        query={ARTICLE_FEEDBACK}
        variables={queryVariables}
        notifyOnNetworkStatusChange
      >
        {({ loading, error, fetchMore, data, networkStatus }) => {
          const fetchMoreFeedback = () => {
            fetchMore({
              updateQuery: relayUpdateQuery.bind(
                this,
                "articleFeedback",
                "prepend"
              ),
              variables: {
                before: first(data.articleFeedback.edges).cursor,
              },
            });
          };

          const props = {
            loading,
            error: networkStatus === 8 || (error && error.message),
            messages: get(data, "articleFeedback.edges", []).map(edge =>
              Object.assign({}, edge.node, {
                author: {
                  displayName: get(edge, "node.author.snapshot.name", ""),
                  avatar: get(edge, "node.author.snapshot.avatar", ""),
                },
              })
            ),
            hasPreviousPage: get(
              data,
              "articleFeedback.pageInfo.hasPreviousPage"
            ),
            fetchMore: fetchMoreFeedback,
            ...ownProps,
          };

          return (
            <Mutation mutation={ARTICLE_FEEDBACK_CREATE}>
              {(mutate, { client, loading: createMessageLoading }) => {
                const { me: currentUser } = client.readQuery({ query: USER });
                const avatar = get(currentUser, "profile.avatar");
                const name = get(currentUser, "name.formatted");

                const createMessage = ({ message }) => {
                  mutate({
                    variables: {
                      articleId,
                      feedback: message,
                    },
                    optimisticResponse: {
                      __typename: "Mutation",
                      articleFeedbackCreate: {
                        id: "-1",
                        remark: message,
                        createdAt: new Date(new Date().getTime()).toISOString(),
                        hasRead: true,
                        author: {
                          id: "",
                          snapshot: {
                            id: "",
                            avatar,
                            name,
                            __typename: "UserSnapshot",
                          },
                          __typename: "User",
                        },
                        isPersisting: true,
                        __typename: "Remark",
                      },
                    },
                    update: (proxy, { data: { articleFeedbackCreate } }) => {
                      const queryArticleFeedback = {
                        query: ARTICLE_FEEDBACK,
                        variables: queryVariables,
                      };

                      const cacheData = proxy.readQuery(queryArticleFeedback);

                      cacheData.articleFeedback.edges.push({
                        cursor: "",
                        node: {
                          ...articleFeedbackCreate,
                          hasRead: true,
                        },
                        __typename: "RemarksConnectionEdge",
                      });

                      proxy.writeQuery({
                        ...queryArticleFeedback,
                        data: cacheData,
                      });
                    },
                  });
                };

                return (
                  <Component
                    createMessage={createMessage}
                    createMessageLoading={createMessageLoading}
                    {...props}
                  />
                );
              }}
            </Mutation>
          );
        }}
      </Query>
    );
  };
};
