import { t } from "localizify";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Dimmer, Header, Loader, Message } from "semantic-ui-react";

import Layout from "../../../components/layout-one-column-narrow/index.web";

const ResetPasswordStates = ({
  type,
  loading,
  error,
  token,
  isValid,
  PasswordForm,
}) => {
  return (
    <Layout>
      {(() => {
        if (loading) {
          return (
            <Dimmer active inverted>
              <Loader>Loading...</Loader>
            </Dimmer>
          );
        }

        if (error) {
          return (
            <Message
              error
              header={t("password.reset.error_heading_other")}
              content={t("password.reset.error_message_other")}
            />
          );
        }

        if (!token || !isValid) {
          return (
            <Message
              error
              header={t("password.reset.error_heading_invalid_link")}
              content={t("password.reset.error_message_invalid_link")}
            />
          );
        }

        const message =
          type === "reset"
            ? t("password.reset.welcome_message_reset")
            : t("password.reset.welcome_message_setup");
        const heading =
          type === "reset"
            ? t("password.reset.welcome_heading_reset")
            : t("password.reset.welcome_heading_setup");
        return (
          <Fragment>
            <Header>{heading}</Header>
            <p>{message}</p>
            <PasswordForm token={token} />
          </Fragment>
        );
      })()}
    </Layout>
  );
};

ResetPasswordStates.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  loading: PropTypes.bool,
  type: PropTypes.string.isRequired,
  isValid: PropTypes.bool,
  PasswordForm: PropTypes.node.isRequired,
  token: PropTypes.string.isRequired,
};

ResetPasswordStates.defaultProps = {
  error: null,
  loading: false,
  isValid: true,
};

export default ResetPasswordStates;
