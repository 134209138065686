import gql from "graphql-tag";

export const MY_ASSISTANT = gql`
  mutation MyAssistant($input: UserSupportContactSaveInput!) {
    myAssistant(input: $input) {
      id
      assistant {
        id
        name
        mobilePhone {
          countryCode
          number
          extension
        }
        officePhone {
          countryCode
          number
          extension
        }
        email
      }
      publicRelations {
        id
        name
        mobilePhone {
          countryCode
          number
          extension
        }
        officePhone {
          countryCode
          number
          extension
        }
        email
      }
    }
  }
`;

export const MY_PUBLIC_RELATION = gql`
  mutation MyPublicRelations($input: UserSupportContactSaveInput!) {
    myPublicRelations(input: $input) {
      id
      assistant {
        id
        name
        mobilePhone {
          countryCode
          number
          extension
        }
        officePhone {
          countryCode
          number
          extension
        }
        email
      }
      publicRelations {
        id
        name
        mobilePhone {
          countryCode
          number
          extension
        }
        officePhone {
          countryCode
          number
          extension
        }
        email
      }
    }
  }
`;

export const MY_ASSISTANT_DELETE = gql`
  mutation MyAssistantDelete {
    myAssistantDelete {
      id
      name {
        formatted
      }
      assistant {
        id
      }
    }
  }
`;

export const MY_PUBLIC_RELATIONS_DELETE = gql`
  mutation MyPublicRelationsDelete {
    myPublicRelationsDelete {
      id
      name {
        formatted
      }
      publicRelations {
        id
      }
    }
  }
`;
