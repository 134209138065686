import { debounce } from "lodash";
import { useCallback, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";

import { logFormBlur, logFormErrors } from "./analytics";
import getValueFromOnChange from "./input";

function useForm(initialValues, validationSchema, onSave, saving, formName) {
  const [values, setValues] = useState({ ...initialValues });
  const [errors, setErrors] = useState({});
  const [hasAttemptedSave, setHasAttemptedSave] = useState(false);

  useDeepCompareEffect(() => {
    setValues({ ...initialValues });
  }, [initialValues]);

  const validateForm = useCallback(
    newValues => {
      const foundErrors = {};
      const validateValues = newValues || values;
      try {
        validationSchema.validateSync(validateValues, { abortEarly: false });
      } catch (e) {
        e.inner.forEach(currentError => {
          foundErrors[currentError.path] = {
            hasError: true,
          };
          if (currentError.type !== "required") {
            foundErrors[currentError.path].message = currentError.message;
          }
        });
      }
      return foundErrors;
    },
    [values, validationSchema]
  );

  const getError = fieldName => {
    let response = false;
    if (errors[fieldName] && hasAttemptedSave) {
      if (errors[fieldName].message) {
        response = {
          content: errors[fieldName].message,
          pointing: "below",
        };
      } else {
        response = true;
      }
    }
    return response;
  };

  const onSubmit = () => {
    const foundErrors = validateForm();
    setHasAttemptedSave(true);
    setErrors(foundErrors);

    if (Object.keys(foundErrors).length < 1) {
      onSave(values);
    } else {
      logFormErrors(formName, foundErrors);
    }
    return foundErrors;
  };

  useDeepCompareEffect(() => {
    const foundErrors = validateForm();
    debounce(() => setErrors(foundErrors), 100);
  }, [values]);

  const displayErrors = [];
  Object.keys(errors).forEach(key => {
    displayErrors[key] = getError(key);
  });

  const decorateInput = name => {
    // If no root error is found, look deep
    // Behavior - one error for all sub-props. Doesn't support error per sub-prop
    let error = displayErrors[name];
    if (!error) {
      const errorKey = Object.keys(displayErrors).find(err =>
        err.includes(name)
      );
      error = errorKey ? displayErrors[errorKey] : undefined;
    }

    const isLocation = name === "location";

    return {
      name,
      error,
      value: values[name],
      onChange(e, args) {
        const value = getValueFromOnChange(args);
        if (isLocation) {
          setValues({ ...values, placeId: value.place_id });
        } else {
          setValues({ ...values, [name]: value });
        }
      },
      onBlur() {
        logFormBlur(formName, name, values[name]);
      },
      disabled: saving || false,
    };
  };

  const resetForm = () => {
    setValues({ ...initialValues });
    setErrors({});
    setHasAttemptedSave(false);
  };

  return {
    onSubmit,
    decorateInput,
    resetForm,
    values,
  };
}

export default useForm;
