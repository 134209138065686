import gql from "graphql-tag";
import { t } from "localizify";
import { get } from "lodash";
import React from "react";
import { Mutation, Query } from "react-apollo";

import { formatGraphQLErrorMessage } from "../../lib/error/utils";

export const FORGOT = gql`
  mutation forgotPassword($username: String!) {
    passwordForgot(username: $username) @client
  }
`;

export const RESET = gql`
  mutation resetPassword($resetToken: String!, $newPassword: String!) {
    passwordReset(
      input: { resetToken: $resetToken, newPassword: $newPassword }
    ) @client
  }
`;

export const CHANGE = gql`
  mutation updatePassword($oldPassword: String!, $newPassword: String!) {
    passwordUpdate(
      input: { oldPassword: $oldPassword, newPassword: $newPassword }
    )
  }
`;

export const VALIDATE_TOKEN = gql`
  query validateToken($token: String!) {
    passwordTokenIsValid(token: $token) @client {
      isValid
    }
  }
`;

export const forgotPassword = Component => ownProps => (
  <Mutation mutation={FORGOT}>
    {(mutationFunc, { loading, error: graphError, data }) => {
      const forgot = username => mutationFunc({ variables: { username } });
      let error = "";
      if (graphError) {
        const errorCode = get(graphError, "networkError.response.data.code");
        if (errorCode === "NotFound.Credential") {
          error = t("password.forgot.error_username_not_found");
        } else {
          error = t("password.forgot.error_unknown");
        }
      }
      const props = {
        error,
        success: !!data,
        loading,
        forgot,
        ...ownProps,
      };
      return <Component {...props} />;
    }}
  </Mutation>
);

export const resetPassword = Component => ownProps => (
  <Mutation mutation={RESET}>
    {(mutationFunc, { loading, error, data }) => {
      const reset = newPassword =>
        mutationFunc({
          variables: { newPassword, resetToken: ownProps.token },
        });
      const props = {
        error,
        success: !!data,
        loading,
        reset,
        ...ownProps,
      };
      return <Component {...props} />;
    }}
  </Mutation>
);

export const changePassword = Component => ownProps => (
  <Mutation mutation={CHANGE}>
    {(mutationFunc, { loading, error, data }) => {
      const change = (oldPassword, newPassword) => {
        return mutationFunc({ variables: { oldPassword, newPassword } });
      };

      const props = {
        error: formatGraphQLErrorMessage(
          error,
          t("password.change.error_incorrect_password")
        ),
        success: !!data,
        loading,
        reset: change,
        ...ownProps,
      };
      return <Component {...props} />;
    }}
  </Mutation>
);

export const validateToken = Component => ownProps => (
  <Query query={VALIDATE_TOKEN} variables={{ token: ownProps.token }}>
    {({ loading, data, error }) => {
      const props = {
        ...ownProps,
        loading,
        error,
        isValid: get(data, "passwordTokenIsValid.isValid"),
      };
      return <Component {...props} />;
    }}
  </Query>
);
