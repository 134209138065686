import { t } from "localizify";
import propTypes from "prop-types";
import React, { useLayoutEffect } from "react";

import setTitle from "../../../lib/webpage";
import { resetPassword, validateToken } from "../graphql";
import PasswordForm from "./form.web";
import ResetState from "./states.web";

const WiredPasswordForm = resetPassword(PasswordForm);

const Reset = props => {
  const { type } = props;

  useLayoutEffect(() => {
    const title =
      type === "reset"
        ? t("password.reset.welcome_heading_reset")
        : t("password.reset.welcome_heading_setup");

    setTitle(title);
  }, [type]);
  return <ResetState PasswordForm={WiredPasswordForm} {...props} />;
};

Reset.propTypes = {
  token: propTypes.string.isRequired,
  type: propTypes.oneOf(["reset", "setup"]).isRequired,
};

export default validateToken(Reset);
