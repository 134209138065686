import { format } from "d3-format";
import localizify, { t } from "localizify";
import { get, range } from "lodash";
import Moment from "moment";

import en from "../language-combiner";

localizify.onTranslationNotFound((locale, key, scope) => {
  // eslint-disable-next-line no-console
  console.warn("Localizify path not found", locale, key, scope);
});
localizify.add("en", en);

export function getLocalizedMonths() {
  const startMonth = new Moment("1970-01-01");
  startMonth.locale(localizify.getLocale() || "en");
  return range(12).map(num => startMonth.month(num).format("MMMM"));
}

export const localizedLongDate = isoDate => {
  return Moment(isoDate)
    .locale(localizify.getLocale() || "en")
    .format("LL");
};

export function namespacedT(namespace) {
  return (key, data) => t(key, Object.assign({ scope: namespace }, data));
}

export function localizedCurrency(number) {
  // TODO: add d3 localization data to language.en.json

  return format("$,.2f")(number);
}

export function registerInterpolationsFromConfig({ me, community } = {}) {
  localizify.registerInterpolations({
    app_name: get(community, "appName"),
    app_concierge_email: get(community, "conciergeEmail"),
    app_concierge_phone: get(community, "conciergePhone"),
    user_display_name: get(me, "name.formatted"),
  });
}

export const LANGUAGE_DEFAULTS = {
  PHONE: "{app_concierge_phone}",
  EMAIL: "{app_concierge_email}",
};

export default localizify;
