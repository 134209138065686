import gql from "graphql-tag";
import { first, get, last } from "lodash";
import { useCallback } from "react";
import { useMutation, useQuery } from "react-apollo";

import { GET_HAS_UNVIEWED } from "../../../components/app-layout/navbar/notifications/hooks";
import { relayUpdateQuery } from "../../../lib/graphql";

export const GET_NOTIFICATIONS = gql`
  query notifications(
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    notifications(first: $first, last: $last, after: $after, before: $before) {
      edges {
        cursor
        node {
          id
          url
          message
          hasRead
          createdAt
        }
      }
      pageInfo {
        hasNextPage
        hasUnviewed
      }
    }
  }
`;

export const MARK_AS_READ = gql`
  mutation notificationsMarkAsRead($input: NotificationsMarkAsReadInput!) {
    notificationsMarkAsRead(input: $input) {
      id
      hasRead
    }
  }
`;

const MARK_ALL_AS_READ = gql`
  mutation notificationsMarkAllAsRead {
    notificationsMarkAllAsRead {
      id
      hasRead
    }
  }
`;

export const useNotifications = pageSize => {
  const REFETCH_STATUS = 4;
  const { loading, data, error, fetchMore, refetch, networkStatus } = useQuery(
    GET_NOTIFICATIONS,
    {
      variables: {
        first: pageSize,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const notificationEdges =
    (data && data.notifications && data.notifications.edges) || [];

  const fetchMoreCb = useCallback(
    updateAction => {
      const _updateAction = updateAction || "append";

      const variables = {};
      if (_updateAction === "append") {
        variables.after = last(notificationEdges).cursor;
      }
      if (_updateAction === "prepend") {
        variables.first = null; // Lingers from initial query variables. Does not affect paging for older results.
        variables.last = pageSize;
        variables.before = first(notificationEdges).cursor;
      }

      return fetchMore({
        updateQuery: (prev, result) => {
          const namespace = "notifications";
          const merged = relayUpdateQuery(
            namespace,
            _updateAction,
            prev,
            result
          );

          // If we got new notifications we are prepending, and we don't care about it's paging when looking for older notifications
          if (_updateAction === "prepend") {
            merged[namespace].pageInfo = prev[namespace].pageInfo;
          }
          return merged;
        },
        variables,
      });
    },
    [notificationEdges, fetchMore, pageSize]
  );

  return {
    loading: loading || networkStatus === REFETCH_STATUS,
    error,
    notifications: notificationEdges.map(({ node }) => node),
    hasMore: !loading && get(data, "notifications.pageInfo.hasNextPage"),
    refetch,
    refetching: networkStatus === REFETCH_STATUS,
    fetchMoreLoading: networkStatus === 3,
    fetchMore: fetchMoreCb,
  };
};

export const useMarkAsRead = () => {
  const [mutate] = useMutation(MARK_AS_READ);

  const markAsRead = useCallback(
    notificationIds => {
      return mutate({
        variables: {
          input: {
            notificationIds,
          },
        },
        optimisticResponse: {
          __typename: "Mutation",
          notificationsMarkAsRead: notificationIds.map(id => ({
            id,
            hasRead: true,
            __typename: "Notification",
          })),
        },
        refetchQueries: [{ query: GET_HAS_UNVIEWED }],
      });
    },
    [mutate]
  );

  return {
    markAsRead,
  };
};

export const useMarkAllAsRead = () => {
  const [mutate] = useMutation(MARK_ALL_AS_READ);

  const markAllAsRead = useCallback(
    currentNotifications => {
      return mutate({
        optimisticResponse: {
          __typename: "Mutation",
          notificationsMarkAllAsRead: currentNotifications.map(
            notification => ({
              id: notification.id,
              hasRead: true,
              __typename: "Notification",
            })
          ),
        },
        refetchQueries: [{ query: GET_HAS_UNVIEWED }],
      });
    },
    [mutate]
  );

  return {
    markAllAsRead,
  };
};
