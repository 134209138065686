const localeDateTime = new Intl.DateTimeFormat();
const { locale } = localeDateTime.resolvedOptions();

// Format: 9/2/2020
export function formatLocaleDate(isoDate) {
  const date = new Date(isoDate || "");
  return Number.isNaN(date.getTime()) ? isoDate : localeDateTime.format(date);
}

// Format: Oct 2, 2020
const shortMonthOptions = { month: "short", day: "numeric", year: "numeric" };
const shortMonthDateTime = new Intl.DateTimeFormat(locale, shortMonthOptions);
export function formatShortMonthDate(isoDate) {
  const date = new Date(isoDate || "");
  return Number.isNaN(date.getTime())
    ? isoDate
    : shortMonthDateTime.format(date);
}

const dateTokenMap = {
  month: "MM",
  day: "DD",
  year: "YYYY",
};

// Extracts date format/token: i.e. MM/DD/YYYY
export function getLocaleDateFormat() {
  const parts = localeDateTime.formatToParts();
  return parts.reduce((accum, { type, value }) => {
    return accum + (dateTokenMap[type] || value);
  }, "");
}
