import PropTypes from "prop-types";
import React from "react";
import { Grid, Header } from "semantic-ui-react";

function PageHeader({
  title,
  compact,
  only,
  headerRight,
  headerRightColumnProps,
}) {
  if (!title && !headerRight) {
    return null;
  }

  return (
    <Grid>
      <Grid.Row
        columns={2}
        only={only}
        style={{
          padding: !compact && "58px 0 38px",
        }}
      >
        {title && (
          <Grid.Column>
            <Header as="h1" style={{ fontSize: "24px", fontWeight: 400 }}>
              {title}
            </Header>
          </Grid.Column>
        )}

        <Grid.Column
          width={title ? 8 : 16}
          {...headerRightColumnProps}
          textAlign="right"
        >
          {headerRight}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default PageHeader;

PageHeader.propTypes = {
  title: PropTypes.string,
  only: PropTypes.string,
  compact: PropTypes.bool,
  headerRight: PropTypes.node,
  headerRightColumnProps: PropTypes.shape({
    only: PropTypes.string,
  }),
};

PageHeader.defaultProps = {
  title: "",
  only: null,
  compact: false,
  headerRight: null,
  headerRightColumnProps: null,
};
