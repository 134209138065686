import { t } from "localizify";
import { find } from "lodash";

import { ERROR_CODES, ERROR_LIST } from "./error-codes";

const GRAPHQL_ERROR_HEADER = "GraphQL error:";

const getErrorByCode = code => {
  return (
    find(ERROR_LIST, e => e.code === code) ||
    find(ERROR_LIST, e => e.code === ERROR_CODES.INVALID_ERROR)
  );
};

const getErrorByMessage = msg => {
  return (
    find(ERROR_LIST, e => t(e.message) === msg) ||
    find(ERROR_LIST, e => e.code === ERROR_CODES.INVALID_ERROR)
  );
};

export const getErrorMessageFromCode = code => {
  return t(getErrorByCode(code).message);
};

export const getErrorCodeFromMessage = message => {
  return getErrorByMessage(message).code;
};

export const getError = (...allErrors) => {
  const errorMessages = allErrors.filter(err => !!err).map(err => err.message);
  return errorMessages.length > 0 ? errorMessages[0] : null;
};

export const formatGraphQLErrorMessage = (error, msg) =>
  error && {
    ...error,
    message: msg || error.message.replace(GRAPHQL_ERROR_HEADER, ""),
  };
