import PropTypes from "prop-types";
import React from "react";
import { Checkbox, Grid, List, Popup } from "semantic-ui-react";

import colors from "../../../lib/colors";
import AppLinks from "../subsections/notifications/app-links.web";

function NotificationSetting({
  title,
  description,
  name,
  checked,
  mobileVisible,
  mobileVisibleColumn,
  mobileDisabled,
  webVisible,
  webVisibleColumn,
  webDisabled,
  emailVisible,
  emailVisibleColumn,
  onChange,
}) {
  return (
    <List.Item key={name} style={{ borderColor: colors.silverBase }}>
      <List.Content style={{ padding: "11px 0 10px" }}>
        <Grid columns={2} verticalAlign="middle">
          <Grid.Row>
            <Grid.Column width={8}>
              <p
                style={{
                  fontSize: "16px",
                  marginBottom: "1px",
                  overflowWrap: "break-word",
                }}
              >
                {title}
              </p>
              {description && (
                <p
                  style={{
                    fontSize: "14px",
                    color: colors.grayMedium,
                    overflowWrap: "break-word",
                  }}
                >
                  {description}
                </p>
              )}
            </Grid.Column>

            <Grid.Column width={8} floated="right" textAlign="right">
              <Grid columns={3}>
                <Popup
                  disabled={!mobileDisabled}
                  content={<AppLinks />}
                  position="bottom right"
                  on={["hover"]}
                  hoverable
                  wide
                  trigger={
                    mobileVisibleColumn && (
                      <Grid.Column width={1} floated="right">
                        {mobileVisible && (
                          <Checkbox
                            disabled={mobileDisabled}
                            toggle
                            name={`${name}-mobile`}
                            style={{ marginLeft: "10%", marginTop: "2px" }}
                            checked={checked.mobile}
                            onChange={onChange}
                          />
                        )}
                      </Grid.Column>
                    )
                  }
                />

                {emailVisibleColumn && (
                  <Grid.Column width={1} floated="right">
                    {emailVisible && (
                      <Checkbox
                        toggle
                        name={`${name}-email`}
                        style={{ marginLeft: "10%", marginTop: "2px" }}
                        checked={checked.email}
                        onChange={onChange}
                      />
                    )}
                  </Grid.Column>
                )}
                {webVisibleColumn && (
                  <Grid.Column width={1} floated="right">
                    {webVisible && (
                      <Checkbox
                        disabled={webDisabled}
                        toggle
                        name={`${name}-web`}
                        style={{ marginLeft: "10%", marginTop: "2px" }}
                        checked={checked.web}
                        onChange={onChange}
                      />
                    )}
                  </Grid.Column>
                )}
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </List.Content>
    </List.Item>
  );
}

export default NotificationSetting;

NotificationSetting.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  mobileVisible: PropTypes.bool,
  mobileVisibleColumn: PropTypes.bool,
  mobileDisabled: PropTypes.bool,
  webVisible: PropTypes.bool,
  webVisibleColumn: PropTypes.bool,
  webDisabled: PropTypes.bool,
  emailVisible: PropTypes.bool,
  emailVisibleColumn: PropTypes.bool,
  onChange: PropTypes.func,
};

NotificationSetting.defaultProps = {
  title: "",
  description: "",
  name: "",
  checked: false,
  mobileVisible: false,
  mobileVisibleColumn: false,
  mobileDisabled: false,
  webVisible: false,
  webVisibleColumn: false,
  webDisabled: false,
  emailVisible: false,
  emailVisibleColumn: false,
  onChange: () => {},
};
