import isFunction from "lodash/isFunction";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { Button, Checkbox } from "semantic-ui-react";

import { trackEvent } from "../../lib/analytics/segment";
import Link from "../link/index.web";

export default function SegmentTrack(props) {
  const {
    wrap: WrappedElement,
    category,
    eventType,
    eventLabel,
    eventProps,
    logOnEvent,
    ...rest
  } = props;
  const { [logOnEvent]: fn } = props;

  // TODO: Deal with React accepted props (like style)
  // if (process.env.NODE_ENV !== "production") {
  //   const propTypes = Object.keys(WrappedElement.propTypes);
  //   const componentPropTypes = Object.keys(Component.propTypes);
  //   const unhandledProps = Object.keys(rest).filter(
  //     key => !propTypes.includes(key) && !componentPropTypes.includes(key)
  //   );
  //   if (unhandledProps && unhandledProps.length > 0)
  //     throw new Error(
  //       `Invalid Props being passed to Wrapped Component: [${unhandledProps}]`
  //     );
  // }

  const jsonProps = JSON.stringify(eventProps || {});
  const trackingOnClick = useCallback(
    (...args) => {
      trackEvent(eventType, category, eventLabel, JSON.parse(jsonProps));

      if (isFunction(fn)) {
        fn(...args);
      }
    },
    [category, eventLabel, eventType, jsonProps, fn]
  );

  rest[logOnEvent] = trackingOnClick;
  return <WrappedElement {...rest} />;
}

SegmentTrack.propTypes = {
  category: PropTypes.string,
  eventType: PropTypes.string.isRequired,
  eventLabel: PropTypes.string.isRequired,
  eventProps: PropTypes.shape({}),
  logOnEvent: PropTypes.string,
  wrap: PropTypes.elementType,
};

SegmentTrack.defaultProps = {
  category: "",
  logOnEvent: "onClick",
  eventProps: {},
  wrap: Button,
};

export function TrackLink(props) {
  return <SegmentTrack {...props} eventType="LinkClick" wrap={Link} />;
}

export function TrackButton(props) {
  return <SegmentTrack {...props} eventType="ButtonClick" wrap={Button} />;
}

export function TrackFormCancel(props) {
  return <SegmentTrack {...props} eventType="FormCancel" wrap={Button} />;
}

export function TrackFormSubmit(props) {
  return <SegmentTrack {...props} eventType="FormSubmit" wrap={Button} />;
}

export function TrackConciergeLink(props) {
  return <SegmentTrack {...props} eventType="ConciergeLink" wrap="a" />;
}

export function TrackCheckbox(props) {
  return <SegmentTrack {...props} eventType="ElementToggle" wrap={Checkbox} />;
}
