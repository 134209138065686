import PropTypes from "prop-types";
import React from "react";
import { Header as SemanticHeader } from "semantic-ui-react";

import colorsTheme from "../../../../lib/colors";
import { localizedCurrency, namespacedT } from "../../../../lib/i18n";
import formatRenewalDate from "../utils";

const t = namespacedT("payment.dunning_member");

function FormHeader({ paid, userName, currentBalance, renewalDate }) {
  if (paid) {
    return null;
  }

  return (
    <div>
      <SemanticHeader
        as="h2"
        style={{ color: colorsTheme.black, textAlign: "center" }}
      >
        {t("payment")}
      </SemanticHeader>
      <p style={{ fontSize: "16px", lineHeight: "24px" }}>
        {t("header_info_message", { userName })}
      </p>
      <SemanticHeader
        as="h3"
        style={{ color: colorsTheme.black, marginBottom: 0 }}
      >
        {t("membership_fee")}{" "}
        <strong style={{ color: colorsTheme.blueBase }}>
          {localizedCurrency(currentBalance).replace(/\.00$/, "")}
        </strong>
      </SemanticHeader>
      <div>
        <i style={{ color: colorsTheme.grayBase }}>
          {t("membership_expires_on", {
            expirationDate: formatRenewalDate(renewalDate),
          })}
        </i>
      </div>
    </div>
  );
}

FormHeader.propTypes = {
  userName: PropTypes.string.isRequired,
  currentBalance: PropTypes.number.isRequired,
  paid: PropTypes.bool.isRequired,
  renewalDate: PropTypes.string.isRequired,
};

export default FormHeader;
