import "./style.css";

import { t } from "localizify";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Button, Header, Icon } from "semantic-ui-react";

import ContactUsButton from "../contact-us-button/index.web";

export default function ErrorNotice({
  message,
  tryAgain,
  contactPhone,
  contactEmail,
  correlationId,
}) {
  return (
    <div className="error-notice">
      <Header as="h1" icon className="error-notice-header">
        <Icon className="warning-circle error-notice-header-icon" />

        {t("error_notice.header")}

        <Header.Subheader className="error-notice-subheader">
          {t("error_notice.sub_header")}
        </Header.Subheader>
      </Header>

      {message && <p className="error-notice-message">{message}</p>}

      {tryAgain && (
        <Fragment>
          <Button onClick={tryAgain} className="error-notice-button">
            {t("error_notice.try_again")}
          </Button>

          {(contactEmail || contactPhone) && (
            <p className="error-notice-or-text">{t("error_notice.or")}</p>
          )}
        </Fragment>
      )}

      {(contactPhone || contactEmail) && (
        <ContactUsButton
          contactPhone={contactPhone}
          contactEmail={contactEmail}
        />
      )}

      {correlationId && (
        <span className="error-notice-correlation-id">{correlationId}</span>
      )}
    </div>
  );
}

ErrorNotice.propTypes = {
  message: PropTypes.string,
  tryAgain: PropTypes.func,
  contactPhone: PropTypes.string,
  contactEmail: PropTypes.string,
  correlationId: PropTypes.string,
};

ErrorNotice.defaultProps = {
  message: "",
  tryAgain: null,
  contactPhone: "",
  contactEmail: "",
  correlationId: "",
};
