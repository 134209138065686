import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import useIdentity from "../../lib/use-identity";
import useLogout from "./hooks";
import Login from "./login/index.web";
import ResetPassword from "./password/reset/index.web";
import {
  LEGACY_LOGIN,
  LEGACY_LOGOUT,
  LOGIN,
  LOGIN_ADMIN,
  LOGOUT,
  RESET_PASSWORD,
  linkTo,
} from "./routes-links";

const LogoutWrapper = () => {
  const { isLoggedIn } = useIdentity();
  const { logout, loading } = useLogout();
  useEffect(() => {
    if (isLoggedIn && !loading) {
      localStorage.removeItem("community");
      logout();
    }
  }, [logout, isLoggedIn, loading]);
  return <Redirect to={linkTo.login()} />;
};

export default (
  <Route
    path={[
      LOGIN,
      LOGIN_ADMIN,
      LOGOUT,
      LEGACY_LOGIN,
      LEGACY_LOGOUT,
      RESET_PASSWORD,
    ]}
  >
    <Switch>
      <Route path={LOGIN} component={Login} />
      <Route
        path={LOGIN_ADMIN}
        render={props => <Login isAdminLogin {...props} />}
      />
      <Route path={LOGOUT} component={LogoutWrapper} />

      <Route
        path={RESET_PASSWORD}
        render={({ location }) => {
          const search = location && location.search;
          const token = new URLSearchParams(search).get("token");
          return <ResetPassword type="reset" token={token} />;
        }}
      />

      {/* REDIRECTS */}

      <Route
        path={LEGACY_LOGIN}
        render={props => (
          <Redirect
            from={LEGACY_LOGIN}
            to={{
              pathname: linkTo.login(),
              search: props.location.search,
            }}
          />
        )}
      />
      <Redirect from={LEGACY_LOGOUT} to={linkTo.logout()} />
    </Switch>
  </Route>
);
