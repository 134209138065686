import "./style.css";

import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, Icon } from "semantic-ui-react";

import { LOGOUT } from "../../../../containers/auth/routes-links";
import { linkTo } from "../../../../containers/profile/routes-links";
import { SETTINGS_HOME } from "../../../../containers/settings/routes-links";
import { namespacedT } from "../../../../lib/i18n";
import ButtonTertiary from "../../../button/tertiary/index.web";
import Link from "../../../link/index.web";
import Sidebar from "../../../sidebar/index.web";
import PrimaryMenuItems from "../primary-menu-items/index.web";
import UserMenu from "../user-menu/index.web";

const t = namespacedT("app_navbar");

function SidebarMenu({ visible, onClose, user, hasForum, moreMenuItems }) {
  const [moreMenuVisible, setMoreMenuVisible] = useState(false);

  const expandMenuClasses = [
    "app-navbar-sidebar-expand-menu-list",
    moreMenuVisible ? "app-navbar-sidebar-expand-menu-list-visible" : "",
  ];

  return (
    <Sidebar
      className="app-navbar-sidebar"
      visible={visible}
      onClose={onClose}
      direction="left"
      closeOnDimmerClick
      style={{ width: "90%" }}
    >
      <UserMenu {...user} onClose={onClose} />

      <div className="app-navbar-sidebar-content">
        <ul className="app-navbar-sidebar-menu">
          <PrimaryMenuItems
            hasForum={hasForum}
            classNamePrefix="app-navbar-sidebar-menu"
            onClose={onClose}
          />
        </ul>

        {moreMenuItems.length !== 0 && (
          <div className="app-navbar-sidebar-expand-menu">
            <ButtonTertiary
              className="app-navbar-sidebar-expand-menu-trigger"
              onClick={() => setMoreMenuVisible(!moreMenuVisible)}
            >
              {t("more")} <Icon className="angle-down" />
            </ButtonTertiary>

            <ul className={expandMenuClasses.join(" ")}>
              {moreMenuItems.map(({ label, link }) => (
                <li key={label} className="app-navbar-sidebar-expand-menu-item">
                  <Link
                    as="a"
                    className="app-navbar-sidebar-expand-menu-link"
                    to={link}
                    onClick={onClose}
                  >
                    {label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}

        <ul className="app-navbar-sidebar-secondary-menu">
          <li className="app-navbar-sidebar-secondary-menu-item">
            <Button
              as={Link}
              to={linkTo.member(user.id)}
              className="app-navbar-sidebar-primary-button"
              fluid
              onClick={onClose}
            >
              {t("manage_profile")}
            </Button>
          </li>
          <li className="app-navbar-sidebar-secondary-menu-item">
            <Link
              className="app-navbar-sidebar-secondary-menu-link"
              to={SETTINGS_HOME}
              onClick={onClose}
            >
              <Icon className="settings" /> {t("settings")}
            </Link>
          </li>
          <li className="app-navbar-sidebar-secondary-menu-item">
            <Link
              className="app-navbar-sidebar-secondary-menu-link"
              to={LOGOUT}
              onClick={onClose}
            >
              <Icon className="sign-out" /> {t("sign_out")}
            </Link>
          </li>
        </ul>
      </div>
    </Sidebar>
  );
}

export default SidebarMenu;

SidebarMenu.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  hasForum: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.string,
    avatar: PropTypes.string,
    displayName: PropTypes.string,
    company: PropTypes.string,
    community: PropTypes.string,
    location: PropTypes.string,
  }),
  moreMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      href: PropTypes.string,
    })
  ),
};

SidebarMenu.defaultProps = {
  visible: false,
  onClose: () => {},
  hasForum: false,
  user: {
    id: "",
    avatar: "",
    displayName: "",
    company: "",
    community: "",
    location: "",
  },
  moreMenuItems: [],
};
