import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";
import { Segment } from "semantic-ui-react";

import ChargifyForm from "../../../../components/chargify-form";
import { trackEvent } from "../../../../lib/analytics/segment";
import { namespacedT } from "../../../../lib/i18n";
import cardTypes from "../../../settings/supported-card-types";

const t = namespacedT("payment.dunning_member");
function FormView({
  paid,
  currentBalance,
  formConfig,
  callingChargify,
  savingPayment,
  setCallingChargify,
  setChargifyErrorMessage,
  setServerErrorMessage,
  addPaymentViaToken,
  setPaid,
  trackFailedChargifyJS,
}) {
  if (paid) {
    return null;
  }

  const errorsMessage = {
    firstName: "First name",
    lastName: "Last name",
    number: "Card number",
    cvv: "CVV",
    month: "Month",
    year: "Year",
    zip: "Zip code",
  };

  const getErrorMessage = chargifyError => {
    if (chargifyError.invalidFields && chargifyError.invalidFields.length > 0) {
      const errorMessage = chargifyError.invalidFields.reduce(
        (msg, errKey, index) => {
          if (msg === "")
            return `Enter valid information for the following ${
              chargifyError.invalidFields.length > 1 ? "inputs" : "input"
            }: ${errorsMessage[errKey]}${
              chargifyError.invalidFields.length > 1 ? "," : "."
            }`;
          if (index === chargifyError.invalidFields.length - 1)
            return `${msg} ${errorsMessage[errKey]}.`;
          return `${msg} ${errorsMessage[errKey]},`;
        },
        ""
      );
      setChargifyErrorMessage(errorMessage);
    }
    if (chargifyError.status && chargifyError.status === 400) {
      setChargifyErrorMessage(chargifyError.errors);
    } else {
      setServerErrorMessage(chargifyError.errors);
    }
  };

  return (
    <>
      {!paid && (
        <Segment basic vertical padded className="segment-form-container">
          <ChargifyForm
            acceptedCards={Object.keys(cardTypes)}
            paymentType="card"
            formConfig={formConfig}
            submitForm={callingChargify}
            onTokenCreate={async token => {
              if (!savingPayment) {
                const result = await addPaymentViaToken(token);
                if (get(result, ["data", "paySubscriptionByToken"])) {
                  trackEvent("FormSubmit", "Payment", t("button_label"), {
                    value: currentBalance,
                  });
                  setPaid(true);
                }
              }
              setCallingChargify(false);
            }}
            onTokenError={chargifyError => {
              trackFailedChargifyJS(chargifyError.errors);
              setCallingChargify(false);
              getErrorMessage(chargifyError);
            }}
          />
        </Segment>
      )}
    </>
  );
}

FormView.propTypes = {
  currentBalance: PropTypes.number.isRequired,
  paid: PropTypes.bool.isRequired,
  formConfig: PropTypes.shape({
    publicKey: PropTypes.string,
    serverHost: PropTypes.string,
  }).isRequired,
  callingChargify: PropTypes.bool.isRequired,
  savingPayment: PropTypes.bool.isRequired,
  setCallingChargify: PropTypes.func.isRequired,
  setChargifyErrorMessage: PropTypes.func.isRequired,
  setServerErrorMessage: PropTypes.func.isRequired,
  addPaymentViaToken: PropTypes.func.isRequired,
  trackFailedChargifyJS: PropTypes.func.isRequired,
  setPaid: PropTypes.func.isRequired,
};

export default FormView;
