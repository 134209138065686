import "./style.css";

import React, { useState } from "react";
import { formatPhoneNumber } from "react-phone-number-input";
import {
  Button,
  Container,
  Grid,
  Icon,
  Message,
  Modal,
  Placeholder,
} from "semantic-ui-react";

import ButtonTertiary from "../../../../components/button/tertiary/index.web";
import HelpTooltip from "../../../../components/help-tooltip/index.web";
import SectionHeader from "../../../../components/section-header/index.web";
import SegmentPage from "../../../../components/segment/page";
import colors from "../../../../lib/colors";
import { namespacedT } from "../../../../lib/i18n";
import UpdatePasswordForm from "../../../password/update/index.web";
import useGetAccountSettings from "./hooks";
import PhoneNumberModal from "./phone-number-modal/index.web";

const tSettings = namespacedT("settings");

export const SubsectionSecurity = () => {
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const [mobilePhoneModalOpen, setMobilePhoneModalOpen] = useState(false);

  const { username, mobilePhone, error, loading } = useGetAccountSettings();

  return (
    <>
      {error ? (
        <Message negative>{error}</Message>
      ) : (
        <>
          <Container>
            <Grid columns={1}>
              <Grid.Row>
                <Grid.Column>
                  <SectionHeader title={tSettings("subsections.login")} />
                  <Grid columns={2} stackable>
                    <Grid.Row>
                      <Grid.Column width={4} className="column-fix">
                        <span style={{ color: colors.grayBase, fontSize: 16 }}>
                          {tSettings("login_info.label_username")}
                        </span>
                        <HelpTooltip
                          content={
                            <p>
                              {tSettings("login_info.text_contact_concierge")}
                            </p>
                          }
                        />
                      </Grid.Column>

                      <Grid.Column width={12} className="column-fix">
                        <span>{username}</span>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={4} className="column-fix">
                        <span style={{ color: colors.grayBase, fontSize: 16 }}>
                          {tSettings("login_info.label_password")}
                        </span>
                      </Grid.Column>
                      <Grid.Column width={12} className="column-fix">
                        <span>
                          <input
                            style={{
                              border: "none",
                              background: "transparent",
                              WebkitTextSecurity: "disc",
                            }}
                            type="text"
                            readOnly
                            disabled
                            value={tSettings("login_info.text_fake_password")}
                          />
                        </span>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Button
                    onClick={() => setChangePasswordModalOpen(true)}
                    data-ui-action="password-reset-open"
                    primary
                  >
                    {tSettings("login_info.button_change_password")}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Grid columns={1}>
              <Grid.Row>
                <Grid.Column>
                  <SectionHeader
                    title={tSettings("subsections.mobile_phone")}
                  />

                  {loading && (
                    <Placeholder>
                      <Placeholder.Line length="short" />
                    </Placeholder>
                  )}

                  {!loading && (
                    <>
                      <Icon
                        className="mobile-phone"
                        style={{
                          fontSize: "24px",
                          verticalAlign: "middle",
                          margin: "0 0 2px 0",
                        }}
                      />

                      <span data-ui-container="user-mobile-phone-number">
                        {mobilePhone.number
                          ? formatPhoneNumber(
                              `+${mobilePhone.countryCode}${mobilePhone.number}`
                            )
                          : tSettings("modals.mobile_phone_number.add")}
                      </span>

                      <ButtonTertiary
                        onClick={() => setMobilePhoneModalOpen(true)}
                        data-ui-action="edit-mobile-phone-open"
                      >
                        <Icon
                          className="edit"
                          style={{
                            color: colors.blueBase,
                            verticalAlign: "middle",
                            margin: "0 0 5px 12px",
                          }}
                        />
                      </ButtonTertiary>
                    </>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>

          <SegmentPage view="SettingsPassword">
            <Modal
              closeIcon
              style={{ maxWidth: 500 }}
              open={changePasswordModalOpen}
              onClose={() => setChangePasswordModalOpen(false)}
            >
              <Modal.Header>
                {tSettings("login_info.button_change_password")}
              </Modal.Header>
              <Modal.Content>{<UpdatePasswordForm />}</Modal.Content>
            </Modal>
          </SegmentPage>

          <PhoneNumberModal
            open={mobilePhoneModalOpen}
            onClose={() => setMobilePhoneModalOpen(false)}
            mobilePhone={mobilePhone}
          />
        </>
      )}
    </>
  );
};

export default SubsectionSecurity;
