import React from "react";
import { Container, Grid, Placeholder } from "semantic-ui-react";

export default function InterimEditor() {
  return (
    <Container className="ie-container" style={{ marginTop: "60px" }}>
      <Grid columns={2} stackable>
        <Grid.Column computer={11} tablet={16}>
          <div className="ie-main-section">
            <div className="ie-editor-skeleton">
              <div className="ie-editor-skeleton-top">
                <Placeholder fluid>
                  <Placeholder.Header>
                    <Placeholder.Line length="full" />
                  </Placeholder.Header>
                </Placeholder>
              </div>

              <div className="ie-editor-skeleton-content">
                <Placeholder fluid>
                  <Placeholder.Line length="full" />
                  <Placeholder.Line length="full" />
                  <Placeholder.Line length="full" />
                  <Placeholder.Line length="full" />
                  <Placeholder.Line length="full" />
                  <Placeholder.Line length="full" />
                  <Placeholder.Line length="short" />
                </Placeholder>
              </div>
            </div>
          </div>
        </Grid.Column>

        <Grid.Column computer={5} tablet={16}>
          <div className="ie-card ie-card-byline-mobile">
            <h4 className="ie-card-title">
              <Placeholder fluid>
                <Placeholder.Header>
                  <Placeholder.Line length="medium" />
                </Placeholder.Header>
              </Placeholder>
            </h4>

            <div className="ie-card-content">
              <Placeholder fluid>
                <Placeholder.Line length="full" />
                <Placeholder.Line length="full" />
                <Placeholder.Line length="full" />
                <Placeholder.Line length="short" />
              </Placeholder>
            </div>
          </div>
        </Grid.Column>
      </Grid>
    </Container>
  );
}
