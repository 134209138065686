import { resizeImage } from "@communityco/web-helpers";

export const defaultAvatar =
  "https://s3.amazonaws.com/cco-organizations/avatar.png";

const getAvatarUrl = (avatarUrl, size = 200) => {
  return resizeImage(avatarUrl || defaultAvatar, size, size);
};

export default getAvatarUrl;
