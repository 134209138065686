import gql from "graphql-tag";
import { get } from "lodash";
import { useMutation, useQuery } from "react-apollo";

import ServerError from "../../lib/error/server-error";

export const REQUEST_SUBSCRIPTION_BALANCE = gql`
  query subscriptionBalance($userId: String!, $subscriptionId: String!) {
    subscriptionBalance(
      input: { userId: $userId, subscriptionId: $subscriptionId }
    ) @client {
      memberName
      outstandingBalance
      showNoBalanceDue
      renewalDate
      publicKey
      serverHost
    }
  }
`;

export const PAY_SUBSCRIPTION = gql`
  mutation paySubscriptionByToken($input: AddSavedPaymentInput!) {
    paySubscriptionByToken(input: $input) @client {
      id
    }
  }
`;

const PAYMENT_FRAGMENT = gql`
  fragment Payment_Fragment on SavedPayment {
    id
    userId
    firstName
    lastName
    expirationMonth
    expirationYear
    last4Digits
    cardType
    status
    isDefault
  }
`;

export const PAYMENTS = gql`
  query {
    payments {
      ...Payment_Fragment
    }
  }
  ${PAYMENT_FRAGMENT}
`;

export const PAYMENTS_FOR_USER = gql`
  query paymentsForUser($userId: String!) {
    paymentsForUser(userId: $userId) {
      ...Payment_Fragment
    }
  }
  ${PAYMENT_FRAGMENT}
`;

const DELETE_SAVED_PAYMENT_ADMIN = gql`
  mutation deleteSavedPayment($input: DeleteSavedPaymentInput!) {
    paymentDeleteSavedPayment(input: $input)
  }
`;

const DELETE_SAVED_PAYMENT_MEMBER = gql`
  mutation deleteSavedPayment($paymentId: String!) {
    paymentDeleteSavedPayment(paymentId: $paymentId)
  }
`;

const CHANGE_DEFAULT_PAYMENT_ADMIN = gql`
  mutation changeDefaultSavedPayment($input: MemberSavedPaymentInput!) {
    paymentChangeDefaultSavedPayment(input: $input) {
      id
    }
  }
`;

const CHANGE_DEFAULT_PAYMENT_MEMBER = gql`
  mutation changeDefaultSavedPayment($paymentId: String!) {
    paymentChangeDefaultSavedPayment(paymentId: $paymentId) {
      id
    }
  }
`;

export const CARD_AUTH_LOG = gql`
  query cardAuthLog($paymentId: ID!) {
    cardAuthLog(paymentId: $paymentId) {
      id
      attemptAmount
      attemptedAt
      authResponseCode
      reversalHttpCode
    }
  }
`;

export function useCardAuthLog(paymentId) {
  const { data, loading } = useQuery(CARD_AUTH_LOG, {
    variables: {
      paymentId,
    },
  });

  return { cardAuthLog: get(data, "cardAuthLog"), loading };
}

export function useChangeDefaultSavedPayment(userId) {
  const [changeDefaultMutation, { data, loading, error }] = useMutation(
    userId ? CHANGE_DEFAULT_PAYMENT_ADMIN : CHANGE_DEFAULT_PAYMENT_MEMBER,
    {
      update: (cache, mutationResult) => {
        const queryName = userId ? "paymentsForUser" : "payments";
        const {
          data: {
            paymentChangeDefaultSavedPayment: { id },
          },
        } = mutationResult;

        const paymentsQuery = {
          query: userId ? PAYMENTS_FOR_USER : PAYMENTS,
        };

        if (userId) {
          paymentsQuery.variables = { userId };
        }

        const cachedCS = cache.readQuery(paymentsQuery);
        const cacheToUpdate = get(cachedCS, queryName) || [];

        cache.writeQuery({
          ...paymentsQuery,
          data: {
            [queryName]: cacheToUpdate.map(payment => {
              payment.isDefault = payment.id === id;
              return payment;
            }),
          },
        });
      },
    }
  );
  const changeDefault = paymentId => {
    const variables = userId
      ? {
          input: {
            userId,
            paymentId,
          },
        }
      : { paymentId };
    return changeDefaultMutation({
      variables,
      optimisticResponse: {
        paymentChangeDefaultSavedPayment: {
          id: paymentId,
          __typename: "SavedPayment",
        },
      },
    });
  };
  return {
    data,
    changeDefault,
    loadingDefault: loading,
    errorDefault: error,
  };
}

export function useDeleteSavedPayment(userId) {
  const [destroyMutation, { data, loading, error }] = useMutation(
    userId ? DELETE_SAVED_PAYMENT_ADMIN : DELETE_SAVED_PAYMENT_MEMBER,
    {
      update: (cache, mutationResult) => {
        const queryName = userId ? "paymentsForUser" : "payments";
        const {
          data: { paymentDeleteSavedPayment: id },
        } = mutationResult;

        const paymentsQuery = {
          query: userId ? PAYMENTS_FOR_USER : PAYMENTS,
        };

        if (userId) {
          paymentsQuery.variables = { userId };
        }

        const cachedCS = cache.readQuery(paymentsQuery);
        const cacheToUpdate = get(cachedCS, queryName) || [];

        cache.writeQuery({
          ...paymentsQuery,
          data: {
            [queryName]: cacheToUpdate.filter(payment => payment.id !== id),
          },
        });
      },
    }
  );
  const destroy = paymentId => {
    const variables = userId
      ? {
          input: {
            userId,
            paymentId,
          },
        }
      : { paymentId };
    return destroyMutation({
      variables,
      optimisticResponse: {
        paymentDeleteSavedPayment: paymentId,
      },
    });
  };
  return {
    destroy,
    data,
    loadingDelete: loading,
    errorDelete: error,
  };
}

export function usePayments() {
  const { data, loading } = useQuery(PAYMENTS);
  let cardArray;
  if (!loading && data && data.payments) {
    cardArray = data.payments;
    cardArray.map(card => {
      card.isExpired = card.status === "EXPIRED";
      card.isSoonExpired = card.status === "EXPIRATION_PENDING";
      card.isFailed = card.status === "FAILED";
      return card;
    });
  }

  return { cardArray, loading };
}

export function usePaymentsForUser(userId) {
  const { data, loading } = useQuery(PAYMENTS_FOR_USER, {
    variables: {
      userId,
    },
  });
  let cardArray;
  if (!loading && data && data.paymentsForUser) {
    cardArray = data.paymentsForUser;
    cardArray.map(card => {
      card.isExpired = card.status === "EXPIRED";
      card.isSoonExpired = card.status === "EXPIRATION_PENDING";
      card.isFailed = card.status === "FAILED";
      return card;
    });
  }

  return { cardArray, loading };
}

const PAYMENT_PROCESSOR_FRAGMENT = gql`
  fragment PaymentProcessor_Fragment on PaymentProcessor {
    id
    publicKey
    subDomain
    serverHost
  }
`;

const PAYMENT_PROCESSOR_ADMIN = gql`
  query paymentProcessor($userId: String!) {
    paymentProcessor(userId: $userId) {
      ...PaymentProcessor_Fragment
    }
  }
  ${PAYMENT_PROCESSOR_FRAGMENT}
`;

const PAYMENT_PROCESSOR = gql`
  query paymentProcessor {
    paymentProcessor {
      ...PaymentProcessor_Fragment
    }
  }
  ${PAYMENT_PROCESSOR_FRAGMENT}
`;

export function usePaymentProcessor({ userId, isAdmin }) {
  const QUERY = isAdmin ? PAYMENT_PROCESSOR_ADMIN : PAYMENT_PROCESSOR;
  const variables = isAdmin ? { variables: { userId } } : null;
  const { data, loading, error } = useQuery(QUERY, variables);

  return {
    processor: get(data, "paymentProcessor", {}),
    loading,
    error,
  };
}

const ADD_PAYMENT_TOKEN = gql`
  mutation paymentAddFromToken($input: AddSavedPaymentInput!) {
    paymentAddFromToken(input: $input) {
      ...Payment_Fragment
    }
  }
  ${PAYMENT_FRAGMENT}
`;

export function useAddPaymentViaToken({ isAdmin, userId }) {
  const [mutation, { loading, error }] = useMutation(ADD_PAYMENT_TOKEN, {
    update(cache, { data }) {
      const queryName = isAdmin ? "paymentsForUser" : "payments";
      const savedPayment = get(data, "paymentAddFromToken");

      const paymentsQuery = {
        query: isAdmin ? PAYMENTS_FOR_USER : PAYMENTS,
      };

      if (userId) {
        paymentsQuery.variables = { userId: savedPayment.userId }; // the savedPayment.userId should be the same as the input userId
      }

      const result = cache.readQuery(paymentsQuery);
      const payments = get(result, queryName, []).map(payment => ({
        ...payment,
        isDefault: false,
      })); // set all current cards to default = false
      cache.writeQuery({
        ...paymentsQuery,
        data: {
          [queryName]: [...payments, savedPayment],
        },
      });
    },
  });

  const addPaymentViaToken = token => {
    const variables = {
      input: {
        userId,
        token,
      },
    };
    return mutation({
      variables,
    });
  };

  const formatedError = error ? new ServerError(error) : error;

  return {
    addPaymentViaToken,
    loading,
    error: formatedError,
  };
}
