import { t } from "localizify";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Dimmer, Loader, Message } from "semantic-ui-react";

const ResetPasswordStates = ({ loading, error, token, isValid, children }) => {
  return (
    <>
      {(() => {
        if (loading) {
          return (
            <Dimmer active inverted>
              <Loader>Loading...</Loader>
            </Dimmer>
          );
        }

        if (error) {
          return (
            <Message
              error
              header={t("password.reset.error_heading_other")}
              content={t("password.reset.error_message_other")}
            />
          );
        }

        if (!token || !isValid) {
          return (
            <Message
              error
              header={t("password.reset.error_heading_invalid_link")}
              content={t("password.reset.error_message_invalid_link")}
            />
          );
        }

        return <Fragment>{children}</Fragment>;
      })()}
    </>
  );
};

ResetPasswordStates.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  loading: PropTypes.bool,
  isValid: PropTypes.bool,
  children: PropTypes.node.isRequired,
  token: PropTypes.string.isRequired,
};

ResetPasswordStates.defaultProps = {
  error: null,
  loading: false,
  isValid: true,
};

export default ResetPasswordStates;
