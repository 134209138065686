import { t } from "localizify";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Icon, Label, Progress } from "semantic-ui-react";

import testPassword from "./utils";

export default class PasswordStrengthIndicator extends Component {
  shouldComponentUpdate(nextProps) {
    const { password } = this.props;
    return nextProps.password !== password;
  }

  render() {
    const { onPassing, onFailing, password } = this.props;
    const results = testPassword(password);
    results.acceptable ? onPassing() : onFailing();

    const getLabel = test => {
      const passed = results.tests[test];
      const style = {
        backgroundColor: "transparent",
        border: "none",
        paddingLeft: 4,
        paddingRight: 0,
        marginBottom: 0,
      };
      const getColor = () => {
        if (passed) return "green";
        return password.length > 0 ? "red" : "grey";
      };
      return (
        <Label basic style={style}>
          <Icon
            style={{ marginRight: 4, verticalAlign: "bottom", width: 16 }}
            name={passed ? "check" : "cancel"}
            color={getColor()}
          />
          {t(`password.reset.strength.${test}`)}
        </Label>
      );
    };

    return (
      <React.Fragment>
        <Progress
          percent={results.percentPassing}
          indicating={results.percentPassing !== 0}
          size="tiny"
          style={{
            marginBottom: "5px",
            marginTop: 0,
          }}
        />
        <Label.Group float="right" style={{ marginBottom: "10px" }}>
          {getLabel("length")}
          {getLabel("numbers_or_symbols")}
          {getLabel("case")}
        </Label.Group>
      </React.Fragment>
    );
  }
}

PasswordStrengthIndicator.propTypes = {
  onFailing: PropTypes.func,
  onPassing: PropTypes.func,
  password: PropTypes.string,
};

PasswordStrengthIndicator.defaultProps = {
  onFailing: () => {},
  onPassing: () => {},
  password: "",
};
