export const ARTICLES = "/articles";
export const ARTICLES_EDIT = `${ARTICLES}/edit`;
export const ARTICLES_AMPLIFY = `${ARTICLES}/amplify/:id`;
export const ARTICLES_PUBLISHED = `${ARTICLES}/published`;
export const ARTICLES_PRODUCTAGREEMENT = `${ARTICLES}/agreement`;
export const ARTICLES_GUIDELINES = `${ARTICLES}/guidelines`;
export const ARTICLES_GUIDELINES_PARAM = `${ARTICLES}?guidelines=true`;
export const ARTICLES_EDIT_GUIDELINES_PARAM = `${ARTICLES_EDIT}?guidelines=true`;
export const ARTICLES_SETTINGS = `${ARTICLES}/settings`;

export const linkTo = {
  articles: () => ARTICLES,
  edit: () => ARTICLES_EDIT,
  articlesAmplify: id => ARTICLES_AMPLIFY.replace(":id", id),
  published: () => ARTICLES_PUBLISHED,
  productAgreement: () => ARTICLES_PRODUCTAGREEMENT,
  guidelines: () => ARTICLES_GUIDELINES_PARAM,
  guidelinesEdit: () => ARTICLES_EDIT_GUIDELINES_PARAM,
  settings: () => ARTICLES_SETTINGS,
};

export const segmentCategories = {
  [ARTICLES]: "ArticleDashboard",
  [ARTICLES_EDIT]: "ArticleEditor",
  [ARTICLES_GUIDELINES]: "ArticleGuidelines",
  [ARTICLES_EDIT_GUIDELINES_PARAM]: "ArticleGuidelines",
};
