import "./team-member-toggle.css";

import { t } from "localizify";
import { get, isEmpty, map } from "lodash";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { formatPhoneNumber } from "react-phone-number-input";
import { Container, Icon } from "semantic-ui-react";

import colors from "../../lib/colors";

export const ASSISTANT_TYPE = {
  EA: "ea",
  PR: "pr",
};

const TeamMemberToggle = ({
  type,
  memberData,
  firstLine,
  secondLine,
  thirdLine,
  handleEdit,
  handleDelete,
}) => {
  const isMemberDataEmpty = isEmpty(memberData) || !memberData.name;
  return (
    <Fragment>
      <Container key={type}>
        {isMemberDataEmpty ? (
          <div
            style={{ color: colors.blueBase, position: "relative" }}
            className="team-member-toggle-container"
          >
            <Icon.Group
              data-ui-action={`add-team-member-${type}-open`}
              style={{ margin: "15px" }}
              onClick={handleEdit}
            >
              <Icon
                style={{ padding: "25px" }}
                size="massive"
                className="team-member-toggle person-add"
                color="blue"
              />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  height: "100%",
                  width: "100%",
                  cursor: "pointer",
                  borderRadius: "50%",
                  border: `1px solid ${colors.blueBase}`,
                }}
              />
            </Icon.Group>
            <div>{firstLine}</div>
            <div>{secondLine}</div>
            <div>{thirdLine}</div>
          </div>
        ) : (
          <div
            className="team-member-toggle-container"
            data-ui-container={`team-member-${type}`}
          >
            <div
              className="team-member-toggle-text"
              style={{
                padding: "15px",
              }}
            >
              {`${secondLine} ${thirdLine}`}
            </div>
            <div
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: colors.grayDarkest,
                lineHeight: "1.5em",
              }}
            >
              {memberData.name}
            </div>
            <div className="team-member-toggle-text">{memberData.email}</div>
            <div className="team-member-toggle-text">
              {formatPhoneNumber(
                `+${get(memberData, "mobilePhone.countryCode")}${get(
                  memberData,
                  "mobilePhone.number"
                )}`
              )}
            </div>
            <div className="team-member-toggle-text">
              {formatPhoneNumber(
                `+${get(memberData, "officePhone.countryCode")}${get(
                  memberData,
                  "officePhone.number"
                )}`
              )}
              {get(memberData, "officePhone.extension")
                ? ` x${memberData.officePhone.extension}`
                : null}
            </div>
            <div>
              <Icon
                data-ui-action={`add-team-member-${type}-edit-open`}
                style={{ margin: "10px", cursor: "pointer" }}
                name="edit"
                color="blue"
                onClick={handleEdit}
              />
              <Icon
                data-ui-action={`delete-team-member-${type}`}
                style={{ margin: "10px", cursor: "pointer" }}
                name="trash"
                color="blue"
                onClick={handleDelete}
              />
            </div>
          </div>
        )}
      </Container>
    </Fragment>
  );
};

export default TeamMemberToggle;

TeamMemberToggle.propTypes = {
  memberData: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    displayName: PropTypes.string,
    email: PropTypes.string,
    mobilePhone: PropTypes.shape({
      countryCode: PropTypes.string,
      number: PropTypes.string,
      extension: PropTypes.string,
    }),
    officePhone: PropTypes.shape({
      countryCode: PropTypes.string,
      number: PropTypes.string,
      extension: PropTypes.string,
    }),
  }),
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  firstLine: PropTypes.string,
  secondLine: PropTypes.string,
  thirdLine: PropTypes.string,
  type: PropTypes.oneOf(map(ASSISTANT_TYPE, type => type)),
};

TeamMemberToggle.defaultProps = {
  memberData: {},
  handleEdit: () => {},
  handleDelete: () => {},
  firstLine: t("onboard.team_member_toggle.add"),
  secondLine: t("onboard.team_member_toggle.executive"),
  thirdLine: t("onboard.team_member_toggle.assistant"),
  type: ASSISTANT_TYPE.EA,
};
