import { get, has } from "lodash";

// Expects semantic's possible formats for args when firing onChange
const getValueFromOnChange = args => {
  let value = "";

  if (has(args, "target.value")) {
    value = get(args, "target.value");
  } else if (has(args, "value")) {
    value = get(args, "value");
  } else if (args) {
    value = args;
  }

  return value;
};

export default getValueFromOnChange;
