import gql from "graphql-tag";
import { get } from "lodash";
import { useQuery } from "react-apollo";

export const LOGIN = gql`
  mutation login(
    $username: String!
    $password: String!
    $targetCommunity: String
  ) {
    login(
      username: $username
      password: $password
      targetCommunity: $targetCommunity
    ) @client
  }
`;

export const LOGIN_REQUEST_AUTH_STRATEGIES = gql`
  query authStrategies($to: String!, $channel: String!) {
    authStrategies(input: { to: $to, channel: $channel }) @client {
      passwordStrategy
      otcSmsStrategy
      smsOptions {
        id
        maskedValue
      }
    }
  }
`;

export const LOGIN_REQUEST_ONE_TIME = gql`
  mutation oneTimeCodeGenerate($to: String!, $channel: OneTimeCodeChannel!) {
    oneTimeCodeGenerate(input: { to: $to, channel: $channel }) @client
  }
`;

export const LOGIN_VERIFY_ONE_TIME = gql`
  mutation oneTimeCodeVerify($to: String!, $code: String!) {
    oneTimeCodeVerify(input: { to: $to, code: $code }) @client {
      user
      community
      authToken
      refreshToken
      roles
    }
  }
`;

export const LOGOUT = gql`
  mutation logout {
    logout @client
  }
`;

export const TOKEN_REFRESH = gql`
  mutation tokenRefresh {
    tokenRefresh @client
  }
`;

export const GET_AVAILABLE_COMMUNITIES = gql`
  {
    availableCommunities @client {
      code
      name
      familiarName
    }
  }
`;

const GET_LOGIN_CONFIG = gql`
  {
    community @client {
      copyrightHolder
      links {
        terms
        privacy
        membershipInfo
      }
      products {
        auth {
          config {
            slug
            backgroundImage
            playStoreLink
            appStoreLink
            logo
            textColor
          }
        }
      }
    }
  }
`;

export const ADD_PUSH_TOKEN = gql`
  mutation notificationsPushTokenAdd(
    $token: ID!
    $isIos: Boolean
    $isFcm: Boolean
  ) {
    notificationsPushTokenAdd(
      input: { token: $token, isIos: $isIos, isFcm: $isFcm }
    )
  }
`;

export const REMOVE_PUSH_TOKEN = gql`
  mutation notificationsPushTokenRemove($token: ID!) {
    notificationsPushTokenRemove(input: { token: $token })
  }
`;

export function useQueryLoginConfig() {
  const { data, loading, error } = useQuery(GET_LOGIN_CONFIG);

  return {
    loading,
    error: get(error, "message"),
    backgroundImage: get(
      data,
      "community.products.auth.config.backgroundImage"
    ),
    textColor: get(data, "community.products.auth.config.textColor"),
    logo: get(data, "community.products.auth.config.logo"),
    slug: get(data, "community.products.auth.config.slug"),
    playStoreLink: get(data, "community.products.auth.config.playStoreLink"),
    appStoreLink: get(data, "community.products.auth.config.appStoreLink"),
    copyright: get(data, "community.copyrightHolder"),
    privacyLink: get(data, "community.links.privacy"),
    termsLink: get(data, "community.links.terms"),
    membershipLink: get(data, "community.links.membershipInfo"),
  };
}
