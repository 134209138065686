import "tinymce/tinymce";
import "tinymce/plugins/link";
import "tinymce/themes/silver/theme";
import "tinymce/themes/mobile/theme";
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/skins/ui/oxide/skin.mobile.min.css";

import { Editor } from "@tinymce/tinymce-react";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import sanitizeHtml from "sanitize-html";
import { Button, Form, Message } from "semantic-ui-react";

import Avatar from "../../../components/avatar/index.web";
import useCurrentUser from "../../../hooks/current-user-hook";

export const ARTICLE_USER_SETTING_FRAGMENT = gql`
  fragment ArticleUserSetting_Fragment on ArticleUserSetting {
    id
    byline
  }
`;

const ARTICLE_USER_SETTING = gql`
  query articleUserSetting {
    articleUserSetting {
      ...ArticleUserSetting_Fragment
    }
  }
  ${ARTICLE_USER_SETTING_FRAGMENT}
`;

const ARTICLE_USER_BYLINE = gql`
  mutation articleUserByline($input: ArticleUserBylineInput) {
    articleUserByline(input: $input) {
      ...ArticleUserSetting_Fragment
    }
  }
  ${ARTICLE_USER_SETTING_FRAGMENT}
`;

const MAX_CHAR_COUNT = 140;

const allowedHtml = {
  allowedTags: ["p", "a"],
  allowedAttributes: {
    a: ["href"],
  },
};

// TinyMCE returns the following entities
// other HTML entities are normal UTF8 encoded
const entities = {
  gt: ">",
  lt: "<",
  amp: "&",
};

function fixEntities(str) {
  return str.replace(/&(gt|lt|amp);/gi, (_, m) => entities[m] || m);
}

function sanitizeEditorContentHtml(html) {
  return sanitizeHtml(html, allowedHtml);
}

function htmlStrippedCharCount(string) {
  if (!string) {
    return 0;
  }

  const htmlStrippedString = sanitizeHtml(string, {
    allowedTags: [],
    allowedAttributes: {},
  });
  return fixEntities(htmlStrippedString).length;
}

function isLengthValid(value) {
  return htmlStrippedCharCount(value) <= MAX_CHAR_COUNT;
}

function isLinksValid(value) {
  if (value) {
    const links = value.match(/(<a[^<]*<\/a>)/g) || [];
    return links.length <= 1;
  }

  return false;
}

export default function BylineEditor({ hasSavedByline, setHasSavedByline }) {
  const { currentUser: avatar } = useCurrentUser();
  const [bylineValue, setBylineValue] = useState("");
  const [showForm, setShowForm] = useState(false);

  const { data, loading } = useQuery(ARTICLE_USER_SETTING, {
    onCompleted: ({ articleUserSetting: { byline } }) => {
      setBylineValue(byline);
    },
  });

  const articleUserSetting = data ? data.articleUserSetting : {};

  useEffect(() => {
    setHasSavedByline(!!articleUserSetting.byline);
  }, [articleUserSetting.byline, setHasSavedByline]);

  const [updateUserByline, { loading: saving, error }] = useMutation(
    ARTICLE_USER_BYLINE
  );

  if (loading) {
    return null;
  }

  async function handleUpdateUserByline() {
    await updateUserByline({
      variables: {
        input: {
          byline: bylineValue,
        },
      },
    });
    setShowForm(false);
  }

  const onKeyDown = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  const isBylineValid = isLengthValid(bylineValue) && isLinksValid(bylineValue);

  return (
    <div className="ie-card ie-card-large ie-card-byline-mobile">
      <h4 className="ie-card-title">Article Byline</h4>
      <div className="ie-card-content">
        <p className="ie-byline-description">
          Your byline is how we credit you as an author. Make sure it includes
          your current title and company so readers know why you&apos;re an
          expert.
        </p>
      </div>

      {error && (
        <Message negative className="ie-error-message">
          <Message.Header>
            An error occurred while saving your byline.
          </Message.Header>
          <p>Please try again.</p>
        </Message>
      )}

      <div className="ie-byline-container">
        <div>
          <Avatar className="ie-byline-avatar" src={avatar.avatar} size={50} />
        </div>

        {!hasSavedByline || showForm ? (
          <Form className="ie-byline-form">
            <Form.Field className="ie-byline-input">
              <Editor
                textareaName="author-byline"
                value={bylineValue}
                init={{
                  menubar: false,
                  statusbar: false,
                  plugins: "link paste",
                  toolbar: "link unlink",
                  link_title: false,
                  default_link_target: "_blank",
                  target_list: false,
                  contextmenu: false,
                  height: 160,
                  paste_preprocess: (plugin, args) => {
                    args.content = sanitizeEditorContentHtml(args.content);
                  },
                  setup: editor => editor.on("keydown", onKeyDown),
                }}
                onEditorChange={setBylineValue}
              />
              <p
                className={`ie-byline-input-description ${!isBylineValid &&
                  "ie-byline-input-error"}`}
              >
                Can contain a maximum of {MAX_CHAR_COUNT} characters and (1)
                link.
              </p>
            </Form.Field>

            <div className="ie-byline-action">
              <Button
                className="ie-byline-button ie-button"
                onClick={handleUpdateUserByline}
                loading={saving}
                disabled={saving || !bylineValue || !isBylineValid}
              >
                Save Byline
              </Button>
            </div>
          </Form>
        ) : (
          <div className="ie-byline-content">
            {/* eslint-disable-next-line react/no-danger */}
            <p
              className="ie-byline-text"
              dangerouslySetInnerHTML={{ __html: bylineValue }}
            />
            <div className="ie-byline-action">
              <Button
                className="ie-byline-button ie-button"
                onClick={() => setShowForm(true)}
              >
                Edit Byline
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

BylineEditor.propTypes = {
  hasSavedByline: PropTypes.bool,
  setHasSavedByline: PropTypes.func,
};

BylineEditor.defaultProps = {
  hasSavedByline: false,
  setHasSavedByline: () => {},
};
