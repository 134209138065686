import PropTypes from "prop-types";
import React, { useState } from "react";
import { useMutation } from "react-apollo";
import { useHistory } from "react-router-dom";
import { Button, Icon, Message } from "semantic-ui-react";

import ApproveArticleModal from "../approve-article-modal/index.web";
import { APPROVE_ARTICLE_FOR_PUBLICATION } from "../graphql/article";
import { linkTo } from "../routes-links";

export default function Previewer({ articleId, articleState, status }) {
  const history = useHistory();
  const [approveModalOpen, setApproveModalOpen] = useState(false);

  const [approveArticle, { loading, error }] = useMutation(
    APPROVE_ARTICLE_FOR_PUBLICATION,
    {
      variables: {
        id: articleId,
      },
    }
  );

  async function handleApproveArticle() {
    await approveArticle();
    history.replace(linkTo.articles());
  }

  return (
    <>
      {status === "READY_TO_APPROVE" ? (
        <Button
          className="ie-button ie-approve-button"
          loading={loading}
          disabled={loading}
          onClick={() => setApproveModalOpen(true)}
        >
          Approve <Icon className="send" />
        </Button>
      ) : (
        <Message info className="ie-error-message">
          <p>
            Our editing team is hard at work on your article. Once we’re
            finished, this page will update with the latest version to approve.
          </p>
        </Message>
      )}

      <div className="ie-main-section">
        <div className="ie-article-preview">
          <h1>{articleState.title}</h1>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: articleState.content }} />
        </div>
      </div>

      <ApproveArticleModal
        open={approveModalOpen}
        onClose={() => setApproveModalOpen(false)}
        onSubmit={handleApproveArticle}
        submitting={loading}
        error={error}
      />
    </>
  );
}

Previewer.propTypes = {
  articleId: PropTypes.string,
  articleState: {
    title: PropTypes.string,
    content: PropTypes.string,
  },
  status: PropTypes.string,
};

Previewer.defaultProps = {
  articleId: PropTypes.string,
  articleState: {
    title: "",
    content: "",
  },
  status: "",
};
