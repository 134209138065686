import "./styles.css";

import PropTypes from "prop-types";
import React from "react";
import { Container } from "semantic-ui-react";

export const AD_SHAPES = {
  MASTHEAD: "MASTHEAD",
  MEDIUM_SQUARE: "MEDIUM_SQUARE",
};

export const MASTHEAD_DIMENSIONS = {
  height: 50,
  width: 700,
};
export const MEDIUM_SQUARE_DIMENSIONS = {
  height: 240,
  width: 240,
};

const shapeDict = {
  [AD_SHAPES.MASTHEAD]: {
    dimensions: MASTHEAD_DIMENSIONS,
    fullWidth: true,
  },
  [AD_SHAPES.MEDIUM_SQUARE]: {
    dimensions: MEDIUM_SQUARE_DIMENSIONS,
  },
};

const styleSheetRules = {
  selectorText: ".avert-container-rules",
  rules: `{
    --ad-image-border: 1px solid rgba(0,0,0,.1);
  }`,
};
const mastheadStyleSheet = {
  selectorText: ".avert-container-masthead-rules",
  rules: `{
    --ad-masthead-width: ${MASTHEAD_DIMENSIONS.width}px;
    --ad-masthead-height: ${MASTHEAD_DIMENSIONS.height}px;
    --ad-masthead-background-color: #EFEFEF;
    --ad-masthead-border-bottom: 1px solid #E5E5E5;
  }`,
};
const mediumSquareStyleSheet = {
  selectorText: ".avert-container-medium-square-rules",
  rules: `{
    --ad-medium-square-width: ${MEDIUM_SQUARE_DIMENSIONS.width}px;
    --ad-medium-square-height: ${MEDIUM_SQUARE_DIMENSIONS.height}px;
  }`,
};

function cssRule({ selectorText, rules }) {
  return `${selectorText} ${rules}`;
}
// Set up CSS styles on module load with dynamic values from this component
// Will allow us to hard code sizes here and not have to hunt them down in CSS sheets
let cssSheet = document.getElementById("avert-container-rules");
if (!cssSheet) {
  cssSheet = document.createElement("style");
  cssSheet.setAttribute("id", "avert-container-rules");
  cssSheet.setAttribute("data-emotion", "css");
  document.body.appendChild(cssSheet);
  cssSheet.sheet.insertRule(cssRule(styleSheetRules));
  cssSheet.sheet.insertRule(cssRule(mediumSquareStyleSheet));
}

export default class AdContainer extends React.PureComponent {
  constructor() {
    super();
    this.mastheadRuleIdx = -1;
  }

  componentDidMount() {
    const { shape } = this.props;
    if (this.mastheadRuleIdx < 0 && shape === AD_SHAPES.MASTHEAD)
      this.mastheadRuleIdx = cssSheet.sheet.insertRule(
        cssRule(mastheadStyleSheet)
      );
  }

  componentWillUnmount() {
    // TODO: This might get tricky if other types of ads need to have rules that affect
    // other components depending on whether loaded or not. Also, this will only handle
    // a single masthead, so other types that can have multiple will be a problem.
    const { shape } = this.props;
    if (shape === AD_SHAPES.MASTHEAD && this.mastheadRuleIdx >= 0) {
      cssSheet.sheet.deleteRule(AdContainer.mastheadRuleIdx);
    }
  }

  render() {
    const { shape, imageUrl, imageUrl2x, link } = this.props;
    const {
      fullWidth,
      dimensions: { width, height },
    } = shapeDict[shape];
    const shapeClass = shape.toLowerCase();
    const Image = () => (
      // eslint-disable-next-line jsx-a11y/alt-text
      <img
        src={imageUrl}
        width={width}
        height={height}
        srcSet={`${imageUrl} 1x, ${imageUrl2x || imageUrl} 2x`}
      />
    );
    return (
      <a
        className={`avert-container ${shapeClass} avert-container-rules avert-container-${shapeClass}-rules`}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {fullWidth ? (
          <Container>
            <Image />
          </Container>
        ) : (
          <Image />
        )}
      </a>
    );
  }
}

export const adContainerProps = {
  shape: PropTypes.oneOf(Object.keys(AD_SHAPES)),
  imageUrl: PropTypes.string.isRequired,
  imageUrl2x: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

AdContainer.propTypes = adContainerProps;
AdContainer.defaultProps = {
  shape: AD_SHAPES.MASTHEAD,
};
