export const ADMIN = "/admin";
export const ADMIN_SITES = `${ADMIN}/sites`;
export const ADMIN_MEMBERS = `${ADMIN}/members`;
export const ADMIN_FORUM = `${ADMIN}/forum`;
export const ADMIN_EXPERT_PANELS = `${ADMIN}/expert-panels`;
export const ADMIN_ARTICLES = `${ADMIN}/articles`;
export const ADMIN_MESSAGES = `${ADMIN}/messages`;
export const ADMIN_MARKETPLACE = `${ADMIN}/marketplace`;
export const ADMIN_GROUPS = `${ADMIN}/groups`;
export const ADMIN_FEEDS = `${ADMIN}/feeds`;
export const ADMIN_CONTENT_BANK = `${ADMIN}/content-bank`;

export const linkTo = {
  admin: () => ADMIN,
  sites: () => ADMIN_SITES,
  members: () => ADMIN_MEMBERS,
  forum: () => ADMIN_FORUM,
  expertPanels: () => ADMIN_EXPERT_PANELS,
  articles: () => ADMIN_ARTICLES,
  marketplace: () => ADMIN_MARKETPLACE,
  messages: () => ADMIN_MESSAGES,
  groups: () => ADMIN_GROUPS,
  feeds: () => ADMIN_FEEDS,
  contentBank: () => ADMIN_CONTENT_BANK,
};
